import React, { useContext, useEffect, useState } from "react";

import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import { environment } from "../services/environment";
import { AxiosContext } from '../services/axios/index';
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router";
import EditEventModal from "../components/EditEventModal";
const DashboardEvent = () => {
  const { id } = useParams();
const [allEvents, setAllEvents] = useState([]);
const axios = useContext(AxiosContext);
const [name, setName] = useState("");
const [tickId, setTickID] = useState("");
// const [id, setId] = useState("");
const [isLoading, setIsLoading] = useState(false); 
const [price, setPrice] = useState("");
const [currency, setCurrency] = useState("NGN");
const [amountavailable, setAmountAvailable] = useState("");

const [showModal, setShowModal] = useState(false);
const [editedEvent, setEditedEvent] = useState(null);

const openModal = (event) => {
  console.log("ev",event);
  setEditedEvent(event);
  setShowModal(true);
};

const closeModal = () => {
  setShowModal(false);
  setEditedEvent(null);
};

const handleEditSubmit = (updatedEventData) => {
  // Make API call to update event with updatedEventData
  console.log(updatedEventData);
  closeModal();
};

const createNewTicket = async () => {
    
  try {
 const response =   await axios.post(
      `${environment.backendURL}/ticket`,
      {
        eventId: tickId,
      name: name,
      price: parseInt(price),
      currency: currency,
      amountavailable: parseInt(amountavailable)
 
        },
      
    );
    // setShowed(false);
console.log("response", response)
if (response.status === 201) {
toast("Ticket Created",{
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",});
// navigate('/create-ticket');
// await handleModalOpen(response.data._id);
const modal = new bootstrap.Modal(document.getElementById('createTicketModal'));
modal.hide();
}
    window.location.reload();
  
  } catch (err) {
    const modal = new bootstrap.Modal(document.getElementById('createTicketModal'));
modal.hide();
    console.log(err);
    toast(err,{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",})
  } 
  // setShowToast(true);
};
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        
        const response = await axios.get(
          `${environment.backendURL}/event/user-events`
        );
        const eventData = response.data;
        setAllEvents(eventData);
        // Set loading status to false when data is fetched successfully
        console.log("all events", eventData);
        setIsLoading(false);
      } catch (error) {
        // Handle any errors here
        setIsLoading(false)
        console.error("Error fetching events:", error);
        // Set loading status to false on error
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  const handleModalTrigger = (event) => {
    // Prevent the default behavior of the link
    event.preventDefault();

    // Get the ticket ID from the data attribute
    const ticketId = event.target.getAttribute('data-ticket-id');

    console.log("ticketID", ticketId);
    // Open the modal
    const modal = new bootstrap.Modal(document.getElementById('createTicketModal'));
    modal.show();

    setTickID(ticketId);
    // Pass the ticket ID to the createNewTicket function
    // createNewTicket(ticketId);
  };

  const DeleteEvent = async (eventid) => {
    try {
      if (axios) {
        await axios
          .delete(`${environment.backendURL}/event/${eventid}`)
          .then((response) => {
            console.log("delete event", response);
          });
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setShowToastInsurance(true);
      window.location.reload();
    }
  };

  return (
 
      <body class="d-flex flex-column h-100">
        <DashboardHeader />

        <Sidebar />
        <div
          class="modal fade"
          id="createTicketModal"
          tabindex="-1"
          aria-labelledby="createTicketModalLabel"
          aria-hidden="false"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="createTicketModalLabel">
                  Create Single Ticket
                </h5>
                <button
                  type="button"
                  class="close-model-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="uil uil-multiply"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="model-content main-form">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Ticket Name*</label>
                        <input
                          class="form-control h_40"
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="Event Ticket Name"
                          value={name}
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Ticket Price*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder="Event Ticket Price"
                          value={price}
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Amount Available*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          onChange={(e) => setAmountAvailable(e.target.value)}
                          placeholder="Number of available tickets"
                          value={amountavailable}
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group main-form mt-4">
                        <label class="form-label">Currency*</label>
                        <select
                              style={{
                                height: "54px",
                                borderRadius: "12px",
                                padding: "10px",
                                fontSize: "20px",
                                fontWeight: "500",
                              }}
                          class="selectpicker"
                          onChange={(e) => setCurrency(e.target.value)}

                          data-size="5"
                          title="Select Currency"
                          data-live-search="true"
                        >
                          <option value="NGN">NGN</option>
                          <option value="USD">USD</option>
                          <option value="USDT">USDT</option>
                        
                        </select>
                      </div>
                    </div>
                    {/* <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-label mb-4 fs-16">
                          Ticket Restrictions
                        </div>
                        <div class="form-group border_bottom">
                          <div class="d-flex align-items-center flex-wrap pb-4 flex-nowrap">
                            <h4 class="fs-14 mb-0 me-auto">
                              Total number of tickets available
                            </h4>
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                id="is-restrict-total-ticket"
                                checked=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div>
                              <label class="fs-12 m-0">Unlimited</label>
                            </div>
                          </div>
                          <div
                            class="p-0 mb-4 total_ticket_per_level"
                            style={{ display: "none" }}
                          >
                            <div class="form-group">
                              <div class="input-number">
                                <input
                                  class="form-control h_40"
                                  type="number"
                                  min="0"
                                  max="30"
                                  placeholder="Enter Total Tickets"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="d-flex align-items-center flex-wrap pt-4 flex-nowrap">
                            <h4 class="fs-14 mb-0 me-auto">
                              Maximum number of tickets for each customer
                            </h4>
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                id="is-restrict-ticket-per-user"
                                checked=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div>
                              <label class="fs-12 m-0">Unlimited</label>
                            </div>
                          </div>
                          <div
                            class="p-0 mt-4 total_ticket_per_user"
                            style={{ display: "none" }}
                          >
                            <div class="form-group">
                              <div class="input-number">
                                <input
                                  class="form-control h_40"
                                  type="number"
                                  min="0"
                                  max="30"
                                  placeholder="Enter Max. per order"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-label mb-4 fs-16">
                          Ticket Restrictions
                        </div>
                        <div class="form-group">
                          <label class="form-label mb-2 fs-14">
                            Ticket Order*
                          </label>
                          <input
                            class="form-control h_40"
                            type="number"
                            value="1"
                            min="1"
                            max="30"
                            placeholder="Enter Sort Order"
                          />
                        </div>
                        <div class="form-group mt-4">
                          <label class="form-label mb-2 fs-14">
                            Ticket Description*
                          </label>
                          <textarea
                            class="form-textarea"
                            placeholder="Description will go here"
                          ></textarea>
                        </div>
                      </div>
                    </div> */}
                    {/* <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="d-flex align-items-center justify-content-between price-variation-head">
                          <h5 class="m-0 color-black fs-16">
                            Variations (
                            <span class="ticket-subtype-count">1</span>)
                          </h5>
                          <span class="add-btn btn-hover">
                            <i class="fa-regular fa-square-plus"></i>
                          </span>
                        </div>
                        <div class="table-responsive">
                          <div class="div-base-table border-0 mt-2 full-width small-table ticket-subtype-table">
                            <div class="table-row table-head no-bg">
                              <div class="table-col fs-14 text-light3">
                                Price <span class="red">*</span>
                              </div>
                              <div class="table-col fs-14 text-light3">
                                Variation Name <span class="red">*</span>
                              </div>
                              <div class="table-col fs-14 text-light3 text-right">
                                Actions
                              </div>
                            </div>
                            <div class="table-row ticket-subtype-row">
                              <div class="table-col first-col">
                                <div class="form-group m-0 form-custom-validation">
                                  <input
                                    class="form-control h_40"
                                    type="number"
                                    min="0"
                                    max="10000"
                                    required=""
                                    placeholder="Price"
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="table-col second-col">
                                <div class="form-group m-0">
                                  <input
                                    class="form-control h_40"
                                    type="text"
                                    maxlength="75"
                                    required=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="table-col third-col">
                                <button
                                  type="button"
                                  class="v-btn-close btn-hover"
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-group">
                          <div class="d-flex align-items-start">
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                class=""
                                id="bird-discount"
                                value=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div class="d-flex flex-column">
                              <label class="color-black mb-1">
                                I want to offer early bird discount.
                              </label>
                              <p class="mt-2 fs-14 d-block mb-3">
                                Enabling this discount lets your attendees get
                                all the regular tickets features at a discounted
                                price.{" "}
                                <a href="#" class="a-link">
                                  Learn more
                                </a>
                              </p>
                            </div>
                          </div>
                          <div
                            class="online-event-discount-wrapper"
                            style={{ display: "none" }}
                          >
                            <div class="row g-3">
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Discount*
                                </label>
                                <input
                                  class="form-control h_40"
                                  type="text"
                                  placeholder="0"
                                  value=""
                                />
                              </div>
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Price*
                                </label>
                                <select class="selectpicker">
                                  <option value="Percentage" selected="">
                                    Percent(%)
                                  </option>
                                  <option value="Fixed">Fixed($)</option>
                                </select>
                              </div>
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Discount ends on*
                                </label>
                                <div class="loc-group position-relative">
                                  <input
                                    class="form-control h_40 datepicker-here"
                                    data-language="en"
                                    data-position="top left"
                                    type="text"
                                    placeholder="MM/DD/YYYY"
                                    value=""
                                  />
                                  <span class="absolute-icon top_0">
                                    <i class="fa-solid fa-calendar-days"></i>
                                  </span>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="clock-icon">
                                  <label class="form-label mt-3 fs-6">
                                    Time
                                  </label>
                                  <select
                                    class="selectpicker"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <option value="00:00">12:00 AM</option>
                                    <option value="00:15">12:15 AM</option>
                                    <option value="00:30">12:30 AM</option>
                                    <option value="00:45">12:45 AM</option>
                                    <option value="01:00">01:00 AM</option>
                                    <option value="01:15">01:15 AM</option>
                                    <option value="01:30">01:30 AM</option>
                                    <option value="01:45">01:45 AM</option>
                                    <option value="02:00">02:00 AM</option>
                                    <option value="02:15">02:15 AM</option>
                                    <option value="02:30">02:30 AM</option>
                                    <option value="02:45">02:45 AM</option>
                                    <option value="03:00">03:00 AM</option>
                                    <option value="03:15">03:15 AM</option>
                                    <option value="03:30">03:30 AM</option>
                                    <option value="03:45">03:45 AM</option>
                                    <option value="04:00">04:00 AM</option>
                                    <option value="04:15">04:15 AM</option>
                                    <option value="04:30">04:30 AM</option>
                                    <option value="04:45">04:45 AM</option>
                                    <option value="05:00">05:00 AM</option>
                                    <option value="05:15">05:15 AM</option>
                                    <option value="05:30">05:30 AM</option>
                                    <option value="05:45">05:45 AM</option>
                                    <option value="06:00">06:00 AM</option>
                                    <option value="06:15">06:15 AM</option>
                                    <option value="06:30">06:30 AM</option>
                                    <option value="06:45">06:45 AM</option>
                                    <option value="07:00">07:00 AM</option>
                                    <option value="07:15">07:15 AM</option>
                                    <option value="07:30">07:30 AM</option>
                                    <option value="07:45">07:45 AM</option>
                                    <option value="08:00">08:00 AM</option>
                                    <option value="08:15">08:15 AM</option>
                                    <option value="08:30">08:30 AM</option>
                                    <option value="08:45">08:45 AM</option>
                                    <option value="09:00">09:00 AM</option>
                                    <option value="09:15">09:15 AM</option>
                                    <option value="09:30">09:30 AM</option>
                                    <option value="09:45">09:45 AM</option>
                                    <option value="10:00" selected="selected">
                                      10:00 AM
                                    </option>
                                    <option value="10:15">10:15 AM</option>
                                    <option value="10:30">10:30 AM</option>
                                    <option value="10:45">10:45 AM</option>
                                    <option value="11:00">11:00 AM</option>
                                    <option value="11:15">11:15 AM</option>
                                    <option value="11:30">11:30 AM</option>
                                    <option value="11:45">11:45 AM</option>
                                    <option value="12:00">12:00 PM</option>
                                    <option value="12:15">12:15 PM</option>
                                    <option value="12:30">12:30 PM</option>
                                    <option value="12:45">12:45 PM</option>
                                    <option value="13:00">01:00 PM</option>
                                    <option value="13:15">01:15 PM</option>
                                    <option value="13:30">01:30 PM</option>
                                    <option value="13:45">01:45 PM</option>
                                    <option value="14:00">02:00 PM</option>
                                    <option value="14:15">02:15 PM</option>
                                    <option value="14:30">02:30 PM</option>
                                    <option value="14:45">02:45 PM</option>
                                    <option value="15:00">03:00 PM</option>
                                    <option value="15:15">03:15 PM</option>
                                    <option value="15:30">03:30 PM</option>
                                    <option value="15:45">03:45 PM</option>
                                    <option value="16:00">04:00 PM</option>
                                    <option value="16:15">04:15 PM</option>
                                    <option value="16:30">04:30 PM</option>
                                    <option value="16:45">04:45 PM</option>
                                    <option value="17:00">05:00 PM</option>
                                    <option value="17:15">05:15 PM</option>
                                    <option value="17:30">05:30 PM</option>
                                    <option value="17:45">05:45 PM</option>
                                    <option value="18:00">06:00 PM</option>
                                    <option value="18:15">06:15 PM</option>
                                    <option value="18:30">06:30 PM</option>
                                    <option value="18:45">06:45 PM</option>
                                    <option value="19:00">07:00 PM</option>
                                    <option value="19:15">07:15 PM</option>
                                    <option value="19:30">07:30 PM</option>
                                    <option value="19:45">07:45 PM</option>
                                    <option value="20:00">08:00 PM</option>
                                    <option value="20:15">08:15 PM</option>
                                    <option value="20:30">08:30 PM</option>
                                    <option value="20:45">08:45 PM</option>
                                    <option value="21:00">09:00 PM</option>
                                    <option value="21:15">09:15 PM</option>
                                    <option value="21:30">09:30 PM</option>
                                    <option value="21:45">09:45 PM</option>
                                    <option value="22:00">10:00 PM</option>
                                    <option value="22:15">10:15 PM</option>
                                    <option value="22:30">10:30 PM</option>
                                    <option value="22:45">10:45 PM</option>
                                    <option value="23:00">11:00 PM</option>
                                    <option value="23:15">11:15 PM</option>
                                    <option value="23:30">11:30 PM</option>
                                    <option value="23:45">11:45 PM</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="co-main-btn min-width btn-hover h_40"
                  data-bs-target="#createTicketModal"
                
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button onClick={() => createNewTicket()} type="button" class="main-btn min-width btn-hover h_40">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper wrapper-body">
          <div class="dashboard-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="d-main-title">
                    <h3>
                      <i class="fa-solid fa-calendar-days me-3"></i>Events
                    </h3>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="main-card mt-5">
                    <div class="dashboard-wrap-content p-4">
                      <h5 class="mb-4">Events (1)</h5>
                      <div class="d-md-flex flex-wrap align-items-center">
                        <div class="dashboard-date-wrap">
                          <div class="form-group">
                            <div class="relative-input position-relative">
                              <input
                                class="form-control h_40"
                                type="text"
                                placeholder="Search by event name, status"
                                value=""
                              />
                              <i class="uil uil-search"></i>
                            </div>
                          </div>
                        </div>
                        <div class="rs ms-auto mt_r4">
                          <div
                            class="nav custom2-tabs btn-group"
                            role="tablist"
                          >
                            <button
                              class="tab-link active"
                              data-bs-toggle="tab"
                              data-bs-target="#all-tab"
                              type="button"
                              role="tab"
                              aria-controls="all-tab"
                              aria-selected="true"
                            >
                              All Event (
                              <span class="total_event_counter">{allEvents.length}</span>)
                            </button>
                            <button
                              class="tab-link"
                              data-bs-toggle="tab"
                              data-bs-target="#online-tab"
                              type="button"
                              role="tab"
                              aria-controls="online-tab"
                              aria-selected="false"
                            >
                              Online Event (
                              <span class="total_event_counter">0</span>)
                            </button>
                            <button
                              class="tab-link"
                              data-bs-toggle="tab"
                              data-bs-target="#venue-tab"
                              type="button"
                              role="tab"
                              aria-controls="venue-tab"
                              aria-selected="false"
                            >
                              Physical Event (
                              <span class="total_event_counter">1</span>)
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div class="event-list">
        <div class="tab-content">
          <div
            class="tab-pane fade show active"
            id="all-tab"
            role="tabpanel"
          >
            {
              allEvents.length > 0 ? (
                allEvents.map((events) => {
                  
                  return (
                    <div class="main-card mt-4">
                    <div class="contact-list">
                      <div class="card-top event-top p-4 align-items-center top d-md-flex flex-wrap justify-content-between">
                        <div class="d-md-flex align-items-center event-top-info">
                          <div class="card-event-img">
                            <img
                              src={events.images[0]}
                              alt=""
                            />
                          </div>
                          <div class="card-event-dt">
                            <h5>
                           { events.name}
                            </h5>
                          </div>
                        </div>
                        <div class="dropdown">
                          <button
                          key={events._id}
                            class="option-btn"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <div key={events._id} class="dropdown-menu dropdown-menu-right">
                            <a key={events._id}  onClick={() => openModal(events)} href="#" class="dropdown-item">
                              <i class="fa-solid fa-gear me-3"></i>Edit Event
                            </a>
                            {/* <a href="#" class="dropdown-item">
                              <i class="fa-solid fa-eye me-3"></i>Preview
                              Event
                            </a> */}
                            {/* <a href="#" class="dropdown-item">
                              <i class="fa-solid fa-clone me-3"></i>
                              Duplicate
                            </a> */}
                             <a
                                class="dropdown-item"
                                href="#"
                              
                                data-ticket-id={events._id}
                                onClick={handleModalTrigger}
                              >
                                <i class="fa-solid fa-ticket me-2"></i>
                               Create Single Ticket
                              </a>
                            <a
                            key={events._id}
                            onClick={() => DeleteEvent(events._id)}
                              href="#"
                              class="dropdown-item delete-event"
                            >
                              <i class="fa-solid fa-trash-can me-3"></i>
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="bottom d-flex flex-wrap justify-content-between align-items-center p-4">
                        <div class="icon-box ">
                          <span class="icon">
                            <i class="fa-solid fa-location-dot"></i>
                          </span>
                          <p>Status</p>
                          <h6 class="coupon-status">Published</h6>
                        </div>
                        <div class="icon-box">
                          <span class="icon">
                            <i class="fa-solid fa-calendar-days"></i>
                          </span>
                          <p>Starts on</p>
                          <h6 class="coupon-status">
                          {events.date + " " + events.time}
                          </h6>
                        </div>
                        {/* <div class="icon-box">
                          <span class="icon">
                            <i class="fa-solid fa-ticket"></i>
                          </span>
                          <p>Ticket</p>
                          <h6 class="coupon-status">250</h6>
                        </div> */}
                        <div class="icon-box">
                          <span class="icon">
                            <i class="fa-solid fa-tag"></i>
                          </span>
                          <p>Tickets sold</p>
                          <h6 class="coupon-status">{events.amountOfTicketSold}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                })
              ):(
                <div class="main-card mt-4">
                <div class="d-flex align-items-center justify-content-center flex-column min-height-430">
                  <div class="event-list-icon">
                    <img src="images/calendar.png" alt="" />
                  </div>
                  <p class="font-16 mt-4 text-light3">
                    No Event found
                  </p>
                </div>
              </div>
              )
            }
               <EditEventModal
            show={showModal}
            onHide={closeModal}
            eventData={editedEvent}
            onSubmit={handleEditSubmit}
    
      />
          </div>
          <div
            class="tab-pane fade"
            id="online-tab"
            role="tabpanel"
          >
            <div class="main-card mt-4">
              <div class="d-flex align-items-center justify-content-center flex-column min-height-430">
                <div class="event-list-icon">
                  <img src="images/calendar.png" alt="" />
                </div>
                <p class="font-16 mt-4 text-light3">
                  No Event found
                </p>
              </div>
            </div>
            <div class="main-card d-none  mt-4">
              <div class="contact-list">
                <div class="card-top event-top p-4 align-items-center top d-md-flex flex-wrap justify-content-between">
                  <div class="d-md-flex align-items-center event-top-info">
                    <div class="card-event-img">
                      <img
                        src="images/event-imgs/img-2.jpg"
                        alt=""
                      />
                    </div>
                    <div class="card-event-dt">
                      <h5>Earrings Workshop with Bronwyn David</h5>
                    </div>
                  </div>
                  <div class="dropdown">
                    <button
                      class="option-btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item">
                        <i class="fa-solid fa-gear me-3"></i>Manage
                      </a>
                      <a href="#" class="dropdown-item">
                        <i class="fa-solid fa-eye me-3"></i>Preview
                        Event
                      </a>
                      <a href="#" class="dropdown-item">
                        <i class="fa-solid fa-clone me-3"></i>
                        Duplicate
                      </a>
                      <a
                        href="#"
                        class="dropdown-item delete-event"
                      >
                        <i class="fa-solid fa-trash-can me-3"></i>
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
                <div class="bottom d-flex flex-wrap justify-content-between align-items-center p-4">
                  <div class="icon-box ">
                    <span class="icon">
                      <i class="fa-solid fa-location-dot"></i>
                    </span>
                    <p>Status</p>
                    <h6 class="coupon-status">Publish</h6>
                  </div>
                  <div class="icon-box">
                    <span class="icon">
                      <i class="fa-solid fa-calendar-days"></i>
                    </span>
                    <p>Starts on</p>
                    <h6 class="coupon-status">
                      30 Jun, 2024 10:00 AM
                    </h6>
                  </div>
                  <div class="icon-box">
                    <span class="icon">
                      <i class="fa-solid fa-ticket"></i>
                    </span>
                    <p>Ticket</p>
                    <h6 class="coupon-status">250</h6>
                  </div>
                  <div class="icon-box">
                    <span class="icon">
                      <i class="fa-solid fa-tag"></i>
                    </span>
                    <p>Tickets sold</p>
                    <h6 class="coupon-status">20</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="venue-tab" role="tabpanel">
            <div class="main-card d-none mt-4">
              <div class="contact-list">
                <div class="card-top event-top p-4 align-items-center top d-md-flex flex-wrap justify-content-between">
                  <div class="d-md-flex align-items-center event-top-info">
                    <div class="card-event-img">
                      <img
                        src="images/event-imgs/img-7.jpg"
                        alt=""
                      />
                    </div>
                    <div class="card-event-dt">
                      <h5>
                        Tutorial on Canvas Painting for Beginners
                      </h5>
                    </div>
                  </div>
                  <div class="dropdown">
                    <button
                      class="option-btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item">
                        <i class="fa-solid fa-gear me-3"></i>Manage
                      </a>
                      <a href="#" class="dropdown-item">
                        <i class="fa-solid fa-eye me-3"></i>Preview
                        Event
                      </a>
                      <a href="#" class="dropdown-item">
                        <i class="fa-solid fa-clone me-3"></i>
                        Duplicate
                      </a>
                      <a
                        href="#"
                        class="dropdown-item delete-event"
                      >
                        <i class="fa-solid fa-trash-can me-3"></i>
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
                <div class="bottom d-flex flex-wrap justify-content-between align-items-center p-4">
                  <div class="icon-box ">
                    <span class="icon">
                      <i class="fa-solid fa-location-dot"></i>
                    </span>
                    <p>Status</p>
                    <h6 class="coupon-status">Publish</h6>
                  </div>
                  <div class="icon-box">
                    <span class="icon">
                      <i class="fa-solid fa-calendar-days"></i>
                    </span>
                    <p>Starts on</p>
                    <h6 class="coupon-status">
                      30 Jun, 2024 10:00 AM
                    </h6>
                  </div>
                  <div class="icon-box">
                    <span class="icon">
                      <i class="fa-solid fa-ticket"></i>
                    </span>
                    <p>Ticket</p>
                    <h6 class="coupon-status">250</h6>
                  </div>
                  <div class="icon-box">
                    <span class="icon">
                      <i class="fa-solid fa-tag"></i>
                    </span>
                    <p>Tickets sold</p>
                    <h6 class="coupon-status">20</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
            
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </body>

  );
};

export default DashboardEvent;
