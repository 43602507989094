import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import parkwell from "../assets/images/lops.png";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import { environment } from "../services/environment";
import { AxiosContext } from "../services/axios";
import { ToastContainer, toast } from "react-toastify";
import { InfinitySpin, TailSpin } from "react-loader-spinner";
import { useAuth } from "../services/auth/useAuth.ts";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaRegCircleQuestion } from "react-icons/fa6";


const Payout = () => {
  const {  storeUserDetails ,getAuthToken,getUserDetails } = useAuth();
  const [authToken, setAuthToken] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const axios = useContext(AxiosContext);
  axios.defaults.headers.common = { Authorization: `bearer ${authToken}` };



  useEffect(() => {
    if (authToken == null || authToken === "undefined") {
      getAuthToken().then((token) => {
        if (token != null) {
          setAuthToken(token);
        }
      });
    }
  },[authToken]);
  console.log("tok",authToken);

const [amount, setAmount] = useState('');
  const [bankList, setBankList] = useState([]);
  const [bankName, setBankName] = useState('');
  const [bankcodes, setBankCodes] = useState('');
  const [accountNum, setAccountNum] = useState('');
  const [accountName, setAccountName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
const [accounts ,setAccounts] = useState([]);


useEffect(() => {
  const getUser = () => {
    getUserDetails().then((detail) => {
      if (detail) {
        const user = JSON.parse(detail);
        setCurrentUser(user);
      }
    });
  };

  getUser();

}, []);

  const resolveAccNumber = (data) => {
    const { accNumber, bankCode } = data;
    return axios.get(
      `${environment.parkwellURL}/utils/banks/resolve?account_number=${accNumber}&bank_code=${bankCode}`
    );
  };



  
  useEffect(() => {
    // setIsSubmitting(false);
    if (accountNum && accountNum.length > 9) {
      resolveAccNumber({
        accNumber: accountNum,
        bankCode: bankName,
      }).then((data) => {
        if (data && data.data && data.data.data) {
          setAccountName(data.data.data.account_name);
          return;
        }
      });

      setAccountName("");
    }
  }, [accountNum, bankName]);

  useEffect(() => {
    axios.get(`${environment.parkwellURL}/utils/banks`).then((data) => {
     
     console.log("bankdata", data);
      const banks = [];

      if (data && data.data && data.data.data) {
        data.data.data.map((bank) => {
          banks.push({ name: bank.name, code: bank.code });
          return null;
        });
      }

      setBankList(banks);
    });
  }, []);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await  axios.get(`${environment.backendURL}/accounts`);
        console.log("accountdata", response.data);
        const accountsdata = response.data;
        setAccounts(accountsdata);

      } catch (error) {
        // Handle any errors here
        console.error("Error fetching accounts:", error);
        // Set loading status to false on error
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  console.log("banks", bankList);

  const addBank = async (e) => {
  e.preventDefault();
    try {
      setIsLoading(true);
   const response =   await axios.post(
        `${environment.backendURL}/accounts`,
        {
          bankName: bankList.find((m) => m.code === bankName)?.name,
      bankCode:bankName,
      accountNumber: accountNum,
          },
        
      );
      // setShowed(false);
console.log("response", response)
setIsLoading(false);
if (response.status === 201) {
  toast("Bank Added",{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",});
// navigate('/create-ticket');
// await handleModalOpen(response.data);

const modal = new bootstrap.Modal(document.getElementById('bankModal'));
await modal.hide();
}
      // window.location.reload().then(() => {
      //   setShowToast(true);
      // });
      
    
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toast(err.response.data.message[0],{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",})
    } 
    // setShowToast(true);
  };
  const makeWithdrawal = async (e) => {
  e.preventDefault();
    try {
      setIsLoading(true);
   const response =   await axios.post(
        `${environment.backendURL}/withdrawal`,
        {

      bankCode:bankcodes,
      amount: parseInt(amount),
      gateway: "paystack",
      currency: "NGN"
          },
        
      );
      // setShowed(false);
console.log("response", response)
setIsLoading(false);
if (response.status === 201) {
  toast("Withdrawal Successful",{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",});
// navigate('/create-ticket');
// await handleModalOpen(response.data);

const modal = new bootstrap.Modal(document.getElementById('withdrawModal'));
await modal.hide();
}
      // window.location.reload().then(() => {
      //   setShowToast(true);
      // });
      
    
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toast(err.response.data.message,{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",})
    } 
    // setShowToast(true);
  };

  const EnableDirect = async () => {
    
    try {
   const response =   await axios.post(
        `${environment.backendURL}/user/edit`,
        {
         directTransfer: "true",
     
   
          },
        
      );
      // setShowed(false);
  console.log("response", response)
  await storeUserDetails(response?.data);
  if (response.status === 201) {
  toast("Payment Enabled",{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",});
  // navigate('/create-ticket');
  // await handleModalOpen(response.data._id);

  }
      // window.location.reload().then(() => {
      //   setShowToast(true);
      // });
      
    
    } catch (err) {


      console.log(err);
      toast(err,{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",})
    } 
    // setShowToast(true);
  };
  const DisableDirect = async () => {
    
    try {
   const response =   await axios.post(
        `${environment.backendURL}/user/edit`,
        {
         directTransfer: "false",
     
   
          },
        
      );
      // setShowed(false);
  console.log("response", response)
  await storeUserDetails(response?.data);
  if (response.status === 201) {
  toast("Payment Disabled",{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",});
  // navigate('/create-ticket');
  // await handleModalOpen(response.data._id);

  }
      // window.location.reload().then(() => {
      //   setShowToast(true);
      // });
      
    
    } catch (err) {


      console.log(err);
      toast(err,{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",})
    } 
    // setShowToast(true);
  };
  return (
    <>
      <body class="d-flex flex-column h-100">
        <div
          class="modal fade"
          id="bankModal"
          tabindex="-1"
          aria-labelledby="bankModalLabel"
          aria-hidden="false"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="bankModalLabel">
                  Add Bank Account
                </h5>
                <button
                  type="button"
                  class="close-model-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="uil uil-multiply"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="model-content main-form">
                  <div class="row">
                    {/* <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Account Name*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                   
                        />
                      </div>
                    </div> */}
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Account Number*</label>
                        <input
                        required
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                        onChange={(e) => setAccountNum(e.target.value)}
                        value={accountNum}
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Bank Name*</label>
                        {/* <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        /> */}
                            <select
                            required
                                      style={{height:"40px",width:"100%", borderRadius:"4px", padding:"5px", fontSize:"14px", fontWeight:"400"}}
                                  

                                        onChange={(e) => setBankName(e.target.value)}
                                    
                                        title="Select bank"
                                    
                                      >
                                        {
                                          bankList.length > 0 &&
                                          bankList.map((bank) => (
                                            <option key={`${bank.code}_${bank.name}`}
                                            value={bank.code}>
                                           { bank.name}</option>
                                          ))
                                        }
                        
                    
                                      </select>
                      </div>
                      <p style={{color:"#00509d", fontSize:"12px"}}>    {accountName}</p>
                    </div>
                 
                    {/* <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">SWIFT/BIC code*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        />
                      </div>
                    </div> */}
                    {/* <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">ABA Routing*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        />
                      </div>
                    </div> */}
                    {/* <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">
                          International Bank Account Number*
                        </label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="co-main-btn min-width btn-hover h_40"
                  data-bs-target="#bankModal"
                
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button onClick={addBank} type="button" class="main-btn min-width btn-hover h_40">
               
                  {isLoading ? (
              <div style={{textAlign:"center", marginLeft:"50px"}}       >
                  <TailSpin
  visible={true}
  height="20"
  width="20"
  color="#f5f5f5"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass=""
  />
                </div>
              
                 
           
              ) : (
                <>
             Save
                </>
              
              )
}
        
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="withdrawModal"
          tabindex="-1"
          aria-labelledby="withdrawModalLabel"
          aria-hidden="false"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="withdrawModalLabel">
                 Withdrawal
                </h5>
                <button
                  type="button"
                  class="close-model-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="uil uil-multiply"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="model-content main-form">
                  <div class="row">
                    {/* <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Account Name*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                   
                        />
                      </div>
                    </div> */}
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Amount*</label>
                        <input
                        required
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Select Bank*</label>
                        {/* <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        /> */}
                            <select
                            required
                                      style={{height:"40px",width:"100%", borderRadius:"4px", padding:"5px", fontSize:"14px", fontWeight:"400"}}
                                  

                                        onChange={(e) => setBankCodes(e.target.value)}
                                    
                                        title="Select bank"
                                    
                                      >
                                             <option key={`#`}
                                            value="Select Bank">
                                           Select Bank</option>
                                        {
                                         accounts.length > 0 && 
                                         accounts.map((account)  => (
                                            <option key={`${account.bankCode}_${account.bankName}`}
                                            value={account.bankCode}>
                                           { account?.accountNumber  +
                                  " - " + account.bankName}</option>
                                          ))
                                        }
                        
                    
                                      </select>
                      </div>
                      {/* <p style={{color:"#00509d", fontSize:"12px"}}>    {accountName}</p> */}
                    </div>
                 
                    {/* <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">SWIFT/BIC code*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        />
                      </div>
                    </div> */}
                    {/* <div class="col-lg-6 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">ABA Routing*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        />
                      </div>
                    </div> */}
                    {/* <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">
                          International Bank Account Number*
                        </label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder=""
                          value=""
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="co-main-btn min-width btn-hover h_40"
                  data-bs-target="#withdrawModal"
                
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button onClick={makeWithdrawal} type="button" class="main-btn min-width btn-hover h_40">
               
                  {isLoading ? (
              <div style={{textAlign:"center", marginLeft:"50px"}}       >
                  <TailSpin
  visible={true}
  height="20"
  width="20"
  color="#f5f5f5"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass=""
  />
                </div>
              
                 
           
              ) : (
                <>
             Submit
                </>
              
              )
}
        
                </button>
              </div>
            </div>
          </div>
        </div>

        <DashboardHeader />

        <Sidebar />

        <div class="wrapper wrapper-body">
          <div class="dashboard-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="d-main-title">
                    <h3>
                      <i class="fa-solid fa-credit-card me-3"></i>Payouts
                    </h3>
                  </div>
                </div>
                <div class="col-md-12">
                  {
                    accounts.length > 0 && (
                      <div class="main-card mt-5">
                      <div class="dashboard-wrap-content p-4">
                        <h5 class="mb-4 fs-5">Enable Direct Payment{' '}
                        <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-right">
                Enabling direct payment means when your ticket is paid for, you get the funds directly to your account
              </Tooltip>
            }
          >
            <span className="question-mark-icon">
            <FaRegCircleQuestion />
            </span>
          </OverlayTrigger>
                         </h5>
                        <div class="d-md-flex flex-wrap align-items-center">
                         
                          <div class=" mt_r4">
                            {
                              currentUser?.directTransfer === true ? (
                                <button
                                onClick={ DisableDirect}
                                  class="main-btn btn-hover h_40 w-100"
                               
                                >
                                 Disable
                                </button>
                              ): (
                                <button
                                onClick={ EnableDirect}
                                  class="main-btn btn-hover h_40 w-100"
                               
                                >
                                  Enable
                                </button>
                              )
                            }
               
                            
                          </div>
                        
                        </div>
                      </div>
                    </div>
                    )
                  }
               
                  <div class="main-card mt-5">
                    <div class="dashboard-wrap-content p-4">
                      <h5 class="mb-4">Added Bank Account ({accounts.length})</h5>
                      <div class="d-md-flex flex-wrap align-items-center">
                        <div class="dashboard-date-wrap">
                          <div class="form-group">
                            <div class="relative-input position-relative">
                              <input
                                class="form-control h_40"
                                type="text"
                                placeholder="Search by bank name"
                                value=""
                              />
                              <i class="uil uil-search"></i>
                            </div>
                          </div>
                        </div>
                        <div class="rs ms-auto mt_r4">
                          <button
                            class="main-btn btn-hover h_40 w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#bankModal"
                          >
                            Add Bank Account
                          </button>
                          
                        </div>
                        &nbsp;
                        <div class=" mt_r4">
                          <button
                            class="main-btn btn-hover h_40 w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#withdrawModal"
                          >
                         Make withdrawal
                          </button>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    accounts.length > 0 && 
                    accounts.map((account) => {
                      const accountNumber = account.accountNumber;
                      const lastFourDigits = accountNumber.slice(-4); // get the last four digits
                      const maskedAccountNumber = '*'.repeat(accountNumber.length - 4) + lastFourDigits;

                      return     (

                        <div class="all-promotion-list">
                        <div class="main-card mt-4 p-4 pt-0">
                          <div class="row">
                            <div class="col-lg-4 col-md-6">
                              <div class="bank-card p-4 mt-4">
                                <h5>{account?.bankName}</h5>
                                {/* <h6>John Doe</h6> */}
                                <span>{maskedAccountNumber}</span>
                                <div class="card-actions">
                                  <a href="#" class="action-link">
                                    <i class="fa-solid fa-pen"></i>
                                  </a>
                                  <a href="#" class="action-link">
                                    <i class="fa-solid fa-trash-can"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                    }
                    
                )
                  }
              
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </body>
    </>
  );
};

export default Payout;
