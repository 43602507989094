import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/parklogo.png";

const Faq = () => {
  return (
    <>
      <body class="d-flex flex-column h-100">
        <Header />

        <div class="wrapper">
          <div class="breadcrumb-block">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-10">
                  <div class="barren-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">
                          Frequently asked questions
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-dt-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="main-title checkout-title text-center">
                    <h3>Frequently asked questions</h3>
                    <p class="mb-0">
                      Find answers to the common questions asked about TicketLoope .
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="main-card mt-5">
                    <div class="bp-title">
                      <h4>Table of contents</h4>
                    </div>
                    <div class="bp-content faq-widget-content">
                      <ul class="faq-widget-links pt_30">
                        <li>
                          <a href="#FAQforOrganisers">FAQ for Organisers</a>
                        </li>
                        <li>
                          <a href="#FAQforAttendees">FAQ for Attendees</a>
                        </li>
                      </ul>
                      {/* <div class="cant-ans-box pt_30">
                        <h4>
                          <i class="fa-solid fa-circle-question me-2 fs-18"></i>
                          Can't find an answer?
                        </h4>
                        <a href="help_center.html">Visit Help Center</a>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-12">
                  <div class="faq-scrollspy">
                    <div
                      class="faq-accordion pt-0 p-2 mt-5"
                      id="FAQforOrganisers"
                    >
                      <div class="faq-accordion-title">
                        <h4>FAQ for Organisers</h4>
                      </div>
                      <div class="accordion" id="accordionFAQforOrganisers">
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading1"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse1"
                              aria-expanded="true"
                              aria-controls="panelsOrgnaisersOpen-collapse1"
                            >
                              What types of events can I set up through TicketLoope ?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse1"
                            class="accordion-collapse collapse show"
                            aria-labelledby="panelsOrgnaisersOpen-heading1"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Ticketloope supports a wide range of events, including but not limited to concerts, conferences, workshops, webinars, sports events, and more. Ticketloope is flexible to accommodate various event types and sizes.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading2"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse2"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse2"
                            >
                              Does Ticketloope offer event management or
                              customer service for my event?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse2"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading2"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Ticketloope provides event registration management and excellent customer service to ensure a smooth and successful experience for you and your attendees. 
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading3"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse3"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse3"
                            >
                              Can my event’s page and my profile reflect my
                              company’s branding?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse3"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading3"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Absolutely! Ticketloope allows you to customize your event page and profile with your company's branding elements, including logos, colors, and banners. This allows your event have professional representation of your brand.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading4"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse4"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse4"
                            >
                              How soon you after my event should I expect to get
                              paid?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse4"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading4"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Payments are processed shortly (within 24hours) after the conclusion of the event. 
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading5"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse5"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse5"
                            >
                              Which platform will Ticketloope use to host my
                              online event and will I have to pay any external
                              charges for my online event?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse5"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading5"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Mauris quis auctor ex.
                                Curabitur id odio in lectus molestie porttitor
                                ac vel turpis. Integer tincidunt non felis et
                                tincidunt. Duis eros leo, porta a felis vitae,
                                facilisis blandit felis. Cras auctor nisl ut sem
                                bibendum, non auctor magna ultrices. Etiam
                                condimentum enim eu dui pharetra, eu sagittis
                                ante mollis. Integer porttitor ultricies
                                bibendum. Aenean mollis odio id ultrices
                                pharetra. Quisque vitae mi nec enim viverra
                                pellentesque.
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading6"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse6"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse6"
                            >
                             Can I add any special requirements at the time of booking?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse6"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading6"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Yes, you can set up custom questions or forms during the booking process to gather specific information or accommodate special requirements from attendees.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading7"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse7"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse7"
                            >
                              Can I add multiple sessions to my online event?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse7"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading7"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Absolutely. Ticketloope allows you to organize and schedule multiple sessions for your online event. This feature is particularly useful for conferences or webinars.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading8"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse8"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse8"
                            >
                              Can I add in any special requirements at the time
                              of booking?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse8"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading8"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Mauris quis auctor ex.
                                Curabitur id odio in lectus molestie porttitor
                                ac vel turpis. Integer tincidunt non felis et
                                tincidunt. Duis eros leo, porta a felis vitae,
                                facilisis blandit felis. Cras auctor nisl ut sem
                                bibendum, non auctor magna ultrices. Etiam
                                condimentum enim eu dui pharetra, eu sagittis
                                ante mollis. Integer porttitor ultricies
                                bibendum. Aenean mollis odio id ultrices
                                pharetra. Quisque vitae mi nec enim viverra
                                pellentesque.
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading9"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse9"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse9"
                            >
                              Can I reserve my event for a selected group of
                              people?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse9"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading9"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                             Yes, Ticketloope gives you options to create private or invitation-only events, allowing you to reserve access for a specific group of people.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading10"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse10"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse10"
                            >
                              Can I limit the number of tickets available to
                              each person?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse10"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading10"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Certainly. You can set limits on the number of tickets an individual can purchase, helping you manage attendance and prevent bulk purchases.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading11"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse11"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse11"
                            >
                              What is the maximum number of people that I can
                              host?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse11"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading11"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              The maximum capacity depends on the type of event and the chosen hosting venue. Ticketloope does not impose a specific limit, but you should consider the capabilities of your event venue or hosting platform.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading12"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse12"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse12"
                            >
                              Can I easily share my event's page?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse12"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading12"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Yes, sharing your event is simple. Ticketloope provides direct links for easy sharing across various platforms.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading13"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse13"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse13"
                            >
                              How do I know who has arrived at my Physical Event?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse13"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading13"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Ticketloope provides services like check-in features to help you track attendance and monitor who has arrived at your venue event.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading14"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse14"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse14"
                            >
                              How do I edit my event after it has been
                              published?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse14"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading14"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              You can easily edit your event details, including date, time, description, and more, by accessing your organizer/host dashboard on Ticketloope
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading15"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse15"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse15"
                            >
                             Can I arrange printed items such as tickets and programs through Ticketloope?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse15"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading15"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Currently, Ticketloope primarily focuses on digital ticketing. However, you can customize and download digital tickets and programs for printing.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading16"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse16"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse16"
                            >
                              Can I change my subscription?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse16"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading16"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Yes, you can manage and change your subscription plan through your account settings on Ticketloope.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading17"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse17"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse17"
                            >
                              What payment options do you accept?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse17"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading17"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Ticketloope ccepts major credit cards, debit cards, and other secure online payment methods. The available options are presented during checkout.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading18"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse18"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse18"
                            >
                             Does Ticketloope charge for free events?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse18"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading18"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Ticketloope does not charge organizers for hosting free events. The platform only charges for paid ticket transactions.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading19"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse19"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse19"
                            >
                        Do my customers need to make an account with Ticketloope?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse19"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading19"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              While creating an account is not mandatory for browsing events, customers will need to create an account to purchase tickets, manage orders, and receive event-related communications.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsOrgnaisersOpen-heading20"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsOrgnaisersOpen-collapse20"
                              aria-expanded="false"
                              aria-controls="panelsOrgnaisersOpen-collapse20"
                            >
                           Where do I get help if I need it?
                            </button>
                          </h2>
                          <div
                            id="panelsOrgnaisersOpen-collapse20"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsOrgnaisersOpen-heading20"
                            data-bs-parent="#accordionFAQforOrganisers"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              If you need assistance, our customer support team is available. Contact us at info@ticketloope.com, and we'll be happy to help you with any questions or concerns.
                              </p>
                            </div>
                          </div>
                        </div>
                    
                      </div>
                    </div>
                    <div
                      class="faq-accordion faq-attendees-accordion pt-0 p-2"
                      id="FAQforAttendees"
                    >
                      <div class="faq-accordion-title">
                        <h4>FAQ for Attendees</h4>
                      </div>
                      <div class="accordion" id="accordionFAQforAttendees">
                        {/* <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading1"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse1"
                              aria-expanded="true"
                              aria-controls="panelsAttendeesOpen-collapse1"
                            >
                              Will my details be shared with any third parties?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse1"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading1"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                No, Ticketloope values your privacy. We do not share your personal details with third parties without your consent. 
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div class="accordion-item">
                          <h2
                            class="accordionpanelsAttendeesOpen-header"
                            id="panelsAttendeesOpen-heading2"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse2"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse2"
                            >
                              Do I need an account to buy tickets from Ticketloope ?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse2"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading2"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              While browsing events does not require an account, you will need to create an account to purchase tickets. This ensures a secure transaction process and allows you to manage your tickets conveniently.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading3"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse3"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse3"
                            >
                              How do I buy tickets for an event?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse3"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading3"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              To purchase tickets, select the desired event, choose your ticket type, and follow the easy checkout process. Create an account or log in to complete your transaction securely.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading4"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse4"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse4"
                            >
                              Why can’t I purchase more than a certain amount of
                              tickets?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse4"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading4"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              To prevent misuse and ensure fair distribution, there may be limits on the number of tickets an individual can purchase for an event. Check the event details for specific restrictions.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading5"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse5"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse5"
                            >
                              How do I inform my organiser of my special
                              requirements for an event?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse5"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading5"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Mauris quis auctor ex.
                                Curabitur id odio in lectus molestie porttitor
                                ac vel turpis. Integer tincidunt non felis et
                                tincidunt. Duis eros leo, porta a felis vitae,
                                facilisis blandit felis. Cras auctor nisl ut sem
                                bibendum, non auctor magna ultrices. Etiam
                                condimentum enim eu dui pharetra, eu sagittis
                                ante mollis. Integer porttitor ultricies
                                bibendum. Aenean mollis odio id ultrices
                                pharetra. Quisque vitae mi nec enim viverra
                                pellentesque.
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading6"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse6"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse6"
                            >
                              When will I receive my tickets?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse6"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading6"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Once your purchase is complete, you will receive an email confirmation with a link to download or print your tickets. You can also access your tickets by logging into your Ticketloope account.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading7"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse7"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse7"
                            >
                              How will I join my online event?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse7"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading7"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Details on how to join your online event will be provided in the event confirmation email. This may include links to the virtual platform or instructions on accessing the event.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading8"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse8"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse8"
                            >
                              Where will I get the best experience out of my
                              online event?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse8"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading8"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              For the best experience, ensure a stable internet connection and use a device that meets the event's technical requirements.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading9"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse9"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse9"
                            >
                              Do I need to print my tickets off?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse9"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading9"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              While digital tickets are convenient, printing them is not mandatory. Ensure that you have the digital tickets accessible on your device at the event venue.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading10"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse10"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse10"
                            >
                              Can I cancel or change my booking?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse10"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading10"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Ticket cancellation and change policies varies. Refer to the event details and our refund policy for specific information. Also feel free to contact our customer support for assistance.

                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading11"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse11"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse11"
                            >
                              I have lost my tickets, can you resend them?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse11"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading11"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                              Yes, log in to your Ticketloope account or check out your email to re-download or print your tickets. Every ticket is unique.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsAttendeesOpen-heading12"
                          >
                            <button
                              class="accordion-btn pe-0 ps-0 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsAttendeesOpen-collapse12"
                              aria-expanded="false"
                              aria-controls="panelsAttendeesOpen-collapse12"
                            >
                              Where can I go to get some help?
                            </button>
                          </h2>
                          <div
                            id="panelsAttendeesOpen-collapse12"
                            class="accordion-collapse collapse"
                            aria-labelledby="panelsAttendeesOpen-heading12"
                            data-bs-parent="#accordionFAQforAttendees"
                          >
                            <div class="accordion-body pt-0 pe-0 ps-0">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Mauris quis auctor ex.
                                Curabitur id odio in lectus molestie porttitor
                                ac vel turpis. Integer tincidunt non felis et
                                tincidunt. Duis eros leo, porta a felis vitae,
                                facilisis blandit felis. Cras auctor nisl ut sem
                                bibendum, non auctor magna ultrices. Etiam
                                condimentum enim eu dui pharetra, eu sagittis
                                ante mollis. Integer porttitor ultricies
                                bibendum. Aenean mollis odio id ultrices
                                pharetra. Quisque vitae mi nec enim viverra
                                pellentesque.
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </>
  );
};

export default Faq;
