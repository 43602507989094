import axios from "axios";

import { environment } from "../environment";


import { useLayoutEffect, useState } from "react";

export type LoginFormData = {
  telephone: string;
  password: string;
};

export type RegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  password: string;
};

export type VerifyOTPFormData = {
  otp: number;
};


export const useAuth = () => {
  // const register = (userData: RegisterFormData) => {
  //   const data = { ...userData, isParkOwner: false };
  //   //data.telephone = `+234${data.telephone.toString().substring(1)}`;
  //   data.telephone = `+234${data.telephone.toString()}`;
  //   return axios.post(`${environment.backendURL}/auth/register`, data);
  // };
  const [items, setItems] = useState([]);
  const login = (userData: LoginFormData) => {
    const data = { ...userData };
    data.telephone = `+234${data.telephone.toString()}`;
    return axios.post(`${environment.backendURL}/auth/login`, data);
  };

  // const resetPwd = (userData: LoginFormData) => {
  //   const data = { ...userData };
  //   data.telephone = `+234${data.telephone.toString()}`;
  //   return axios.post(`${environment.backendURL}/auth/reset`, data);
  // };

  // const verifyResetPassword = (pin: string, pinId: string, userId: string, password: string) => {
  //   const data = {
  //     pinId,
  //     pin,
  //     userId,
  //     password,
  //   };
  //   return axios.post(`${environment.backendURL}/auth/verifyReset`, data);
  // };

 
//   const verifyOTP = (pin: string, pinId: string, userId: string) => {
//     const data = {
//       pinId,
//       pin,
//       userId    
//     };
//    return axios.post(`${environment.backendURL}/auth/verifyOTP`, data);
//  };


  const storeToken = (token: string) => {
    localStorage.setItem( "token", token );
  };

  const logOut = async () => {
    await localStorage.removeItem( "token" );
  };

  

  const storeUserDetails = (userData: any) => {
    localStorage.setItem("user",  JSON.stringify(userData) );
  };

  const getUserDetails = async (temp?: string) => {
    if (!temp) {
      return await localStorage.getItem( "user" );
    } else {
      return await localStorage.getItem(' temp' );
    }
  };

  const getUserId = async () => {
    const user = await getUserDetails();
    if (user) {
      const detail = JSON.parse(user);
      return detail.id;
    }
    return null;
  };

  const getAuthToken = async () => {
    return localStorage.getItem( "token" );
  };

  const isLoggedIn = async (): Promise<boolean> => {
    const token = await localStorage.getItem( "token" );
    return token != null;
  };

  return {
    login,
    storeToken,
    storeUserDetails,
    getUserDetails,
    getAuthToken,
    isLoggedIn,
    getUserId,
    logOut,
    
  };
};

export const useUserId = () => {
  const [userId, setUserId] = useState();
  const { getUserId } = useAuth();

  useLayoutEffect(() => {
    getUserId().then((id) => {
      setUserId(id);
    });
  });

  return userId;
};
