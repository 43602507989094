import React, { useCallback, useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/parklogo.png";
import fun from "../assets/images/fun.jpeg";
import free from "../assets/images/free.jpeg";
import lagos from "../assets/images/lagos.png";
import { useAuth } from "../services/auth/useAuth.ts";
import { useNavigate } from "react-router";
import { AxiosContext } from "../services/axios/index.js";
import { environment } from "../services/environment.jsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState("OTHERS");

  const { isLoggedIn } = useAuth();
  const { getUserDetails } = useAuth();
  const [currentUser, setCurrentUser] = useState("");
  const { logOut } = useAuth();
  const [loggedIn, setLoggedIn] = useState(true);
  const navigate = useNavigate();
  const [allEvents, setAllEvents] = useState([]);
  const axios = useContext(AxiosContext);
  const handlePageOpen = useCallback(
    (props) => {
      navigate(`/event-details/${props.slug}`);
    },
    [navigate]
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${environment.backendURL}/event`);
        const eventData = response.data;
        setAllEvents(eventData);
        // Set loading status to false when data is fetched successfully
        console.log("all events", eventData);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching events:", error);
        // Set loading status to false on error
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const checkLogin = async () => {
      const check = await isLoggedIn();
      setLoggedIn(check);
    };
    checkLogin();
  }, []);
  const filteredEvents = allEvents.filter((event) => {
    return selectedCategory === "OTHERS" || event.category === selectedCategory;
  });

  console.log("filtered", filteredEvents);



  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <>
      <body class="d-flex flex-column h-100 ">
        <Header />
        <div
          class="wrapper"
          // style={{ width: "100%", backgroundColor: "#0E1FC3" }}
        >
          <div class="hero-banner">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="hero-banner-content-home">
                    <h2 style={{ color: "#00509d" }}>
                      {" "}
                      <span style={{ color: "#fc440f" }}>Seamless</span>{" "}
                      Ticketing
                      <br />
                      For All Your{" "}
                      <span style={{ color: "#fc440f" }}>Events</span>
                    </h2>
                    <p
                      style={{
                        color: "#000",
                        letterSpacing: "0.1px",
                        fontWeight: "500",
                      }}
                    >
                      An all-in-one ticketing platform for event organisers,
                      promoters,and managers.
                      <br />
                      Easily create, promote and manage your events of any type
                      and size.
                    </p>
                    <br />
                    <br />
                    <a
                      href={loggedIn ? "/create-event" : "/login"}
                      class=" btn-hover"
                      style={{
                        backgroundColor: "#00509d",
                        padding: "20px ",
                        borderRadius: "10px",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      Create Event <i class="fa-solid fa-arrow-right ms-3"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="explore-events p-80">
            <div class="container">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                  <div class="main-title">
                    <h3>Explore Events</h3>
                  </div>
                </div>
              
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="event-filter-items">
                    <div class="featured-controls">
                      {/* <div class="filter-tag">
                        <a href="/event-by-date" class="active">
                          All
                        </a>
                        <a href="/event-by-date">Today</a>
                        <a href="/event-by-date">Tomorrow</a>
                        <a href="/event-by-date">This Week</a>
                        <a href="/event-by-date">This Weekend</a>
                        <a href="/event-by-date">Next Week</a>
                        <a href="/event-by-date">Next Weekend</a>
                        <a href="/event-by-date">This Month</a>
                        <a href="/event-by-date">Next Month</a>
                        <a href="/event-by-date">This Year</a>
                        <a href="/event-by-date">Next Year</a>
                      </div> */}
                      <div class="controls">
                        <button
                          onClick={() => setSelectedCategory("OTHERS")}
                          class={`control ${selectedCategory === "OTHERS" ? 'selected' : ''}`}
                          type="button"
                         
                          data-filter="others"
                        >
                          All
                        </button>
                        <button
                         onClick={() => setSelectedCategory("ARTS")}
                          type="button"
                          class={`control ${selectedCategory === "ARTS" ? 'selected' : ''}`}
                          data-filter=".arts"
                        >
                          Arts
                        </button>
                        <button
                         onClick={() => setSelectedCategory("SEMINAR")}
                          type="button"
                          class={`control ${selectedCategory === "SEMINAR" ? 'selected' : ''}`}
                          data-filter=".business"
                        >
                          Seminars
                        </button>
                        <button
                          onClick={() => setSelectedCategory("CONCERT")}
                          type="button"
                          class={`control ${selectedCategory === "CONCERT" ? 'selected' : ''}`}
                          data-filter=".concert"
                        >
                          Concert
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TRAINING_WORKSHOP")}
                          type="button"
                          class={`control ${selectedCategory === "TRAINING_WORKSHOP" ? 'selected' : ''}`}
                          data-filter=".workshops"
                        >
                          Workshops
                        </button>
                        <button
                         onClick={() => setSelectedCategory("FASHION")}
                          type="button"
                          class={`control ${selectedCategory === "FASHION" ? 'selected' : ''}`}
                          data-filter=".fashion"
                        >
                         Fashion
                        </button>
                        <button
                         onClick={() => setSelectedCategory("FESTIVALS")}
                          type="button"
                          class={`control ${selectedCategory === "FESTIVALS" ? 'selected' : ''}`}
                          data-filter=".festivals"
                        >
                      Festivals
                        </button>
                        <button
                         onClick={() => setSelectedCategory("FOODANDDRINKS")}
                          type="button"
                          class={`control ${selectedCategory === "FOODANDDRINKS" ? 'selected' : ''}`}
                          data-filter=".FOODNDRINKS"
                        >
                         Food and Drinks
                        </button>
                        <button
                         onClick={() => setSelectedCategory("WEBINARS")}
                          type="button"
                          class={`control ${selectedCategory === "WEBINARS" ? 'selected' : ''}`}
                          data-filter=".webinars"
                        >
                         Webinars
                        </button>
                        <button
                         onClick={() => setSelectedCategory("ONLINE_CLASS")}
                          type="button"
                          data-filter=".online"
                          class={`control ${selectedCategory === "ONLINE_CLASS" ? 'selected' : ''}`}
                        >
                         Online Class
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TALK_SHOW")}
                          type="button"
                          class={`control ${selectedCategory === "TALK_SHOW" ? 'selected' : ''}`}
                          data-filter=".talk"
                        >
                  Talk Show
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TECH")}
                          type="button"
                          class={`control ${selectedCategory === "TECH" ? 'selected' : ''}`}

                          data-filter=".tech"
                        >
                  Tech
                        </button>
                        <button
                         onClick={() => setSelectedCategory("SPORTSANDFITNESS")}
                          type="button"
                          class={`control ${selectedCategory === "SPORTSANDFITNESS" ? 'selected' : ''}`}
                          data-filter=".tech"
                        >
                 Sports and Fitness
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TRAVELANDOUTDOOR")}
                          type="button"
                          class={`control ${selectedCategory === "TRAVELANDOUTDOOR" ? 'selected' : ''}`}
                          data-filter=".travel"
                        >
                 Travel and Outdoor
                        </button>
                        <button
                         onClick={() => setSelectedCategory("VISUALARTS")}
                          type="button"
                          class={`control ${selectedCategory === "VISUALARTS" ? 'selected' : ''}`}

                          data-filter=".visual"
                        >
                 Visual Arts
                        </button>
                      </div>
                      <div class="row" data-ref="event-filter-content">
                        {filteredEvents.slice(0, 8).map((event) => (
                          <div
                            class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                            data-ref="mixitup-target"
                          >
                            <div class="main-card mt-4">
                              <div class="event-thumbnail">
                                <a
                                  href={`/venue-event/${event.slug}`}
                                  class="thumbnail-img"
                                >
                                  <img  style={{objectFit:"contain", }} src={event.images[0]} alt="" />
                                </a>
                                <span
                                  class="bookmark-icon"
                                  title="Bookmark"
                                ></span>
                              </div>
                              <div class="event-content">
                                <a
                                  href={`/venue-event/${event.slug}`}
                                  class="event-title"
                                >
                                  {event.name}
                                </a>
                                <div>
                                  <p class="duration-description">{event.description}</p>
                                  {/* <span class="remaining">6 slot</span> */}
                                </div>
                              </div>
                              <div class="event-footer">
                                <div class="event-timing">
                                  <div class="publish-date">
                                    <span>
                                      <i class="fa-solid fa-calendar-day me-2"></i>
                                      {event.date}
                                    </span>
                                    <span class="dot">
                                      <i class="fa-solid fa-circle"></i>
                                    </span>
                                    <span> {event.time}</span>
                                  </div>
                                  {/* <span class="publish-time">
               <i class="fa-solid fa-clock me-2"></i>1h
             </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {
                          filteredEvents.length < 1 && (
                            <div class="main-card mt-4">
                            <div class="d-flex align-items-center justify-content-center flex-column min-height-430">
                              <div class="event-list-icon">
                                <img src="images/calendar.png" alt="" />
                              </div>
                              <p class="font-16 mt-4 text-light3">
                                No Event Found Under This Category
                              </p>
                            </div>
                          </div>
                          )
                        }
                      </div>


                      <div class="browse-btn">
                        <a href="/explore-events" class="main-btn btn-hover ">
                          Browse All
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="host-engaging-event-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-10">
                  <div class="main-title">
                    <h3>
                      Host Engaging Online and Physical Events with TicketLoope
                    </h3>
                    <p>
                      Organise Physical Events and host online events with
                      unlimited possibilities using our built-in virtual event
                      platform. Build a unique event experience for you and your
                      attendees.
                    </p>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="engaging-block">
                    <div class="owl-carousel engaging-slider owl-theme">
                      <div class="item">
                        <div class="main-card">
                          <div class="host-item">
                            <div class="host-img">
                              <img src="images/icons/venue-events.png" alt="" />
                            </div>
                            <h4>Physical Events</h4>
                            <p>
                              Create outstanding event page for your venue
                              events, attract attendees and sell more tickets.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="host-item">
                            <div class="host-img">
                              <img src="images/icons/webinar.png" alt="" />
                            </div>
                            <h4>Webinar</h4>
                            <p>
                              Webinars tend to be one-way events. TicketLoope
                              helps to make them more engaging.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="host-item">
                            <div class="host-img">
                              <img
                                src="images/icons/training-workshop.png"
                                alt=""
                              />
                            </div>
                            <h4>Training & Workshop </h4>
                            <p>
                              Create and host profitable workshops and training
                              sessions online, sell tickets and earn money.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="host-item">
                            <div class="host-img">
                              <img src="images/icons/online-class.png" alt="" />
                            </div>
                            <h4>Online Class</h4>
                            <p>
                              Try our e-learning template to create a fantastic
                              e-learning event page and drive engagement.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="host-item">
                            <div class="host-img">
                              <img src="images/icons/talk-show.png" alt="" />
                            </div>
                            <h4>Talk Show</h4>
                            <p>
                              Use our intuitive built-in event template to
                              create and host an engaging Talk Show.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-10">
                  <div class="main-title">
                    <h3>Our Ticketloope Features, Get Exactly What You Need</h3>
                    <p>
                      As well as being the most affordable online-based event
                      registration tool and one of the best online event
                      ticketing systems, TicketLoope is super easy-to-use and
                      built with a simplistic layout which is totally convenient
                      for the organisers to operate.
                    </p>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="feature-group-list">
                    <div class="row">
                      {/* <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46">
                          <div class="feature-icon">
                            <img src="images/icons/icon11.png" alt="" />
                          </div>
                          <h4>Online Events</h4>
                          <p>
                            Built-in video conferencing platform to save you
                            time and cost.
                          </p>
                        </div>
                      </div> */}
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon12.png" alt="" />
                          </div>
                          <h4>Physical Event</h4>
                          <p>
                            Easy-to-use features to create and manage your venue
                            events.
                          </p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon13.png" alt="" />
                          </div>
                          <h4>Engaging Event Page</h4>
                          <p>
                            Create engaging event pages with your logo and our
                            hero image collage gallery.
                          </p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon4.png" alt="" />
                          </div>
                          <h4>Marketing Automation</h4>
                          <p>
                            Use our marketing automation tools to promote your
                            events on social media and email.
                          </p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon5.png" alt="" />
                          </div>
                          <h4>Sell Tickets</h4>
                          <p>
                            Start monetising your online and Physical Events,
                            sell unlimited tickets.
                          </p>
                        </div>
                      </div>
                      {/* <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46">
                          <div class="feature-icon">
                            <img src="images/icons/icon6.png" alt="" />
                          </div>
                          <h4>Networking</h4>
                          <p>
                            Engage your attendees with the speakers using our
                            interactive tools and build your own network.
                          </p>
                        </div>
                      </div> */}
                      {/* <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46">
                          <div class="feature-icon">
                            <img src="images/icons/icon7.png" alt="" />
                          </div>
                          <h4>Recording</h4>
                          <p>
                            Securely record your online events and save on the
                            cloud of your choice*.
                          </p>
                        </div>
                      </div> */}
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon8.png" alt="" />
                          </div>
                          <h4>Live Streaming</h4>
                          <p>
                            Livestream your online events on Facebook, YouTube
                            and other social networks.
                          </p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon9.png" alt="" />
                          </div>
                          <h4>Engagement Metrics</h4>
                          <p>
                            Track your event engagement metrics like visitors,
                            ticket sales, etc. from your dashboard.
                          </p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon20.png" alt="" />
                          </div>
                          <h4>Security & Support</h4>
                          <p>
                            Secure data and payment processing backed by a team
                            eager to see you succeed.
                          </p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46 text-center">
                          <div class="feature-icon">
                            <img src="images/icons/icon21.png" alt="" />
                          </div>
                          <h4>Reports & Analytics</h4>
                          <p>
                            Get useful reports and insights to boost your sales
                            and marketing activities.
                          </p>
                        </div>
                      </div>
                      {/* <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="feature-item mt-46">
                          <div class="feature-icon">
                            <img src="images/icons/icon22.png" alt="" />
                          </div>
                          <h4>Mobile & Desktop App</h4>
                          <p>
                            Stay on top of things, manage and monitor your
                            events using the organiser app.
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="host-step-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-10">
                  <div class="main-title">
                    <h3>Be a Star Event Host in 4 Easy Steps</h3>
                    <p>
                      Use early-bird discounts, coupons and group ticketing to
                      double your ticket sale. Get paid quickly and securely.
                    </p>
                  </div>
                </div>
   
                <div class="col-lg-12">
                <Carousel
 additionalTransfrom={0}
 arrows
 autoPlay
 autoPlaySpeed={3000}
 centerMode={false}
 className=""
 containerClass="container"
 dotListClass=""
 draggable
 focusOnSelect={false}
 infinite={true}
 itemClass=""
 keyBoardControl
 minimumTouchDrag={80}
 pauseOnHover
 renderArrowsWhenDisabled={false}
 renderButtonGroupOutside={false}
 renderDotsOutside={false}
 responsive={{
   desktop: {
     breakpoint: {
       max: 3000,
       min: 1024
     },
     items: 1,
     partialVisibilityGutter: 40
   },
   mobile: {
     breakpoint: {
       max: 464,
       min: 0
     },
     items: 1,
     partialVisibilityGutter: 30
   },
   tablet: {
     breakpoint: {
       max: 1024,
       min: 464
     },
     items: 1,
     partialVisibilityGutter: 30
   }
 }}
 rewind={false}
 rewindWithAnimation={false}
 rtl={false}
 shouldResetAutoplay
 showDots={false}
 sliderClass=""
 slidesToSlide={1}
 swipeable
>
<div class="row">
<div class="easy-steps-tab">
                    <div class="nav step-tabs" role="tablist">
                          <button
                          // style={{backgroundColor:"#00509d", color:"white", }}
                        class="step-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#step-01"
                        type="button"
                        role="tab"
                        aria-controls="step-01"
                        aria-selected="true"
                      >
                        Step 01<span style={{color:"white"}}>Create Your Event</span>
                      </button>
                      </div>
                      </div>
                          <div class="col-lg-12 col-md-12">
                   
                            <div class="step-text">
                              Sign up for free and create your event easily in
                              minutes.
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-1.png"
                                  alt=""
                                />
                              </div>
                              <h4>Sign up for free</h4>
                              <p>
                                Sign up easily using your Google or Facebook
                                account or email and create your events in
                                minutes.
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-2.png"
                                  alt=""
                                />
                              </div>
                              <h4>Use built-in event page template</h4>
                              <p>
                                Choose from our customised page templates
                                specially designed to attract attendees.
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-3.png"
                                  alt=""
                                />
                              </div>
                              <h4>Customise your event page as you like</h4>
                              <p>
                                Add logo, collage hero images, and add details
                                to create an outstanding event page.
                              </p>
                            </div>
                          </div>
                        </div>
                
                        <div class="row">
                        <div class="easy-steps-tab">
                    <div class="nav step-tabs" role="tablist">
                      <button
                      style={{backgroundColor:"#00509d",color:"white"}}
                        class="step-link"
                        data-bs-toggle="tab"
                        data-bs-target="#step-02"
                        type="button"
                        role="tab"
                        aria-controls="step-02"
                        aria-selected="true"
                      >
                        Step 02<span style={{color:"white"}}>Sell Tickets and Get Paid</span>
                      </button>
                      </div>
                      </div>
     
                          <div class="col-lg-12 col-md-12">
                            <div class="step-text">
                              Use our multiple ticketing features & marketing
                              automation tools to boost ticket sales.
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-4.png"
                                  alt=""
                                />
                              </div>
                              <h4>
                                Promote your events on social media & email
                              </h4>
                              <p>
                                Use our intuitive event promotion tools to reach
                                your target audience and sell tickets.
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-5.png"
                                  alt=""
                                />
                              </div>
                              <h4>
                                Use early-bird discounts, coupons & group
                                ticketing
                              </h4>
                              <p>
                                Double your ticket sales using our built-in
                                discounts, coupons and group ticketing features.
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-6.png"
                                  alt=""
                                />
                              </div>
                              <h4>Get paid quickly & securely</h4>
                              <p>
                                Use our PCI compliant payment gateways to
                                collect your payment securely.
                              </p>
                            </div>
                          </div>
                        </div>
                      
                      <div class="row">
                      <div class="easy-steps-tab">
                    <div class="nav step-tabs" role="tablist">
                      <button
                            style={{backgroundColor:"#00509d",color:"white"}}
                        class="step-link"
                        data-bs-toggle="tab"
                        data-bs-target="#step-02"
                        type="button"
                        role="tab"
                        aria-controls="step-02"
                        aria-selected="false"
                      >
                                Step 03<span style={{color:"white"}}>Finally, Host Your Event</span>
                      </button>
                      </div>
                      </div> 
                          <div class="col-lg-12 col-md-12">
                            <div class="step-text text-center">
                              Use TicketLoope to host any types of online events
                              for free.
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-7.png"
                                  alt=""
                                />
                              </div>
                              <h4>Free event hosting</h4>
                              <p>
                                Use Eventbookings to host any types of online
                                events for free.
                              </p>
                            </div>
                          </div>
                          {/* <div class="col-lg-4 col-md-6">
                            <div class="step-item">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-8.png"
                                  alt=""
                                />
                              </div>
                              <h4>Built-in video conferencing platform</h4>
                              <p>
                                No need to integrate with ZOOM or other 3rd
                                party apps, use our built-in video conferencing
                                platform for your events.
                              </p>
                            </div>
                          </div> */}
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-9.png"
                                  alt=""
                                />
                              </div>
                              <h4>Connect your attendees with your event</h4>
                              <p>
                                Use our live engagement tools to connect with
                                attendees during the event.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                        <div class="easy-steps-tab">
                    <div class="nav step-tabs" role="tablist">
                      <button
                            style={{backgroundColor:"#00509d",color:"white"}}
                        class="step-link"
                        data-bs-toggle="tab"
                        data-bs-target="#step-02"
                        type="button"
                        role="tab"
                        aria-controls="step-02"
                        aria-selected="false"
                      >
                                Step 04<span style={{color:"white"}}>Repeat and Grow</span>
                      </button>
                      </div>
                      </div> 
                          <div class="col-lg-12 col-md-12">
                            <div class="step-text ">
                              Create more events and earn more money.
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-10.png"
                                  alt=""
                                />
                              </div>
                              <h4>Create multiple sessions & earn more</h4>
                              <p>
                                Use our event scheduling features to create
                                multiple sessions for your events & earn more
                                money.
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-11.png"
                                  alt=""
                                />
                              </div>
                              <h4>Clone past event to create similar events</h4>
                              <p>
                                Use our event cloning feature to clone past
                                event and create a new one easily within a few
                                clicks.
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="step-item text-center">
                              <div class="step-icon">
                                <img
                                  src="images/icons/step-icon-12.png"
                                  alt=""
                                />
                              </div>
                              <h4>Get support like nowhere else</h4>
                              <p>
                                Our dedicated customer support will assist you
                                in becoming an expert in no time.
                              </p>
                            </div>
                          </div>
                        </div>
 
</Carousel>;
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-10">
                  <div class="main-title">
                    <h3>Transforming Thousands of Event Hosts Just Like You</h3>
                    <p>
                      Be part of a winning team. We are continuously thriving to
                      bring the best to our customers.
                    </p>
                  </div>
                </div>
                {/* <div class="col-lg-12">
                  <div class="testimonial-slider-area">
                    <div class="owl-carousel testimonial-slider owl-theme">
                      <div class="item">
                        <div class="main-card">
                          <div class="testimonial-content">
                            <div class="testimonial-text">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vivamus maximus arcu et ligula
                                maximus vehicula. Phasellus at luctus lacus,
                                quis eleifend nibh. Nam vitae convallis nisi,
                                vitae tempus risus.
                              </p>
                            </div>
                            <div class="testimonial-user-dt">
                              <h5>Madeline S.</h5>
                              <span>Events Co-ordinator</span>
                              <ul>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                              </ul>
                            </div>
                            <span class="quote-icon">
                              <i class="fa-solid fa-quote-right"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="testimonial-content">
                            <div class="testimonial-text">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vivamus maximus arcu et ligula
                                maximus vehicula. Phasellus at luctus lacus,
                                quis eleifend nibh. Nam vitae convallis nisi,
                                vitae tempus risus.
                              </p>
                            </div>
                            <div class="testimonial-user-dt">
                              <h5>Gabrielle B.</h5>
                              <span>Administration</span>
                              <ul>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                              </ul>
                            </div>
                            <span class="quote-icon">
                              <i class="fa-solid fa-quote-right"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="testimonial-content">
                            <div class="testimonial-text">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vivamus maximus arcu et ligula
                                maximus vehicula. Phasellus at luctus lacus,
                                quis eleifend nibh. Nam vitae convallis nisi,
                                vitae tempus risus.
                              </p>
                            </div>
                            <div class="testimonial-user-dt">
                              <h5>Piyush G.</h5>
                              <span>Application Developer</span>
                              <ul>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                              </ul>
                            </div>
                            <span class="quote-icon">
                              <i class="fa-solid fa-quote-right"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="testimonial-content">
                            <div class="testimonial-text">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vivamus maximus arcu et ligula
                                maximus vehicula. Phasellus at luctus lacus,
                                quis eleifend nibh. Nam vitae convallis nisi,
                                vitae tempus risus.
                              </p>
                            </div>
                            <div class="testimonial-user-dt">
                              <h5>Joanna P.</h5>
                              <span>Event manager</span>
                              <ul>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                              </ul>
                            </div>
                            <span class="quote-icon">
                              <i class="fa-solid fa-quote-right"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="testimonial-content">
                            <div class="testimonial-text">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vivamus maximus arcu et ligula
                                maximus vehicula. Phasellus at luctus lacus,
                                quis eleifend nibh. Nam vitae convallis nisi,
                                vitae tempus risus.
                              </p>
                            </div>
                            <div class="testimonial-user-dt">
                              <h5>Romo S.</h5>
                              <span>Admin</span>
                              <ul>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                              </ul>
                            </div>
                            <span class="quote-icon">
                              <i class="fa-solid fa-quote-right"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="main-card">
                          <div class="testimonial-content">
                            <div class="testimonial-text">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vivamus maximus arcu et ligula
                                maximus vehicula. Phasellus at luctus lacus,
                                quis eleifend nibh. Nam vitae convallis nisi,
                                vitae tempus risus.
                              </p>
                            </div>
                            <div class="testimonial-user-dt">
                              <h5>Christopher F.</h5>
                              <span>Online Marketing Executive</span>
                              <ul>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                                <li>
                                  <i class="fa-solid fa-star"></i>
                                </li>
                              </ul>
                            </div>
                            <span class="quote-icon">
                              <i class="fa-solid fa-quote-right"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div class="our-organisations-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="main-title text-center">
                    <h3>
                      321+ events created by thousands of organisations around
                      the globe
                    </h3>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="organisations-area">
                    <div class="owl-carousel organisations-slider owl-theme">
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-1.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-2.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-3.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-4.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-5.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-6.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-7.png" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <Footer />
      </body>
    </>
  );
};

export default Home;
