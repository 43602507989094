import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

import { Link, useParams } from "react-router-dom";
import { AxiosContext } from "../services/axios";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../services/environment";
import Dashboard from './Dashboard';

const CreateTick = (props) => {
const axios = useContext(AxiosContext)
// var paramId = props.match.params.id;
// var id = parseInt(paramId);
const { id } = useParams();
const [name, setName] = useState("");
const [price, setPrice] = useState("");
const [currency, setCurrency] = useState("");
const [allTickets, setAllTickets] = useState([]);
// Now you can use the _id in your component
console.log("_id",id);

// Now you can use the _id in your component
// console.log(_id);
// console.log("props", id);
console.log("props", props);


const deleteUser = async (tickid) => {
    await axios.delete(`${environment.backendURL}/ticket/${tickid}`)
      .then((response) => {
        console.log("response",response.data);
       toast("Ticket Deleted");
           window.location.reload();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setError(error);
      })
  
  }


  const createNewTicket = async (e) => {
    
    try {
   const response =   await axios.post(
        `${environment.backendURL}/ticket`,
        {
          eventId: id,
        name: name,
        price: parseInt(price),
        currency: currency,
   
          },
        
      );
      // setShowed(false);
console.log("response", response)
if (response.status === 201) {
  toast("Ticket Created",{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",});
// navigate('/create-ticket');
// await handleModalOpen(response.data._id);
const modal = new bootstrap.Modal(document.getElementById('singleTicketModal'));
modal.hide();
}
      // window.location.reload().then(() => {
      //   setShowToast(true);
      // });
      
    
    } catch (err) {
      console.log(err);
      toast(err.response.data.message[0],{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",})
    } 
    // setShowToast(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${environment.backendURL}/ticket`
        );
        const ticketData = response.data;
        setAllTickets(ticketData);
        // Set loading status to false when data is fetched successfully
        console.log("all tickets", ticketData);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching tickets:", error);
        // Set loading status to false on error
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  return (

      <body class="d-flex flex-column h-100">
        <div
          class="modal fade"
          id="singleTicketModal"
          tabindex="-1"
          aria-labelledby="singleTicketModalLabel"
          aria-hidden="false"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="singleTicketModalLabel">
                  Create Single Ticket
                </h5>
                <button
                  type="button"
                  class="close-model-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="uil uil-multiply"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="model-content main-form">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Ticket Name*</label>
                        <input
                          class="form-control h_40"
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="Event Ticket Name"
                          value={name}
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Ticket Price*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder="Event Ticket Price"
                          value={price}
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group main-form mt-4">
                        <label class="form-label">Currency*</label>
                        <select
                          class="selectpicker"
                          onChange={(e) => setCurrency(e.target.value)}

                          data-size="5"
                          title="Select Currency"
                          data-live-search="true"
                        >
                          <option value="NGN">NGN</option>
                          <option value="USD">USD</option>
                          <option value="USDT">USDT</option>
                        
                        </select>
                      </div>
                    </div>
                    {/* <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-label mb-4 fs-16">
                          Ticket Restrictions
                        </div>
                        <div class="form-group border_bottom">
                          <div class="d-flex align-items-center flex-wrap pb-4 flex-nowrap">
                            <h4 class="fs-14 mb-0 me-auto">
                              Total number of tickets available
                            </h4>
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                id="is-restrict-total-ticket"
                                checked=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div>
                              <label class="fs-12 m-0">Unlimited</label>
                            </div>
                          </div>
                          <div
                            class="p-0 mb-4 total_ticket_per_level"
                            style={{ display: "none" }}
                          >
                            <div class="form-group">
                              <div class="input-number">
                                <input
                                  class="form-control h_40"
                                  type="number"
                                  min="0"
                                  max="30"
                                  placeholder="Enter Total Tickets"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="d-flex align-items-center flex-wrap pt-4 flex-nowrap">
                            <h4 class="fs-14 mb-0 me-auto">
                              Maximum number of tickets for each customer
                            </h4>
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                id="is-restrict-ticket-per-user"
                                checked=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div>
                              <label class="fs-12 m-0">Unlimited</label>
                            </div>
                          </div>
                          <div
                            class="p-0 mt-4 total_ticket_per_user"
                            style={{ display: "none" }}
                          >
                            <div class="form-group">
                              <div class="input-number">
                                <input
                                  class="form-control h_40"
                                  type="number"
                                  min="0"
                                  max="30"
                                  placeholder="Enter Max. per order"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-label mb-4 fs-16">
                          Ticket Restrictions
                        </div>
                        <div class="form-group">
                          <label class="form-label mb-2 fs-14">
                            Ticket Order*
                          </label>
                          <input
                            class="form-control h_40"
                            type="number"
                            value="1"
                            min="1"
                            max="30"
                            placeholder="Enter Sort Order"
                          />
                        </div>
                        <div class="form-group mt-4">
                          <label class="form-label mb-2 fs-14">
                            Ticket Description*
                          </label>
                          <textarea
                            class="form-textarea"
                            placeholder="Description will go here"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="d-flex align-items-center justify-content-between price-variation-head">
                          <h5 class="m-0 color-black fs-16">
                            Variations (
                            <span class="ticket-subtype-count">1</span>)
                          </h5>
                          <span class="add-btn btn-hover">
                            <i class="fa-regular fa-square-plus"></i>
                          </span>
                        </div>
                        <div class="table-responsive">
                          <div class="div-base-table border-0 mt-2 full-width small-table ticket-subtype-table">
                            <div class="table-row table-head no-bg">
                              <div class="table-col fs-14 text-light3">
                                Price <span class="red">*</span>
                              </div>
                              <div class="table-col fs-14 text-light3">
                                Variation Name <span class="red">*</span>
                              </div>
                              <div class="table-col fs-14 text-light3 text-right">
                                Actions
                              </div>
                            </div>
                            <div class="table-row ticket-subtype-row">
                              <div class="table-col first-col">
                                <div class="form-group m-0 form-custom-validation">
                                  <input
                                    class="form-control h_40"
                                    type="number"
                                    min="0"
                                    max="10000"
                                    required=""
                                    placeholder="Price"
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="table-col second-col">
                                <div class="form-group m-0">
                                  <input
                                    class="form-control h_40"
                                    type="text"
                                    maxlength="75"
                                    required=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="table-col third-col">
                                <button
                                  type="button"
                                  class="v-btn-close btn-hover"
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-group">
                          <div class="d-flex align-items-start">
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                class=""
                                id="bird-discount"
                                value=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div class="d-flex flex-column">
                              <label class="color-black mb-1">
                                I want to offer early bird discount.
                              </label>
                              <p class="mt-2 fs-14 d-block mb-3">
                                Enabling this discount lets your attendees get
                                all the regular tickets features at a discounted
                                price.{" "}
                                <a href="#" class="a-link">
                                  Learn more
                                </a>
                              </p>
                            </div>
                          </div>
                          <div
                            class="online-event-discount-wrapper"
                            style={{ display: "none" }}
                          >
                            <div class="row g-3">
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Discount*
                                </label>
                                <input
                                  class="form-control h_40"
                                  type="text"
                                  placeholder="0"
                                  value=""
                                />
                              </div>
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Price*
                                </label>
                                <select class="selectpicker">
                                  <option value="Percentage" selected="">
                                    Percent(%)
                                  </option>
                                  <option value="Fixed">Fixed($)</option>
                                </select>
                              </div>
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Discount ends on*
                                </label>
                                <div class="loc-group position-relative">
                                  <input
                                    class="form-control h_40 datepicker-here"
                                    data-language="en"
                                    data-position="top left"
                                    type="text"
                                    placeholder="MM/DD/YYYY"
                                    value=""
                                  />
                                  <span class="absolute-icon top_0">
                                    <i class="fa-solid fa-calendar-days"></i>
                                  </span>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="clock-icon">
                                  <label class="form-label mt-3 fs-6">
                                    Time
                                  </label>
                                  <select
                                    class="selectpicker"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <option value="00:00">12:00 AM</option>
                                    <option value="00:15">12:15 AM</option>
                                    <option value="00:30">12:30 AM</option>
                                    <option value="00:45">12:45 AM</option>
                                    <option value="01:00">01:00 AM</option>
                                    <option value="01:15">01:15 AM</option>
                                    <option value="01:30">01:30 AM</option>
                                    <option value="01:45">01:45 AM</option>
                                    <option value="02:00">02:00 AM</option>
                                    <option value="02:15">02:15 AM</option>
                                    <option value="02:30">02:30 AM</option>
                                    <option value="02:45">02:45 AM</option>
                                    <option value="03:00">03:00 AM</option>
                                    <option value="03:15">03:15 AM</option>
                                    <option value="03:30">03:30 AM</option>
                                    <option value="03:45">03:45 AM</option>
                                    <option value="04:00">04:00 AM</option>
                                    <option value="04:15">04:15 AM</option>
                                    <option value="04:30">04:30 AM</option>
                                    <option value="04:45">04:45 AM</option>
                                    <option value="05:00">05:00 AM</option>
                                    <option value="05:15">05:15 AM</option>
                                    <option value="05:30">05:30 AM</option>
                                    <option value="05:45">05:45 AM</option>
                                    <option value="06:00">06:00 AM</option>
                                    <option value="06:15">06:15 AM</option>
                                    <option value="06:30">06:30 AM</option>
                                    <option value="06:45">06:45 AM</option>
                                    <option value="07:00">07:00 AM</option>
                                    <option value="07:15">07:15 AM</option>
                                    <option value="07:30">07:30 AM</option>
                                    <option value="07:45">07:45 AM</option>
                                    <option value="08:00">08:00 AM</option>
                                    <option value="08:15">08:15 AM</option>
                                    <option value="08:30">08:30 AM</option>
                                    <option value="08:45">08:45 AM</option>
                                    <option value="09:00">09:00 AM</option>
                                    <option value="09:15">09:15 AM</option>
                                    <option value="09:30">09:30 AM</option>
                                    <option value="09:45">09:45 AM</option>
                                    <option value="10:00" selected="selected">
                                      10:00 AM
                                    </option>
                                    <option value="10:15">10:15 AM</option>
                                    <option value="10:30">10:30 AM</option>
                                    <option value="10:45">10:45 AM</option>
                                    <option value="11:00">11:00 AM</option>
                                    <option value="11:15">11:15 AM</option>
                                    <option value="11:30">11:30 AM</option>
                                    <option value="11:45">11:45 AM</option>
                                    <option value="12:00">12:00 PM</option>
                                    <option value="12:15">12:15 PM</option>
                                    <option value="12:30">12:30 PM</option>
                                    <option value="12:45">12:45 PM</option>
                                    <option value="13:00">01:00 PM</option>
                                    <option value="13:15">01:15 PM</option>
                                    <option value="13:30">01:30 PM</option>
                                    <option value="13:45">01:45 PM</option>
                                    <option value="14:00">02:00 PM</option>
                                    <option value="14:15">02:15 PM</option>
                                    <option value="14:30">02:30 PM</option>
                                    <option value="14:45">02:45 PM</option>
                                    <option value="15:00">03:00 PM</option>
                                    <option value="15:15">03:15 PM</option>
                                    <option value="15:30">03:30 PM</option>
                                    <option value="15:45">03:45 PM</option>
                                    <option value="16:00">04:00 PM</option>
                                    <option value="16:15">04:15 PM</option>
                                    <option value="16:30">04:30 PM</option>
                                    <option value="16:45">04:45 PM</option>
                                    <option value="17:00">05:00 PM</option>
                                    <option value="17:15">05:15 PM</option>
                                    <option value="17:30">05:30 PM</option>
                                    <option value="17:45">05:45 PM</option>
                                    <option value="18:00">06:00 PM</option>
                                    <option value="18:15">06:15 PM</option>
                                    <option value="18:30">06:30 PM</option>
                                    <option value="18:45">06:45 PM</option>
                                    <option value="19:00">07:00 PM</option>
                                    <option value="19:15">07:15 PM</option>
                                    <option value="19:30">07:30 PM</option>
                                    <option value="19:45">07:45 PM</option>
                                    <option value="20:00">08:00 PM</option>
                                    <option value="20:15">08:15 PM</option>
                                    <option value="20:30">08:30 PM</option>
                                    <option value="20:45">08:45 PM</option>
                                    <option value="21:00">09:00 PM</option>
                                    <option value="21:15">09:15 PM</option>
                                    <option value="21:30">09:30 PM</option>
                                    <option value="21:45">09:45 PM</option>
                                    <option value="22:00">10:00 PM</option>
                                    <option value="22:15">10:15 PM</option>
                                    <option value="22:30">10:30 PM</option>
                                    <option value="22:45">10:45 PM</option>
                                    <option value="23:00">11:00 PM</option>
                                    <option value="23:15">11:15 PM</option>
                                    <option value="23:30">11:30 PM</option>
                                    <option value="23:45">11:45 PM</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="co-main-btn min-width btn-hover h_40"
                  data-bs-target="#aboutModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button onClick={() => createNewTicket()} type="button" class="main-btn min-width btn-hover h_40">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="groupTicketModal"
          tabindex="-1"
          aria-labelledby="groupTicketModalLabel"
          aria-hidden="false"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="groupTicketModalLabel">
                  Create Group Ticket
                </h5>
                <button
                  type="button"
                  class="close-model-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="uil uil-multiply"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="model-content main-form">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label">Ticket Name*</label>
                        <input
                          class="form-control h_40"
                          type="text"
                          placeholder="Enter Ticket Type - Group Name (E.g Gold - Family Pass)"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-label mb-4 fs-16">
                          Ticket Restrictions
                        </div>
                        <div class="form-group border_bottom">
                          <div class="d-flex align-items-center flex-wrap pb-4 flex-nowrap">
                            <h4 class="fs-14 mb-0 me-auto">
                              Total number of tickets available
                            </h4>
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                id="is-restrict-total-ticket2"
                                checked=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div>
                              <label class="fs-12 m-0">Unlimited</label>
                            </div>
                          </div>
                          <div
                            class="p-0 mb-4 total_ticket_per_level2"
                            style={{ display: "none" }}
                          >
                            <div class="form-group">
                              <div class="input-number">
                                <input
                                  class="form-control h_40"
                                  type="number"
                                  min="0"
                                  max="30"
                                  placeholder="Enter Total Tickets"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="d-flex align-items-center flex-wrap pt-4 flex-nowrap">
                            <h4 class="fs-14 mb-0 me-auto">
                              Maximum number of tickets for each customer
                            </h4>
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                id="is-restrict-ticket-per-user2"
                                checked=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div>
                              <label class="fs-12 m-0">Unlimited</label>
                            </div>
                          </div>
                          <div
                            class="p-0 mt-4 total_ticket_per_user2"
                            style={{ display: "none" }}
                          >
                            <div class="form-group">
                              <div class="input-number">
                                <input
                                  class="form-control h_40"
                                  type="number"
                                  min="0"
                                  max="30"
                                  placeholder="Enter Max. per order"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-label mb-4 fs-16">
                          Ticket Restrictions
                        </div>
                        <div class="form-group">
                          <label class="form-label mb-2 fs-14">
                            Ticket Order*
                          </label>
                          <input
                            class="form-control h_40"
                            type="number"
                            value="1"
                            min="1"
                            max="30"
                            placeholder="Enter Sort Order"
                          />
                        </div>
                        <div class="form-group mt-4">
                          <label class="form-label mb-2 fs-14">
                            Ticket Description*
                          </label>
                          <textarea
                            class="form-textarea"
                            placeholder="Description will go here"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label mb-2 fs-14">
                          Ticket Price*
                        </label>
                        <input
                          class="form-control h_40"
                          type="number"
                          value="10"
                          placeholder="Enter Ticket Price"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mt-4">
                        <label class="form-label mb-2 fs-14">
                          Number of tickets for each group*
                        </label>
                        <input
                          class="form-control h_40"
                          type="number"
                          min="0"
                          value=""
                          placeholder="Enter Total Tickets Per Group"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="main-card p-4 mt-4">
                        <div class="form-group">
                          <div class="d-flex align-items-start">
                            <label class="btn-switch m-0 me-3">
                              <input
                                type="checkbox"
                                class=""
                                id="bird-discount2"
                                value=""
                              />
                              <span class="checkbox-slider"></span>
                            </label>
                            <div class="d-flex flex-column">
                              <label class="color-black mb-1">
                                I want to offer early bird discount.
                              </label>
                              <p class="mt-2 fs-14 d-block mb-3">
                                Enabling this discount lets your attendees get
                                all the regular tickets features at a discounted
                                price.{" "}
                                <a href="#" class="a-link">
                                  Learn more
                                </a>
                              </p>
                            </div>
                          </div>
                          <div
                            class="online-event-discount-wrapper2"
                            style={{ display: "none" }}
                          >
                            <div class="row g-3">
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Discount*
                                </label>
                                <input
                                  class="form-control h_40"
                                  type="text"
                                  placeholder="0"
                                  value=""
                                />
                              </div>
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Price*
                                </label>
                                <select class="selectpicker">
                                  <option value="Percentage" selected="">
                                    Percent(%)
                                  </option>
                                  <option value="Fixed">Fixed($)</option>
                                </select>
                              </div>
                              <div class="col-md-3">
                                <label class="form-label mt-3 fs-6">
                                  Discount ends on*
                                </label>
                                <div class="loc-group position-relative">
                                  <input
                                    class="form-control h_40 datepicker-here"
                                    data-language="en"
                                    data-position="top left"
                                    type="text"
                                    placeholder="MM/DD/YYYY"
                                    value=""
                                  />
                                  <span class="absolute-icon top_0">
                                    <i class="fa-solid fa-calendar-days"></i>
                                  </span>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="clock-icon">
                                  <label class="form-label mt-3 fs-6">
                                    Time
                                  </label>
                                  <select
                                    class="selectpicker"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <option value="00:00">12:00 AM</option>
                                    <option value="00:15">12:15 AM</option>
                                    <option value="00:30">12:30 AM</option>
                                    <option value="00:45">12:45 AM</option>
                                    <option value="01:00">01:00 AM</option>
                                    <option value="01:15">01:15 AM</option>
                                    <option value="01:30">01:30 AM</option>
                                    <option value="01:45">01:45 AM</option>
                                    <option value="02:00">02:00 AM</option>
                                    <option value="02:15">02:15 AM</option>
                                    <option value="02:30">02:30 AM</option>
                                    <option value="02:45">02:45 AM</option>
                                    <option value="03:00">03:00 AM</option>
                                    <option value="03:15">03:15 AM</option>
                                    <option value="03:30">03:30 AM</option>
                                    <option value="03:45">03:45 AM</option>
                                    <option value="04:00">04:00 AM</option>
                                    <option value="04:15">04:15 AM</option>
                                    <option value="04:30">04:30 AM</option>
                                    <option value="04:45">04:45 AM</option>
                                    <option value="05:00">05:00 AM</option>
                                    <option value="05:15">05:15 AM</option>
                                    <option value="05:30">05:30 AM</option>
                                    <option value="05:45">05:45 AM</option>
                                    <option value="06:00">06:00 AM</option>
                                    <option value="06:15">06:15 AM</option>
                                    <option value="06:30">06:30 AM</option>
                                    <option value="06:45">06:45 AM</option>
                                    <option value="07:00">07:00 AM</option>
                                    <option value="07:15">07:15 AM</option>
                                    <option value="07:30">07:30 AM</option>
                                    <option value="07:45">07:45 AM</option>
                                    <option value="08:00">08:00 AM</option>
                                    <option value="08:15">08:15 AM</option>
                                    <option value="08:30">08:30 AM</option>
                                    <option value="08:45">08:45 AM</option>
                                    <option value="09:00">09:00 AM</option>
                                    <option value="09:15">09:15 AM</option>
                                    <option value="09:30">09:30 AM</option>
                                    <option value="09:45">09:45 AM</option>
                                    <option value="10:00" selected="selected">
                                      10:00 AM
                                    </option>
                                    <option value="10:15">10:15 AM</option>
                                    <option value="10:30">10:30 AM</option>
                                    <option value="10:45">10:45 AM</option>
                                    <option value="11:00">11:00 AM</option>
                                    <option value="11:15">11:15 AM</option>
                                    <option value="11:30">11:30 AM</option>
                                    <option value="11:45">11:45 AM</option>
                                    <option value="12:00">12:00 PM</option>
                                    <option value="12:15">12:15 PM</option>
                                    <option value="12:30">12:30 PM</option>
                                    <option value="12:45">12:45 PM</option>
                                    <option value="13:00">01:00 PM</option>
                                    <option value="13:15">01:15 PM</option>
                                    <option value="13:30">01:30 PM</option>
                                    <option value="13:45">01:45 PM</option>
                                    <option value="14:00">02:00 PM</option>
                                    <option value="14:15">02:15 PM</option>
                                    <option value="14:30">02:30 PM</option>
                                    <option value="14:45">02:45 PM</option>
                                    <option value="15:00">03:00 PM</option>
                                    <option value="15:15">03:15 PM</option>
                                    <option value="15:30">03:30 PM</option>
                                    <option value="15:45">03:45 PM</option>
                                    <option value="16:00">04:00 PM</option>
                                    <option value="16:15">04:15 PM</option>
                                    <option value="16:30">04:30 PM</option>
                                    <option value="16:45">04:45 PM</option>
                                    <option value="17:00">05:00 PM</option>
                                    <option value="17:15">05:15 PM</option>
                                    <option value="17:30">05:30 PM</option>
                                    <option value="17:45">05:45 PM</option>
                                    <option value="18:00">06:00 PM</option>
                                    <option value="18:15">06:15 PM</option>
                                    <option value="18:30">06:30 PM</option>
                                    <option value="18:45">06:45 PM</option>
                                    <option value="19:00">07:00 PM</option>
                                    <option value="19:15">07:15 PM</option>
                                    <option value="19:30">07:30 PM</option>
                                    <option value="19:45">07:45 PM</option>
                                    <option value="20:00">08:00 PM</option>
                                    <option value="20:15">08:15 PM</option>
                                    <option value="20:30">08:30 PM</option>
                                    <option value="20:45">08:45 PM</option>
                                    <option value="21:00">09:00 PM</option>
                                    <option value="21:15">09:15 PM</option>
                                    <option value="21:30">09:30 PM</option>
                                    <option value="21:45">09:45 PM</option>
                                    <option value="22:00">10:00 PM</option>
                                    <option value="22:15">10:15 PM</option>
                                    <option value="22:30">10:30 PM</option>
                                    <option value="22:45">10:45 PM</option>
                                    <option value="23:00">11:00 PM</option>
                                    <option value="23:15">11:15 PM</option>
                                    <option value="23:30">11:30 PM</option>
                                    <option value="23:45">11:45 PM</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="co-main-btn min-width btn-hover h_40"
                  data-bs-target="#aboutModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="button" class="main-btn min-width btn-hover h_40">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <Header />

        <div class="wrapper">
          <div class="event-dt-block p-80">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12">
                  <div class="main-title text-center">
                    <h3>Create Ticket</h3>
                  </div>
                </div>
                
                <div class="col-xl-8 col-lg-9 col-md-12">
                  <div class="wizard-steps-block">
                    <div id="add-event-tab" class="step-app">
                      <div class="step-content">
                        <div
                          class="step-tab-panel step-tab-gallery"
                          id="tab_step2"
                        >
                          <div class="tab-from-content">
                            <div class="main-card">
                              <div class="bp-title">
                                <h4>
                                  <i class="fa-solid fa-ticket step_icon me-3"></i>
                                  Tickets
                                </h4>
                              </div>
                              <div class="bp-form main-form">
                                <div class="p-4 form-group border_bottom pb_30">
                                  <div class="">
                                    <div class="ticket-section">
                                      <label class="form-label fs-16">
                                        Let's create tickets!
                                      </label>
                                      <p class="mt-2 fs-14 d-block mb-3 pe_right">
                                        Create tickets for your event by
                                        clicking on the 'Add Tickets' button
                                        below.
                                      </p>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between pt-4 pb-3 full-width">
                                      <h3 class="fs-18 mb-0">
                                        Tickets (
                                        <span class="venue-event-ticket-counter">
                                         {allTickets.length > 0 ? allTickets.length : "0"}
                                        </span>
                                        )
                                      </h3>
                                      <div class="dropdown dropdown-default dropdown-normal btn-ticket-type-top">
                                        <button
                                          class="dropdown-toggle main-btn btn-hover h_40 pe-4 ps-4"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <span>Add Tickets</span>
                                        </button>
                                        <div
                                          class="dropdown-menu dropdown-menu-right"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <a
                                            class="dropdown-item"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#singleTicketModal"
                                          >
                                            <i class="fa-solid fa-ticket me-2"></i>
                                            Single Ticket
                                          </a>
                                          <a
                                            class="dropdown-item"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#groupTicketModal"
                                          >
                                            <i class="fa-solid fa-ticket me-2"></i>
                                            Group Ticket
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      allTickets.length < 1 ? (
                                        <div class="ticket-type-item-empty  text-center p_30">
                                        <div class="ticket-list-icon d-inline-block">
                                          <img src="images/ticket.png" alt="" />
                                        </div>
                                        <h4 class="color-black mt-4 mb-3 fs-18">
                                          You have no tickets yet.
                                        </h4>
                                        <p class="mb-0">
                                          You have not created a ticket yet.
                                          Please click the button above to create
                                          your event ticket.
                                        </p>
                                      </div>
                                      ): (
                                        ""
                                      )
                                    }
                                  
                                {
                                  allTickets.length > 0 ? (
allTickets.map((tickets) => {
  return (
        <div class="price-ticket-card mt-4">
                                        <div class="price-ticket-card-head d-md-flex flex-wrap align-items-start justify-content-between position-relative p-4">
                                          <div class="d-flex align-items-center top-name">
                                            <div class="icon-box">
                                              <span class="icon-big rotate-icon icon icon-purple">
                                                <i class="fa-solid fa-ticket"></i>
                                              </span>
                                              <h5 class="fs-16 mb-1 mt-1">
                                                {tickets?.name} - {tickets?.price + " " + tickets?.currency}
                                              </h5>
                                              <p class="text-gray-50 m-0">
                                                {/* <span class="visitor-date-time">
                                                  May 3, 2024
                                                </span> */}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            {/* <div class="price-badge">
                                              <img
                                                src="images/discount.png"
                                                alt=""
                                              />
                                            </div> */}
                                            {/* <label class="btn-switch tfs-8 mb-0 me-4 mt-1">
                                              <input
                                                type="checkbox"
                                                value=""
                                                checked
                                              />
                                              <span class="checkbox-slider"></span>
                                            </label> */}
                                            <div class="dropdown dropdown-default dropdown-text dropdown-icon-item">
                                              <button
                                                class="option-btn-1"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i class="fa-solid fa-ellipsis-vertical"></i>
                                              </button>
                                              <div class="dropdown-menu dropdown-menu-end">
                                                {/* <a
                                                  href="#"
                                                  class="dropdown-item"
                                                >
                                                  <i class="fa-solid fa-pen me-3"></i>
                                                  Edit
                                                </a> */}
                                                <a
                                                  onClick={() => deleteUser(tickets?._id)}
                                                  class="dropdown-item"
                                                >
                                                  <i class="fa-solid fa-trash-can me-3"></i>
                                                  Delete
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="price-ticket-card-body border_top p-4">
                                          {/* <div class="full-width d-flex flex-wrap justify-content-between align-items-center">
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-ticket"></i>
                                              </div>
                                              <span class="text-145">
                                                Total tickets
                                              </span>
                                              <h6 class="coupon-status">20</h6>
                                            </div>
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-users"></i>
                                              </div>
                                              <span class="text-145">
                                                Ticket limit per customer
                                              </span>
                                              <h6 class="coupon-status">2</h6>
                                            </div>
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-cart-shopping"></i>
                                              </div>
                                              <span class="text-145">
                                                Discount
                                              </span>
                                              <h6 class="coupon-status">5%</h6>
                                            </div>
                                          </div> */}
                                        </div>
                                      </div>
  )
})
                                  ):(
                    ""
                                  )
                                }
                                    <div class="ticket-type-item-list mt-4">
                                      {/* <div class="price-ticket-card mt-4">
                                        <div class="price-ticket-card-head d-md-flex flex-wrap align-items-start justify-content-between position-relative p-4">
                                          <div class="d-flex align-items-center top-name">
                                            <div class="icon-box">
                                              <span class="icon-big rotate-icon icon icon-purple">
                                                <i class="fa-solid fa-ticket"></i>
                                              </span>
                                              <h5 class="fs-16 mb-1 mt-1">
                                                New Small - $10.00
                                              </h5>
                                              <p class="text-gray-50 m-0">
                                                <span class="visitor-date-time">
                                                  May 3, 2024
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <div class="price-badge">
                                              <img
                                                src="images/discount.png"
                                                alt=""
                                              />
                                            </div>
                                            <label class="btn-switch tfs-8 mb-0 me-4 mt-1">
                                              <input
                                                type="checkbox"
                                                value=""
                                                checked
                                              />
                                              <span class="checkbox-slider"></span>
                                            </label>
                                            <div class="dropdown dropdown-default dropdown-text dropdown-icon-item">
                                              <button
                                                class="option-btn-1"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i class="fa-solid fa-ellipsis-vertical"></i>
                                              </button>
                                              <div class="dropdown-menu dropdown-menu-end">
                                                <a
                                                  href="#"
                                                  class="dropdown-item"
                                                >
                                                  <i class="fa-solid fa-pen me-3"></i>
                                                  Edit
                                                </a>
                                                <a
                                                  href="#"
                                                  class="dropdown-item"
                                                >
                                                  <i class="fa-solid fa-trash-can me-3"></i>
                                                  Delete
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="price-ticket-card-body border_top p-4">
                                          <div class="full-width d-flex flex-wrap justify-content-between align-items-center">
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-ticket"></i>
                                              </div>
                                              <span class="text-145">
                                                Total tickets
                                              </span>
                                              <h6 class="coupon-status">20</h6>
                                            </div>
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-users"></i>
                                              </div>
                                              <span class="text-145">
                                                Ticket limit per customer
                                              </span>
                                              <h6 class="coupon-status">2</h6>
                                            </div>
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-cart-shopping"></i>
                                              </div>
                                              <span class="text-145">
                                                Discount
                                              </span>
                                              <h6 class="coupon-status">5%</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      {/* <div class="price-ticket-card mt-4">
                                        <div class="price-ticket-card-head d-md-flex flex-wrap align-items-start justify-content-between position-relative p-4">
                                          <div class="d-flex align-items-center top-name">
                                            <div class="icon-box">
                                              <span class="icon-big rotate-icon icon icon-yellow">
                                                <i class="fa-solid fa-ticket"></i>
                                              </span>
                                              <h5 class="fs-16 mb-1 mt-1">
                                                Group - $10.00
                                              </h5>
                                              <p class="text-gray-50 m-0">
                                                <span class="visitor-date-time">
                                                  May 3, 2024
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <div class="price-badge">
                                              <img
                                                src="images/discount.png"
                                                alt=""
                                              />
                                            </div>
                                            <label class="btn-switch tfs-8 mb-0 me-4 mt-1">
                                              <input
                                                type="checkbox"
                                                value=""
                                                checked
                                              />
                                              <span class="checkbox-slider"></span>
                                            </label>
                                            <div class="dropdown dropdown-default dropdown-text dropdown-icon-item">
                                              <button
                                                class="option-btn-1"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i class="fa-solid fa-ellipsis-vertical"></i>
                                              </button>
                                              <div class="dropdown-menu dropdown-menu-end">
                                                <a
                                                  href="#"
                                                  class="dropdown-item"
                                                >
                                                  <i class="fa-solid fa-pen me-3"></i>
                                                  Edit
                                                </a>
                                                <a
                                                  href="#"
                                                  class="dropdown-item"
                                                >
                                                  <i class="fa-solid fa-trash-can me-3"></i>
                                                  Delete
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="price-ticket-card-body border_top p-4">
                                          <div class="full-width d-flex flex-wrap justify-content-between align-items-center">
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-ticket"></i>
                                              </div>
                                              <span class="text-145">
                                                Total tickets
                                              </span>
                                              <h6 class="coupon-status">
                                                Unlimited
                                              </h6>
                                            </div>
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-users"></i>
                                              </div>
                                              <span class="text-145">
                                                Ticket limit per customer
                                              </span>
                                              <h6 class="coupon-status">
                                                Unlimited
                                              </h6>
                                            </div>
                                            <div class="icon-box">
                                              <div class="icon me-3">
                                                <i class="fa-solid fa-cart-shopping"></i>
                                              </div>
                                              <span class="text-145">
                                                Discount
                                              </span>
                                              <h6 class="coupon-status">2%</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="step-footer step-tab-pager mt-4">
                        <Link to="/create-event-venue">
                          <button
                            data-direction="prev"
                            class="btn btn-default btn-hover steps_btn"
                          >
                            Previous
                          </button>
                        </Link>
                        &nbsp;
                        <Link to="/dashboard">
                          <button
                            data-direction="next"
                            class="btn btn-default btn-hover steps_btn"
                          >
                     Dashboard
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}
        <Footer />
      </body>
  
  );
};

export default CreateTick;
