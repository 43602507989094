import {client} from "./client";


export const getPosts = async () => {
    try {
        const entries = await client.getEntries({
            content_type: "ticketloopeBlogPost",

        });
        const filteredEntries = entries.items.map((data) => {
            const filteredEntry = data.fields;
            return filteredEntry;
        });
        return filteredEntries;
    } catch (error) {
        console.log("contentful err", error);
    }
};

// export const getFeaturedPosts = async () => {
//     try {
//         const entries = await client.getEntries({
//             content_type: "pageLanding",

//         });
//         const filteredEntries = entries.items.map((data) => {
//             const filteredEntry = data.fields;
//             return filteredEntry;
//         });
//         return filteredEntries;
//     } catch (error) {
//         console.log("contentful err", error);
//     }
// };

export const getSinglePost = async (slug) => {
    try {
        const entries = await client.getEntries({
            "fields.slug": slug,
            content_type: "ticketloopeBlogPost",

        });
        return entries;
    } catch (error) {
        console.log(error);
    }
}