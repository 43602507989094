import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const About = () => {
  return (
    <>
      <body class="d-flex flex-column h-100">
        <div
          class="modal fade"
          id="communitieModal"
          tabindex="-1"
          aria-labelledby="communitieModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
    
                <h5 style={{color: "#00509d"}} class="modal-title" id="communitieModalLabel">
                We are all about making event ticketing seamless
                </h5>
                <button
                  type="button"
                  class="close-model-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="uil uil-multiply"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="model-content main-form mt-4">
                  <p class="mb-4">
     
                  </p>
                  <p class="mb-0">
    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Header />

        <div class="wrapper">
          <div class="hero-banner">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-9 col-md-10">
                  <div class="hero-banner-content-about text-start">
                    <h2 class="mb-0" >
                      We are a team that loves supporting the community through
                      events
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="explore-events p-40">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="main-title checkout-title">
                    <h3>
                      Our aim is to provide a powerful and affordable event
                      ticketing solution that can handle events of any size and
                      complexity without an issue.
                    </h3>
                  </div>
                  <div class="about--description">
                    <p>
                      Ticketloope online event ticketing software was built on
                      the idea that anyone, anywhere in the world wanting to
                      organise an event should have the tools to simply do so.
                    
                    </p>
                    <p>
                      The focus of our efforts is the event organiser, who works
                      hard to ensure that their attendees have a great
                      experience. We share that passion for bringing people
                      together, creating memories and enriching lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="host-engaging-event-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="main-title checkout-title text-center">
                    <h3>We are all about making event ticketing seamless</h3>
                  </div>
                  <div class="communities-steps">
                    <div class="row">
                      <div class="col-lg-4 col-md-12">
                        <a
                          href="#"
                          class="main-card communities-item"
                   
                        >
                          <div class="communities-img text-center">
                            <img style={{width:"220px",height:"200px"}} src="images/about/discount.png" alt="" />
                          </div>
                          <div class="communities-content">
                            <p>
                              We work with several industry leaders and
                              community groups around the world and we want all of
                              our event organisers to succeed in everything they
                              do. Events are not always easy, but selling
                              tickets online should be.
                            </p>
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <a
                          href="#"
                          class="main-card communities-item"
                     
                        >
                          <div class="communities-img text-center">
                            <img style={{width:"207px", height:"201px"}} src="images/about/percent.png" alt="" />
                          </div>
                          <div class="communities-content">
                            <p>
                              We are committed to making a positive impact on
                              the community. That's why our pricing structure is
                              set so that everyone can afford to use Ticketloope
                              , and it's why we offer our system free of charge
                              for any free events or registrations.
                            </p>
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <a
                          href="#"
                          class="main-card communities-item"
                      
                        >
                          <div class="communities-img text-center">
                            <img style={{width:"297px", height:"221px"}} src="images/about/chart.png" alt="" />
                          </div>
                          <div class="communities-content">
                            <p>
                              This provides an additional revenue stream for
                              your charity and allows us to positively
                              contribute to the causes that are important to you
                              and your community.
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-10">
                  <div class="main-title mb-4">
                    <h3>
                      The Complete Event Ticketing Platform with a Host of
                      Features
                    </h3>
                    <p>
                      Ticketloope allows you to create and sell tickets for your
                      event and manage all of the data associated with your
                      events, such as ticket sales, visitor insights, reporting,
                      and much more. It's the perfect platform to help you
                      manage your events efficiently.
                    </p>
                  </div>
                </div>
                {/* <div class="col-lg-12">
                  <div
                    class="nav step-tabs custom-border-top pt-5"
                    role="tablist"
                  >
                    <button
                      class="step-link feature-step-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#step-01"
                      type="button"
                      role="tab"
                      aria-controls="step-01"
                      aria-selected="true"
                    >
                      Step 01<span>Event Pa</span>
                    </button>
                    <button
                      class="step-link feature-step-link"
                      data-bs-toggle="tab"
                      data-bs-target="#step-02"
                      type="button"
                      role="tab"
                      aria-controls="step-02"
                      aria-selected="false"
                    >
                      Step 02<span>Ticketing</span>
                    </button>
                    <button
                      class="step-link feature-step-link"
                      data-bs-toggle="tab"
                      data-bs-target="#step-03"
                      type="button"
                      role="tab"
                      aria-controls="step-03"
                      aria-selected="false"
                    >
                      Step 03<span>Attendees</span>
                    </button>
                    <button
                      class="step-link feature-step-link"
                      data-bs-toggle="tab"
                      data-bs-target="#step-04"
                      type="button"
                      role="tab"
                      aria-controls="step-04"
                      aria-selected="false"
                    >
                      Step 04<span>Promotion</span>
                    </button>
                    <button
                      class="step-link feature-step-link"
                      data-bs-toggle="tab"
                      data-bs-target="#step-05"
                      type="button"
                      role="tab"
                      aria-controls="step-05"
                      aria-selected="false"
                    >
                      Step 05<span>Management</span>
                    </button>
                  </div>
                  <div class="tab-content">
                    <div
                      class="tab-pane fade show active"
                      id="step-01"
                      role="tabpanel"
                    >
                      <div class="row justify-content-between">
                        <div class="col-lg-5">
                          <div class="row">
                            <div class="col-12">
                              <div class="feature-item">
                                <h4>Responsive Design</h4>
                                <p>
                                  Our responsive and user-friendly event page
                                  allows guests to book events from any device.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Public or Private Event</h4>
                                <p>
                                  You can host your events in public or by
                                  invitation only. Allow only the people whom
                                  you've invited to the events.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Booking end Countdown Timer</h4>
                                <p>
                                  Our Countdown timer can be a unique tool for
                                  selling your tickets. The timer shows the end
                                  time for ticket sale deadline, discount end
                                  period, and more.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Multi-session or Multi-Day Event</h4>
                                <p>
                                  You can launch multiple-session or
                                  multiple-day events according to your needs.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="feature-img">
                            <img src="images/about/feature-1.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="step-02" role="tabpanel">
                      <div class="row justify-content-between">
                        <div class="col-lg-5">
                          <div class="row">
                            <div class="col-12">
                              <div class="feature-item">
                                <h4>Single Ticket</h4>
                                <p>
                                  Sell standard, fixed price tickets to all of
                                  your attendees coming to the events. You have
                                  complete control of limiting ticket numbers.
                                  In addition, you can set up Unlimited types of
                                  tickets for your event.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Unlimited Ticket Types</h4>
                                <p>
                                  You can price your tickets in different types
                                  with the multi-price system. For example, you
                                  can sell discounted tickets, such as
                                  early-bird discounts, higher-priced VIP
                                  tickets, etc.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Group Ticketing</h4>
                                <p>
                                  There are unlimited ways of grouping tickets.
                                  You can create various types of tickets. You
                                  can set the limit for group ticket purchases
                                  from one person. Attendees can choose their
                                  seats as there are no seat restrictions on
                                  group tickets.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Set Your Rules</h4>
                                <p>
                                  Pass the service fee to the attendees or pay
                                  it yourself; set ticket cancellation and
                                  refund policies
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="feature-img">
                            <img src="images/about/feature-2.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="step-03" role="tabpanel">
                      <div class="row justify-content-between">
                        <div class="col-lg-5">
                          <div class="row">
                            <div class="col-12">
                              <div class="feature-item">
                                <h4>Fully Customisable Forms</h4>
                                <p>
                                  Our easy drag and drop feature lets you create
                                  attendees' forms as per your event
                                  requirements. Create a professional-looking
                                  form with a few simple steps.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Calendar Integration</h4>
                                <p>
                                  We've integrated popular online calendars
                                  (Outlook, Google, Yahoo, and Apple) where
                                  attendees can add events and get alerts when
                                  nearing the event date.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Attendee Dashboard</h4>
                                <p>
                                  The attendees' dashboard gives guests the
                                  option to cancel their registration should
                                  they no longer be able to attend. They can
                                  also check their registered events and
                                  suggestions.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Secure Payment Methods</h4>
                                <p>
                                  Ticketloope employs PCI-compliant payment
                                  gateways and has a comprehensive data
                                  protection policy to ensure that your earnings
                                  are safe. In addition, we provide flexible
                                  payment methods for a smooth payout
                                  experience.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="feature-img">
                            <img src="images/about/feature-3.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="step-04" role="tabpanel">
                      <div class="row justify-content-between">
                        <div class="col-lg-5">
                          <div class="row">
                            <div class="col-12">
                              <div class="feature-item">
                                <h4>Email Campaign</h4>
                                <p>
                                  You can design professional-looking emails
                                  from our built-in templates and send or
                                  schedule invitations, send reminders, and
                                  event updates to all of your contacts or
                                  pre-categorised attendees.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Analytics and Tracking</h4>
                                <p>
                                  Your events can be integrated into conversion
                                  tracking tools to monitor their social media
                                  promotion campaigns. Also, you can add your
                                  own Facebook Pixel, Google Analytics and
                                  Google Ads conversion codes.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Coupon</h4>
                                <p>
                                  You can use coupons to boost ticket sales.
                                  Multiple coupons can be created with
                                  additional offers. Create and promote coupons
                                  and drive more ticket sales.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Early Bird Discounts</h4>
                                <p>
                                  You can give a percentage or sum of the
                                  discounted ticket to selected people. You have
                                  the freedom to set up the discounts as you
                                  like
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="feature-img">
                            <img src="images/about/feature-4.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="step-05" role="tabpanel">
                      <div class="row justify-content-between">
                        <div class="col-lg-5">
                          <div class="row">
                            <div class="col-12">
                              <div class="feature-item">
                                <h4>Built-in Analytic Tools</h4>
                                <p>
                                  Ticketloope dashboard provides essential
                                  analytical information about the event and
                                  ticket sales. Track and make decisions
                                  accordingly to optimise your events to the
                                  fullest.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Team Collaboration</h4>
                                <p>
                                  Work together, from anywhere, with your team.
                                  Manage professionally your events together in
                                  real-time, all on one platform. Assign tasks
                                  to members from our organisation's dashboard.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Scanner App</h4>
                                <p>
                                  You can use the scanning app to check in
                                  guests, and only those with valid tickets can
                                  be let in. In addition, you can
                                  cross-reference scanned tickets with your
                                  guest list through the app.
                                </p>
                              </div>
                              <div class="feature-item">
                                <h4>Easy Duplicate Events</h4>
                                <p>
                                  Our duplicate event feature saves time and
                                  energy and creates the same events within
                                  seconds. You can still customise the new event
                                  as you want. You will receive separate
                                  analytical reports for each event.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="feature-img">
                            <img src="images/about/feature-5.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div class="host-engaging-event-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="main-title text-center">
                    <h3>The Team Behind Ticketloope </h3>
                    <p>
                      Every day we are passionately dedicated to make buying and
                      selling tickets simple and secure.
                    </p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="our-team custom-border-top">
                    <div class="row justify-content-xl-center">
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-1.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Rock William</h4>
                            <span>General Manager</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-2.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Brian Mills</h4>
                            <span>Vp Engineering</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-3.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Damian Murphy</h4>
                            <span>Business Development Manager</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-4.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Justine William</h4>
                            <span>Client Success Manager</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-5.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Lorraine Tan</h4>
                            <span>Product Manager</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-6.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Anneliese Hough</h4>
                            <span>Product Manager</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-7.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Johnson Dua</h4>
                            <span>Tech Lead</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                        <div class="main-card team-card">
                          <div class="team-img">
                            <img src="images/about/team-8.jpg" alt="" />
                          </div>
                          <div class="team-content">
                            <h4>Lizzy Wizzy</h4>
                            <span>Tech Lead</span>
                            <div class="user-social-links-group">
                              <a
                                href="https://www.facebook.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-facebook-square"></i>
                              </a>
                              <a
                                href="https://www.instagram.com/accounts/login/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-instagram"></i>
                              </a>
                              <a
                                href="https://twitter.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/"
                                class="user-social-link"
                                target="_blank"
                              >
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="our-organisations-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="main-title text-center">
                    <h3>
                      321+ events created by thousands of organisations around
                      the globe
                    </h3>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="organisations-area">
                    <div class="owl-carousel organisations-slider owl-theme">
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-1.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-2.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-3.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-4.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-5.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-6.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="sponsor">
                          <a href="#">
                            <img src="images/icons/sponsor-7.png" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Footer />
      </body>
    </>
  );
};

export default About;
