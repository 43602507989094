import Footer from "./Footer";
import Header from "./Header";
import Select from "react-select";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-select/dist/css/bootstrap-select.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "react-bootstrap-select/dist/react-bootstrap-select.min.js";
import { useAuth } from "../services/auth/useAuth.ts";
import { useNavigate } from "react-router";
import { AxiosContext } from "../services/axios/index.js";
import { environment } from "../services/environment.jsx";
import { useContext, useEffect, useState } from "react";

const ExploreEvent = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("OTHERS");
  const [visibleEvents, setVisibleEvents] = useState(8); // Number of initially visible events
  const [searchQuery, setSearchQuery] = useState("");
  const handleSeeMore = () => {
    // Increase the number of visible events when "See More" is clicked
    setVisibleEvents((prevVisibleEvents) => prevVisibleEvents + 4); // Adjust the increment as needed
  };

  const axios = useContext(AxiosContext);
  const options = [
    {
      value: "browse_all",
      label: "Browse All",
      icon: "fa-solid fa-tower-broadcast",
    },
    {
      value: "online_events",
      label: "Online Events",
      icon: "fa-solid fa-video",
    },
    {
      value: "venue_events",
      label: "Physical Events",
      icon: "fa-solid fa-location-dot",
    },
  ];

  const options2 = [
    { value: "01", label: "All" },
    { value: "02", label: "Arts" },
    { value: "03", label: "Business" },
    { value: "04", label: "Coaching and Consulting" },
    { value: "05", label: "Community and Culture" },
    { value: "06", label: "Education and Training" },
    { value: "07", label: "Family and Friends" },
    { value: "08", label: "Fashion and Beauty" },
    { value: "09", label: "Film and Entertainment" },
    { value: "10", label: "Food and Drink" },
    { value: "11", label: "Free" },
    { value: "12", label: "Health and Wellbeing" },
    { value: "13", label: "Hobbies and Interest" },
    { value: "14", label: "Music and Theater" },
    { value: "15", label: "Religion and Spirituality" },
    { value: "16", label: "Science and Technology" },
    { value: "17", label: "Sports and Fitness" },
    { value: "18", label: "Travel and Outdoor" },
    { value: "19", label: "Visual Arts" },
  ];



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${environment.backendURL}/event`);
        const eventData = response.data;
        setAllEvents(eventData);
        // Set loading status to false when data is fetched successfully
        console.log("all events", eventData);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching events:", error);
        // Set loading status to false on error
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  const filteredEvents = allEvents.filter((event) => {
    return (
      (selectedCategory === "OTHERS" || event.category === selectedCategory) &&
      event.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  
  return (
    <>
      <body class="d-flex flex-column h-100">
        <Header />

        <div class="wrapper">
          <div class="hero-banner">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-10">
                  <div class="hero-banner-content-about">
                    <h2 >Find Your Next Event</h2>
                 <br/>
                 <br/>
           
                      <div class="search-form main-form text-center">
  <div class="row g-3 justify-content-center">
    <div class="col-lg-8 col-md-12">
      <div class="form-group search-input">
        <div class="input-group">
          <input
            type="text"
            placeholder="Search by event name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            class="form-control"
            style={{ width: "70%", height: "60px"  }} // Adjust the width as needed
          />
          <span class="input-group-text">
            <i class="fa-solid fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

                        {/* <div class="col-lg-5 col-md-12">
                          <div class="form-group search-category">
                            <Select
                              options={options}
                              defaultValue={options[0]} // Set the default selected option
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: "100%",
                                }),
                              }}
                            />
                          </div>
                        </div> */}
                        {/* <div class="col-lg-5 col-md-12">
                          <div class="form-group">
                            <Select
                              options={options2}
                              defaultValue={options2[0]}
                              isSearchable={true}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: "100%",
                                }),
                              }}
                            />
                          </div>
                        </div> */}
                        {/* <div class="col-lg-2 col-md-12">
                          <a href="#" class="main-btn btn-hover w-100">
                            Find
                          </a>
                        </div> */}
                      </div>
                
                </div>
              </div>
            </div>
          </div>
          <div class="explore-events p-80">
            <div class="container">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="event-filter-items">
                    <div class="featured-controls">
                      {/* <div class="filter-tag">
                        <a href="#" class="active">
                          All
                        </a>
                        <a href="#">Today</a>
                        <a href="#">Tomorrow</a>
                        <a href="#">This Week</a>
                        <a href="#">This Weekend</a>
                        <a href="#">Next Week</a>
                        <a href="#">Next Weekend</a>
                        <a href="#">This Month</a>
                        <a href="#">Next Month</a>
                        <a href="#">This Year</a>
                        <a href="#">Next Year</a>
                      </div> */}
                     <div class="controls">
                        <button
                          onClick={() => setSelectedCategory("OTHERS")}
                          class={`control ${selectedCategory === "OTHERS" ? 'selected' : ''}`}
                          type="button"
                         
                          data-filter="others"
                        >
                          All
                        </button>
                        <button
                         onClick={() => setSelectedCategory("ARTS")}
                          type="button"
                          class={`control ${selectedCategory === "ARTS" ? 'selected' : ''}`}
                          data-filter=".arts"
                        >
                          Arts
                        </button>
                        <button
                         onClick={() => setSelectedCategory("SEMINAR")}
                          type="button"
                          class={`control ${selectedCategory === "SEMINAR" ? 'selected' : ''}`}
                          data-filter=".business"
                        >
                          Seminars
                        </button>
                        <button
                          onClick={() => setSelectedCategory("CONCERT")}
                          type="button"
                          class={`control ${selectedCategory === "CONCERT" ? 'selected' : ''}`}
                          data-filter=".concert"
                        >
                          Concert
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TRAINING_WORKSHOP")}
                          type="button"
                          class={`control ${selectedCategory === "TRAINING_WORKSHOP" ? 'selected' : ''}`}
                          data-filter=".workshops"
                        >
                          Workshops
                        </button>
                        <button
                         onClick={() => setSelectedCategory("FASHION")}
                          type="button"
                          class={`control ${selectedCategory === "FASHION" ? 'selected' : ''}`}
                          data-filter=".fashion"
                        >
                         Fashion
                        </button>
                        <button
                         onClick={() => setSelectedCategory("FESTIVALS")}
                          type="button"
                          class={`control ${selectedCategory === "FESTIVALS" ? 'selected' : ''}`}
                          data-filter=".festivals"
                        >
                      Festivals
                        </button>
                        <button
                         onClick={() => setSelectedCategory("FOODANDDRINKS")}
                          type="button"
                          class={`control ${selectedCategory === "FOODANDDRINKS" ? 'selected' : ''}`}
                          data-filter=".FOODNDRINKS"
                        >
                         Food and Drinks
                        </button>
                        <button
                         onClick={() => setSelectedCategory("WEBINARS")}
                          type="button"
                          class={`control ${selectedCategory === "WEBINARS" ? 'selected' : ''}`}
                          data-filter=".webinars"
                        >
                         Webinars
                        </button>
                        <button
                         onClick={() => setSelectedCategory("ONLINE_CLASS")}
                          type="button"
                          data-filter=".online"
                          class={`control ${selectedCategory === "ONLINE_CLASS" ? 'selected' : ''}`}
                        >
                         Online Class
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TALK_SHOW")}
                          type="button"
                          class={`control ${selectedCategory === "TALK_SHOW" ? 'selected' : ''}`}
                          data-filter=".talk"
                        >
                  Talk Show
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TECH")}
                          type="button"
                          class={`control ${selectedCategory === "TECH" ? 'selected' : ''}`}

                          data-filter=".tech"
                        >
                  Tech
                        </button>
                        <button
                         onClick={() => setSelectedCategory("SPORTSANDFITNESS")}
                          type="button"
                          class={`control ${selectedCategory === "SPORTSANDFITNESS" ? 'selected' : ''}`}
                          data-filter=".tech"
                        >
                 Sports and Fitness
                        </button>
                        <button
                         onClick={() => setSelectedCategory("TRAVELANDOUTDOOR")}
                          type="button"
                          class={`control ${selectedCategory === "TRAVELANDOUTDOOR" ? 'selected' : ''}`}
                          data-filter=".travel"
                        >
                 Travel and Outdoor
                        </button>
                        <button
                         onClick={() => setSelectedCategory("VISUALARTS")}
                          type="button"
                          class={`control ${selectedCategory === "VISUALARTS" ? 'selected' : ''}`}

                          data-filter=".visual"
                        >
                 Visual Arts
                        </button>
                      </div>
                      <div class="row" data-ref="event-filter-content">
                      {filteredEvents.slice(0, visibleEvents).map((event) => (
                          <div
                            class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                            data-ref="mixitup-target"
                          >
                            <div class="main-card mt-4">
                              <div class="event-thumbnail">
                                <a
                          href={`/venue-event/${event.slug}`}
                                  class="thumbnail-img"
                                >
                                  <img src={event.images[0]} alt="" />
                                </a>
                                <span
                                  class="bookmark-icon"
                                  title="Bookmark"
                                ></span>
                              </div>
                              <div class="event-content">
                                <a
                                href={`/venue-event/${event.slug}`}
                                  class="event-title"
                                >
                                  {event.name}
                                </a>
                                <p class="duration-description">{event.description}</p>
                                <div>
                                
                                  {/* <span class="remaining">6 slot</span> */}
                                </div>
                                <div class="duration-price-remaining">
                                  <span class="duration-price">{event.type === "VENUE" ? "Physical" : "Online"} Event </span>
                                  {/* <span class="remaining">6 slot</span> */}
                                </div>
                              </div>
                              <div class="event-footer">
                                <div class="event-timing">
                                  <div class="publish-date">
                                    <span>
                                      <i class="fa-solid fa-calendar-day me-2"></i>
                                      {event.date}
                                    </span>
                                    <span class="dot">
                                      <i class="fa-solid fa-circle"></i>
                                    </span>
                                    <span> {event.time}</span>
                                  </div>
                                  {/* <span class="publish-time">
               <i class="fa-solid fa-clock me-2"></i>1h
             </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                                                {
                          filteredEvents.length < 1 && (
                            <div class="main-card mt-4">
                            <div class="d-flex align-items-center justify-content-center flex-column min-height-430">
                              <div class="event-list-icon">
                                <img src="images/calendar.png" alt="" />
                              </div>
                              <p class="font-16 mt-4 text-light3">
                                No Event Found Under This Category
                              </p>
                            </div>
                          </div>
                          )
                        }
                      </div>
                      {visibleEvents < filteredEvents.length && (
        <div className="browse-btn">
          <button onClick={handleSeeMore} className="main-btn btn-hover">
            See More
          </button>
        </div>
      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </>
  );
};

export default ExploreEvent;
