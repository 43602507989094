import React, { useContext, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/parklogo.png";
import { useLocation, useNavigate } from "react-router";
import { PaystackButton } from "react-paystack";
import { environment } from "../services/environment";
import { AxiosContext } from "../services/axios";
const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const axios = useContext(AxiosContext)
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [fullname, setFullname] = useState("");
  const [tickid, setTickID] = useState([]);
  const { state } = location;
  const {events, tickets, ticketQuantities, totalTickets, totalCost } = state || {};
  const publicKey = "pk_live_c28db74aadafee818d9f89c0b2e36ef6462fb31f";
  console.log("state", state, ticketQuantities);

console.log("tickid", tickid);
  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: parseInt(totalCost) * 100, //convert to kobo for paystack...
    publicKey: publicKey,
  };



const freePayment = (e) => {
e.preventDefault();
  try {
    if (axios) {
      const selectedTickets = tickets.map((ticket, index) => ({
        ticketId: ticket._id, // Assuming your ticket object has an 'id' property
        quantity: ticketQuantities[index] || 0,
        ticket: ticket,
      }))
      .filter(ticket => ticket.quantity > 0);

    // Construct an array of ticket IDs to pass to the endpoint
    const ticketIds = selectedTickets.map(ticket => ticket.ticketId);
    const ticketse = selectedTickets.map(ticket => ticket.ticket);
    setTickID(ticketIds);
console.log("ticketids", ticketIds);
console.log("tickets", ticketse);

      axios
        .post(`${environment.backendURL}/payment`,{
          name: fullname,
          address: address,
          amount: parseInt(totalCost),
          ticketId: ticketIds,
          phone: phone,
          gateway: "PAYSTACK",
          email: email,
          ref:  "ticket" + Math.floor(Math.random() * 1000000000 + 1).toString(),
        })
        .then((response) => {
          // Handle success
          console.log("Payment successful:", response.data);
          const bookingDetails = response.data

          // Navigate to the "/booking-confirmed" route with state
          navigate('/booking-confirmed', { state: { bookingDetails,ticketse,events } });
     
        });
    }
  } catch (error) {
    console.log(error);
  } finally {
    // setShowToast1(true);
  }
}


  const handlePaystackSuccessAction = (reference, response) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log("my", reference?.status);
    console.log(reference);
    if (reference?.status === "success") {
      console.log("that", reference); //  get reference here

      try {
        if (axios) {
          const selectedTickets = tickets.map((ticket, index) => ({
            ticketId: ticket._id, // Assuming your ticket object has an 'id' property
            quantity: ticketQuantities[index] || 0,
            ticket: ticket,
          }))
          .filter(ticket => ticket.quantity > 0);
          const deduction = totalCost * 0.04;
          const adjustedAmount = totalCost - deduction;
        // Construct an array of ticket IDs to pass to the endpoint
        const ticketIds = selectedTickets.map(ticket => ticket.ticketId);
        const ticketse = selectedTickets.map(ticket => ticket.ticket);
        setTickID(ticketIds);
console.log("ticketids", ticketIds);
console.log("tickets", ticketse);
console.log("adjusted", adjustedAmount);

          axios
            .post(`${environment.backendURL}/payment`,{
              name: fullname,
              address: address,
              amount: parseInt(adjustedAmount),
              ticketId: ticketIds,
              phone: phone,
              gateway: "PAYSTACK",
              email: email,
              ref:  "ticket" + Math.floor(Math.random() * 1000000000 + 1).toString(),
            })
            .then((response) => {
              // Handle success
              console.log("Payment successful:", response.data);
              console.log("responded:", response);
              const bookingDetails = response.data
    
              // Navigate to the "/booking-confirmed" route with state
              navigate('/booking-confirmed', { state: { bookingDetails,ticketse,events } });
         
            });
        }
      } catch (error) {
        console.log("error",error.response?.data?.message);
  

      } 
      // setShowToast1(true);
    }
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Confirm & Pay",
    onSuccess: (reference, response) =>
      handlePaystackSuccessAction(reference, response),
    onClose: handlePaystackCloseAction,
  };


  return (
    <>
      <body class="d-flex flex-column h-100">
        <Header />

        <div class="wrapper">
          <div class="breadcrumb-block">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-10">
                  <div class="barren-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">
                          Checkout
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-dt-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="main-title checkout-title">
                    <h3>Order Confirmation</h3>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12">
                  <div class="checkout-block">
                    <div class="main-card">
                      <div class="bp-title">
                        <h4>Billing information</h4>
                      </div>
                      <div class="bp-content bp-form">
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">FullName*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                onChange={(e) => setFullname(e.target.value)}
                                placeholder="John Doe"
                                value={fullname}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">Address*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Address"
                                value={address}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">Email*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="johndoe@example.com"
                                value={email}
                           
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">Phone Number</label>
                              <input
                                class="form-control h_50"
                                type="tel"
                                  onChange={(e) => setPhone(e.target.value)}
                                placeholder="telephone"
                                value={phone}
                              />
                            </div>
                          </div>
                          {/* <div class="col-lg-6 col-md-12">
                            <div class="form-group main-form mt-4">
                              <label class="form-label">Country*</label>
                              <select
                                class="selectpicker"
                                data-size="5"
                                title="Nothing selected"
                                data-live-search="true"
                              >
                                <option value="Algeria">Algeria</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">
                                  Austria (Österreich)
                                </option>
                                <option value="Belgium">
                                  Belgium (België)
                                </option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Brazil">Brazil</option>
                                <option value="Canada">Canada</option>
                                <option value="Chile">Chile</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">
                                  Czech Republic
                                </option>
                                <option value="Denmark">Denmark</option>
                                <option value="Dominican Republic">
                                  Dominican Republic
                                </option>
                                <option value="Estonia">Estonia</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="Germany">Germany</option>
                                <option value="Greece">Greece</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Japan">Japan</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Norway">Norway</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="South Africa">
                                  South Africa
                                </option>
                                <option value="South Korea">South Korea</option>
                                <option value="Spain">Spain</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Turkey">Turkey</option>
                                <option value="United Kingdom">
                                  United Kingdom
                                </option>
                                <option value="United States">
                                  United States
                                </option>
                                <option value="Vietnam">Vietnam</option>
                              </select>
                            </div>
                          </div> */}
                          {/* <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">State*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                placeholder=""
                                value=""
                              />
                            </div>
                          </div> */}
                          {/* <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">City/Suburb*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                placeholder=""
                                value=""
                              />
                            </div>
                          </div> */}
                          {/* <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">Zip/Post Code*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                placeholder=""
                                value=""
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div class="main-card mt-5">
                      <div class="bp-title">
                        <h4>Total Payable Amount :  {totalCost}</h4>
                      </div>
                      <div class="bp-content bp-form">
                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">Card number*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                placeholder=""
                                value=""
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">Expiry date*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                placeholder="MM/YY"
                                value=""
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="form-group mt-4">
                              <label class="form-label">CVV*</label>
                              <input
                                class="form-control h_50"
                                type="text"
                                placeholder=""
                                value=""
                              />
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12">
                            <a
                              class="main-btn btn-hover h_50 w-100 mt-5"
                              type="button"
                              href="/booking-confirmed"
                            >
                              Confirm & Pay
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="main-card order-summary">
                    <div class="bp-title">
                      <h4>Billing information</h4>
                    </div>
                    <div class="order-summary-content p_30">
                    {tickets && tickets.length > 0 &&
    tickets
      .filter((ticket, index) => ticketQuantities[index] > 0)
      .map((ticket, index) => (
        <div key={index} className="event-order-dt">
          {/* <div className="event-thumbnail-img">
            <img src={ticket.image} alt={ticket.name} />
          </div> */}
          <div className="event-order-dt-content">
            <h5>{ticket.name}</h5>
            <span>{ticket.date}</span>
            <div className="category-type">{ticket.category}</div>
          </div>
        </div>
      ))
  }
                      <div class="order-total-block">
                        <div class="order-total-dt">
                          <div class="order-text">Total Ticket</div>
                          <div class="order-number">{totalTickets}</div>
                        </div>
                        <div class="order-total-dt">
                          <div class="order-text">Sub Total</div>
                          <div class="order-number">{totalCost}</div>
                        </div>
                        <div class="divider-line"></div>
                        <div class="order-total-dt">
                          <div class="order-text">Total</div>
                          <div class="order-number ttl-clr"> {totalCost}</div>
                        </div>
                      </div>
                      <div class="coupon-code-block">
                        <div class="form-group mt-4">
                          <label class="form-label">Coupon Code*</label>
                          <div class="position-relative">
                            <input
                              class="form-control h_50"
                              type="text"
                              placeholder="Code"
                              value=""
                            />
                            <button class="apply-btn btn-hover" type="button">
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="confirmation-btn">


                        {/* <a
                          class="main-btn btn-hover h_50 w-100 mt-5"
                          type="button"
                          href="/booking-confirmed"
                        >
                          Confirm & Pay
                        </a> */}
                             <div class="confirmation-btn">
                              {
                                totalCost === "0.00" ?  <a
onClick={freePayment}
                                class="main-btn btn-hover h_50 w-100 mt-5"
                                type="button"
                           
                              >
                                Confirm
                              </a> :      <PaystackButton   className="main-btn btn-hover h_50 w-100 mt-5" {...componentProps} />
                              }
                
                      </div>
                        <span>Price is inclusive of all applicable GST</span>
                      </div>
                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </>
  );
};

export default Checkout;
