import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/parklogo.png";
import { useParams } from "react-router";
import { AxiosContext } from "../services/axios";
import { environment } from "../services/environment";



const Terms = () => {


    return (
        <>

<body class="d-flex flex-column h-100">

<Header />
<div class="wrapper">
    <div class="breadcrumb-block">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-10">
                    <div class="barren-breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="https://www.gambolthemes.net/html-items/barren-html/disable-demo-link/index.html">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Terms &amp; Conditions</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="event-dt-block p-80">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="main-card">
                        <div class="bp-title">
                            <h4>Terms &amp; Conditions</h4>
                        </div>
                        <div class="bp-content">
                            <div class="group-terms-tabs">
                                <div class="nav terms-tabs p-2 border-bottom-0 mt-4" role="tablist">
                                    {/* <a class="term-link active" id="sellers-tab" data-bs-toggle="tab" href="https://www.gambolthemes.net/html-items/barren-html/disable-demo-link/term_and_conditions.html#sellers" role="tab" aria-controls="sellers" aria-selected="true">for Ticket Sellers</a> */}
                                    <a class="term-link active" id="buyer-tab" data-bs-toggle="tab" href="https://www.gambolthemes.net/html-items/barren-html/disable-demo-link/term_and_conditions.html#buyer" role="tab" aria-controls="buyer" aria-selected="true" >For Ticket Buyers</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">							
                    <div class="tab-content pt-0 p-2 mt-5 mt-lg-0">
                        <div class="tab-pane fade " id="sellers" role="tabpanel" aria-labelledby="sellers-tab">
                            <div class="terms-main-title">
                                <h4>Terms &amp; Conditions for Ticket Sellers</h4>
                            </div>
                            <div class="terms-content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis molestie purus lorem, eget facilisis augue cursus eget. Aliquam posuere vehicula dignissim. Vivamus vitae odio consectetur, pharetra tortor ac, interdum urna. Cras leo mi, tincidunt non ultrices in, finibus ac dolor. Mauris tincidunt non metus id iaculis.</p>
                                <ol>
                                    <li>Who we are and what we do</li>
                                </ol>
                                <ul>
                                    <li>
                                        Barren offers the following services to event organisers (“services”):
                                        <ul>
                                            <li>Nam consectetur felis a risus ultrices sollicitudin.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                            <li>In pulvinar ipsum a nisi venenatis aliquet at ullamcorper erat.</li>
                                            <li>Praesent elementum mi in finibus pellentesque.</li>
                                        </ul>										
                                    </li>
                                </ul>
                                <ol start="2">
                                    <li>Your use of our Services</li>
                                </ol>
                                <p>Mauris id ligula et est lacinia malesuada. Quisque leo purus, porttitor efficitur aliquet ac, dictum vitae eros. Pellentesque at nisl lorem. Aliquam pretium diam ullamcorper ullamcorper suscipit. In consectetur scelerisque odio sit amet sagittis. Nunc viverra justo vel ante placerat pharetra. Quisque eget est et tortor molestie iaculis. Proin a quam urna. Phasellus nec orci lacinia, egestas massa sed, pretium justo. Aliquam mauris arcu, rutrum vitae tortor ut, tincidunt scelerisque nisi. Suspendisse aliquam est nec malesuada rhoncus.</p>							
                                <ol start="3">
                                    <li>Creating an account</li>
                                </ol>
                                <p>Phasellus faucibus eros nec quam rutrum congue. Quisque lobortis porttitor turpis, in volutpat libero accumsan eu. Sed feugiat massa sed venenatis ullamcorper. Integer sed volutpat dolor, aliquam bibendum leo. In sed eros scelerisque, tempus augue a, viverra turpis. Nam pharetra ipsum velit, sollicitudin dapibus nulla cursus ut. Nulla facilisi. Ut lacinia dui non nunc placerat, vitae tristique dui luctus. Quisque eu lorem euismod, tristique mi in, porttitor risus. Nam ut nisi quis nisi vehicula scelerisque. Vivamus vestibulum nibh cursus, commodo sem sit amet, mattis odio.</p>
                                <ol start="4">
                                    <li>Content of the event page</li>
                                </ol>
                                <p>Morbi non facilisis ipsum. Mauris volutpat sagittis purus, nec mattis dui auctor quis. Maecenas interdum ex eget mauris ullamcorper, quis aliquet risus faucibus. Suspendisse posuere accumsan mi, ut accumsan elit. Duis aliquam a neque eu congue. Donec finibus felis vitae dui condimentum, sit amet placerat leo dapibus. In hac habitasse platea dictumst. Nunc tristique dolor vitae ipsum condimentum euismod. Morbi scelerisque ornare tortor in sodales. Duis ligula ex, tristique quis ultrices et, faucibus non metus.</p>
                                <ol start="5">
                                    <li>Ownership of content</li>
                                </ol>
                                <p>Integer sed volutpat dolor, aliquam bibendum leo. In sed eros scelerisque, tempus augue a, viverra turpis. Nam pharetra ipsum velit, sollicitudin dapibus nulla cursus ut. Nulla facilisi. Ut lacinia dui non nunc placerat, vitae tristique dui luctus. Quisque eu lorem euismod, tristique mi in, porttitor risus. Nam ut nisi quis nisi vehicula scelerisque. Vivamus vestibulum nibh cursus, commodo sem sit amet, mattis odio.</p>
                                <ol start="6">
                                    <li>Payment</li>
                                </ol>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut ex magna. Vivamus finibus faucibus luctus. Fusce eleifend turpis in tortor mattis tincidunt. Mauris sed eros blandit ipsum imperdiet egestas. Donec sit amet arcu at urna porttitor ullamcorper in eget mi. Cras id nisl ac elit tristique pretium. Mauris augue magna, consequat nec pharetra sed, cursus eu mauris.</p>
                                <ol start="7">
                                    <li>Cancelled, varied or postponed events</li>
                                </ol>
                                <p>Pellentesque at nisl lorem. Aliquam pretium diam ullamcorper ullamcorper suscipit. In consectetur scelerisque odio sit amet sagittis. Nunc viverra justo vel ante placerat pharetra. Quisque eget est et tortor molestie iaculis. Proin a quam urna. Phasellus nec orci lacinia, egestas massa sed, pretium justo. Aliquam mauris arcu, rutrum vitae tortor ut, tincidunt scelerisque nisi. Suspendisse aliquam est nec malesuada rhoncus.</p>
                                <ol start="8">
                                    <li>Exchanges and refunds</li>
                                </ol>
                                <p>In hac habitasse platea dictumst. Sed ultrices mauris quis condimentum dapibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce fermentum quis mi in vestibulum. Nullam volutpat nisl sed nibh ultricies, et lobortis mauris tristique. Cras aliquam nisi et turpis consectetur condimentum. Pellentesque sed ullamcorper sapien. Mauris nec augue eget ligula viverra aliquam. Ut a pellentesque metus. Integer ut elementum lorem. Sed bibendum fringilla orci, sed accumsan mauris egestas sed. Suspendisse elit sapien, cursus vitae congue at, hendrerit tempor nunc. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam lacinia facilisis ligula, blandit gravida elit sodales sit amet. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur volutpat imperdiet malesuada.</p>
                                <ol start="9">
                                    <li>Unauthorised use of the Site</li>
                                </ol>
                                <p>Quisque eget est et tortor molestie iaculis. Proin a quam urna. Phasellus nec orci lacinia, egestas massa sed, pretium justo. Aliquam mauris arcu, rutrum vitae tortor ut, tincidunt scelerisque nisi. Suspendisse aliquam est nec malesuada rhoncus. Duis ligula ex, tristique quis ultrices et, faucibus non metus. Aliquam auctor in erat at tempus. Mauris finibus vitae justo et auctor. Integer dignissim erat lectus, quis porta tellus pretium ac. Ut in nunc ex.</p>
                                <ol start="10">
                                    <li>Termination of Account</li>
                                </ol>
                                <p>hendrerit tempor nunc. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam lacinia facilisis ligula, blandit gravida elit sodales sit amet. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur volutpat imperdiet malesuada.</p>
                                <ol start="11">
                                    <li>Consequences of a breach</li>
                                </ol>
                                <p>In sed eros scelerisque, tempus augue a, viverra turpis. Nam pharetra ipsum velit, sollicitudin dapibus nulla cursus ut. Nulla facilisi. Ut lacinia dui non nunc placerat, vitae tristique dui luctus. Quisque eu lorem euismod, tristique mi in, porttitor risus. Nam ut nisi quis nisi vehicula scelerisque. Vivamus vestibulum nibh cursus, commodo sem sit amet, mattis odio.</p>
                                <ol start="12">
                                    <li>Disclaimer and Limitation of Liability</li>
                                </ol>
                                <p>Morbi non facilisis ipsum. Mauris volutpat sagittis purus, nec mattis dui auctor quis. Maecenas interdum ex eget mauris ullamcorper, quis aliquet risus faucibus. Suspendisse posuere accumsan mi, ut accumsan elit. Duis aliquam a neque eu congue. Donec finibus felis vitae dui condimentum, sit amet placerat leo dapibus.</p>
                                <ol start="13">
                                    <li>Indemnity</li>
                                </ol>
                                <p>Nulla facilisi. Ut lacinia dui non nunc placerat, vitae tristique dui luctus. Quisque eu lorem euismod, tristique mi in, porttitor risus. Nam ut nisi quis nisi vehicula scelerisque. Vivamus vestibulum nibh cursus, commodo sem sit amet, mattis odio.</p>
                                <ol start="14">
                                    <li>General Provisions</li>
                                </ol>
                                <p>Quisque eget est et tortor molestie iaculis. Proin a quam urna. Phasellus nec orci lacinia, egestas massa sed, pretium justo. Aliquam mauris arcu, rutrum vitae tortor ut, tincidunt scelerisque nisi. Suspendisse aliquam est nec malesuada rhoncus.</p>
                            </div>
                        </div>
                        <div class="tab-pane fade active show" id="buyer" role="tabpanel" aria-labelledby="buyer-tab">
                            <div class="terms-main-title">
                                <h4>Terms &amp; Conditions for Ticket Buyers</h4>
                            </div>
                            <div class="terms-content">
                                <p>Ticketloope provides ticketing services for events, facilitating transactions as an intermediary third party between ticket purchasers and event producers.
.</p>
                                <ol>
                                    <li>Pricing; Fees and Availability:</li>
                                </ol>
                                <ul>
                                    <li>
                                    Tickets may be subject to non-refundable service fees, processing charges and delivery fees. Ticketloope sells tickets on behalf of event producers who set and control prices, offers, availability, and seating arrangements, which may change at any time.
                                       									
                                    </li>
                                </ul>
                                <ol start="2">
                                    <li>Ticket Policies and Limits:</li>
                                </ol>
                                <ul>
                                    <li>
                                    All sales through Ticketloope are final
                                    </li>
                                    <li>
                                    Ticketloope implements ticket limits to prevent unfair buying practices and all attempts 
                                    </li>
                                    <li>
                                    To circumvent ticket limits may result in order cancellation.</li>
                                </ul>
                              <ol start="3">
                                    <li>Disclaimer and Limitations of Liability</li>
                                </ol>
                                <p>Event related issues are solely the responsibility of event producers. Force majeure events may release Ticketloope from obligations.</p>
                                <ol start="4">
                                    <li>Privacy Policy and Legal Governance/Venue: </li>
                                </ol>
                                <ul>
                                    <li>
                                    All information provided by purchasers is subject to Ticketloope's Privacy Policy. 
                                    </li>
                                    <li>
                                    Purchasers must indemnify and hold harmless Ticketloope in all legal actions. 
 </li>
                                    <li>
                                    Disputes are governed by . the Laws of the Federal Republic of Nigeria regardless where the event(s) have taken place.
</li>
                                </ul>
                                <div class="terms-main-title">
                                <h4>PURCHASER TERMS, CONDITIONS AND POLICIES</h4>
                            </div>
                             
                                <p>(“Ticketloope,” “we” or “us”) is in the business of providing reservations, tickets, memberships, certificates, admissions, and/or confirmations (“Services”) that allow ticket holders or purchasers (each, a "Patron") attendance at, access to, or participation in, events, venues and other activities produced by third party persons, organizations or other entities (“Event Producer”).</p>
                            
                                <p>Any purchases on Ticketloope's website or mobile application (“Site”) are subject to these terms and conditions (“Terms”). </p>
                                <p>Ticketloope serves as an intermediary between you (“you” or “Purchaser”) and Event Producer(s) for the purchase of an event ticket. Ticketloope is in no way a party to any agreement made between you and the Event Producer formed through the purchase of a ticket.</p>
                             <p>Tickets purchased on Ticketloope’s Site are typically subject to a non-refundable per ticket service fee and a non-refundable per order processing fee. </p>
                              <p>Event Producers may request your contact information including your phone number (at the point of contact or subsequent to a purchase or event registration) for promotions, and/or event updates. Should you choose to opt-in, you acknowledge that you are receiving SMS from the Event Producer(s) as a third party, and therefore you agree to indemnify and hold harmless Ticketloope for any issues stemming from such third party communications. </p>
                              <p>Please see our Privacy Policy for more information or contact us if you are having an issue with an Event Producer regarding communication issues. Please be advised that opting out of communications from Event Producer(s) does not include opting out from ticketloope communications.</p>
                         
                              <div class="terms-main-title">
                                <h4>PRICING, AVAILABILITY, AND SEATING.</h4>
                            </div>

                                <ul start="8">
                                    <li>Ticketloope acts as a ticket seller on behalf of Event Producers, and doesn't set ticket prices, determine seating locations, or control ticket availability.</li>
                                </ul>
                                <ul start="8">
                                    <li>	Event ticket prices are set by the Event Producer, and may be subject to separate venue fees or additional Ticketloope fees for certain events.
</li>
                                </ul>
                                <ul start="8">
                                    <li>	All offers and prices are subject to change at any time at the discretion of the Event Producer or Ticketloope
</li>
                                </ul>
                                <ul start="8">
                                    <li>	Ticketloope is not responsible for ticket over-sales, seating information, or customer dissatisfaction with selected seating. Any disputes are to be addressed strictly between the purchaser and Event Producer/venue, with the explicit exclusion of Ticketloope Service charges are not to be refunded due to customer seating dissatisfaction.
</li>
                                </ul>
                                <ul start="8">
                                    <li>	Seating charts may be provided for general reference, but specific seat locations, layouts and views may vary or be modified without notice for some events.
</li>
                                </ul>
                                <h4>CANCELED/RESCHEDULED EVENTS</h4>
  <ul>
    <li>
      <h4>Event Producers, venues, or others outside the control of Ticketloope may cancel, reschedule or re-locate events.</h4>
      <p>
        While Ticketloope may undertake efforts to inform you of a cancellation after it has received information from the Event Producer, it cannot guarantee that you will be informed of the cancellation prior to the event. It is your responsibility to check whether an event has been canceled or rescheduled and to ascertain the new time or location. Should a cancellation, rescheduling or relocation occur, it is at the discretion of the Event Producer whether to refund the ticket price or exchange the tickets for a similarly equivalent event.
      </p>
    </li>
    <li>
      <h4>If an event is rescheduled or relocated</h4>
      <p>
        The Event Producer may set their own refund and/or exchange limitations. Ticketloope will provide instructions on the Site or via email regarding how to exchange specific event tickets or obtain ticket price refunds. Service charges will not be refunded in the event of a canceled or rescheduled event.
      </p>
    </li>
  </ul>

  <h4>REFUNDS AND EXCHANGES</h4>
  <ul>
    <li>
      <h4>ALL SALES ARE FINAL</h4>
      <p>
        Before purchasing tickets, carefully review your event details and seat selection. Ticketloope will not refund or exchange tickets, unless the applicable event is canceled or rescheduled as described herein. Event tickets will not be replaced or refunded, including tickets that were lost, stolen, delayed, damaged, or destroyed during delivery to ticket Purchaser.
      </p>
    </li>
    <li>
      <h4>All exchanges or refunds from canceled/postponed events</h4>
      <p>
        May only be paid back via the original purchase method. If an Event Producer provides us with notice of an event cancellation, Ticketloope will use reasonable efforts to provide timely notice of such cancellation to all individuals who have purchased tickets to such canceled event(s). Ticketloope shall notify Purchasers about the specific timeframe within which the refund process will be initiated and no further action shall be required of the Purchaser.
      </p>
    </li>
    <li>
      <h4>If an Event Producer provides us with notice of an event rescheduling or re-location</h4>
      <p>
        The initiation of the refund process shall not be automatically applicable and the tickets will remain valid for the rescheduled and/or relocated event date(s). If the Purchaser cannot attend the event on the rescheduled date or at the new location, it is their sole responsibility to request a refund through a formal refund request. The refund request must be submitted by the original Purchaser in a timely fashion in accordance with the notice provisions in order to initiate the refund process.
      </p>
    </li>
    <li>
      <h4>Refund prohibitions</h4>
      <p>
        You agree that you will not attempt to evade, avoid, or circumvent any refund prohibitions in any manner with regard to tickets you purchased. Without limiting the generality of the foregoing, you agree not to dispute or seek a "chargeback" from the banking institution or credit card company used to purchase tickets from Ticketloope’s Site. If you do so, your tickets may be canceled. Ticketloope, at its sole discretion, may refuse to honor pending and future ticket purchases made from accounts associated with such chargebacks. Additionally, Ticketloope may prohibit individuals with linked credit card accounts and online accounts, as well as anyone violating this provision, from accessing Ticketloope’s Site.
      </p>
    </li>
  </ul>

  <h4>ORDER CANCELLATION</h4>
  <p>
    Notwithstanding anything to the contrary contained herein, Ticketloope reserves the right to cancel ticket orders for any reason including, but not limited to, incorrect billing information and fraudulent ticket purchasing.
  </p>

  <h4>DISCLAIMER OF WARRANTIES; ACCESS</h4>
  <p>
    You hereby understand and agree that Ticketloope’s ticketing Services are provided on an “as is” and “as available” basis, and Ticketloope expressly disclaims all warranties of any kind, express or implied, including, but not limited to, implied warranties of merchantability, title, non-infringement and fitness for a particular purpose. Ticketloope makes no warranty that (i) the Services provided hereunder (or any portion of those Services) will meet your requirements or expectations; (ii) Ticketloope’s Services will be uninterrupted, timely, secure, or error-free; or (iii) the results that may be obtained from the use of Ticketloope’s Services will be accurate or reliable.
  </p>

  <p>
    You hereby understand and agree that Ticketloope’s Site may, at times, be inaccessible or inoperable for any reason, including, but not limited to: (i) equipment or communications malfunctions; (ii) periodic maintenance, repairs, or administrative reviews which Ticketloope may undertake from time-to-time; or (iii) causes beyond Ticketloope’s reasonable control or which are not reasonably foreseeable, such as acts of government or the malicious or criminal acts of third parties.
  </p>

  <h4>LIMITATION OF LIABILITY</h4>
  <p>
    You understand and agree that Ticketloope is in no way responsible or liable for the event(s) for which you may purchase tickets, with such responsibility and liability being solely with the Event Producer. You hereby release and hold harmless Ticketloope and all its respective agents, affiliates, associates, officers, members, managers, contractors, and employees (collectively, “Released Parties”) from any and all claims, demands, losses, damages, liabilities, suits, actions, and expenses (including, without limitation, reasonable attorneys’ fees) on account of any dispute, bodily injury, death, or property or personal damage arising out of or in connection with the applicable event.
  </p>

  <p>
    Ticketloope will only be liable by reason of an attributable failure to provide a viable ticket if the customer has promptly given Ticketloope notice of default in writing and Ticketloope has not remedied such breach within a reasonable amount of time.
  </p>

  <h4>FORCE MAJEURE</h4>
  <p>
    Ticketloope will not be liable for failure to perform any obligation under these Terms if such failure is caused by internet outages or delays, unauthorized access, earthquakes, communications outages, fire, flood, war, an Act of God, or the occurrence of any other unforeseen contingency beyond the reasonable control of Ticketloope.
  </p>

  <h4>PRIVACY</h4>
  <p>
    The information Ticketloope may obtain through your use of our Services and through your use of Ticketloope’s Site is subject to Ticketloope’s Privacy Policy which is specifically incorporated by reference into these Terms.
  </p>

  <h4>INDEMNIFICATION</h4>
  <p>
    YOU AGREE TO DEFEND, INDEMNIFY AND HOLD TICKETLOOPE AND ITS OFFICERS, DIRECTORS, AGENTS, LICENSORS, PAYMENT PROCESSING PARTNERS, OTHER PARTNERS AND EMPLOYEES, HARMLESS FROM ANY AND ALL DAMAGE (WHETHER DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR OTHERWISE), LOSS, LIABILITY, COST AND EXPENSE (INCLUDING, WITHOUT LIMITATION, REASONABLE ATTORNEYS' AND ACCOUNTING FEES) RESULTING FROM ANY CLAIM, DEMAND, SUIT, PROCEEDING (WHETHER BEFORE AN ARBITRATOR, COURT, MEDIATOR OR OTHERWISE) OR INVESTIGATION MADE BY ANY THIRD PARTY (EACH A "CLAIM") RELATING TO OR ARISING OUT OF: (A) YOUR BREACH OF THESE TERMS; (B) YOUR USE OF TICKETLOOPE’S SERVICES IN VIOLATION OF THESE TERMS OR OTHER POLICIES WE POST OR MAKE AVAILABLE; (C) YOUR BREACH OF ANY APPLICABLE LOCAL, STATE, PROVINCIAL, NATIONAL OR OTHER LAW, RULE OR REGULATION OR THE RIGHTS OF ANY THIRD PARTY; (D) TICKETLOOPE’S APPORTIONMENT, COLLECTION AND REMISSION OF TAXES; AND (E) ANY APPLICABLE TICKETED EVENTS, PROVIDED THAT IN THE CASE OF (E) THIS INDEMNIFICATION WILL NOT APPLY TO THE EXTENT THAT THE CLAIM ARISES OUT OF TICKETLOOPE'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
  </p>

  <h4>DISPUTES</h4>
  <p>
    These Terms shall be governed by the laws of the Federal Republic of Nigeria and any controversy or claim arising out of or in relation to these Terms, including the validity, construction or performance of these Terms, shall be submitted exclusively to the jurisdiction of the Lagos state courts. With respect to any breach which is capable of cure, Ticketloope shall not be considered in breach of these Terms unless Ticketloope fails to cure any breach within thirty (30) days of receipt of written notice of the breach. A waiver of any breach shall not waive a prior or subsequent breach.
  </p>

  <h4>MISCELLANEOUS</h4>
  <p>
    These Terms contain the entire understanding between you and Ticketloope regarding the subject matter hereof, and all prior agreements, terms and conditions, privacy policies, email or Discord correspondence as to such subject matter have all collectively been merged herein, with the current Privacy Policy and other terms of use contained on the Site hereby incorporated by reference. By using the Site, the application or any of ticketloope
  </p>
  <p>
    Purchaser hereby acknowledges that they have had the opportunity to consult with counsel regarding these Terms or has expressly waived such right upon proceeding with using any of the Services. If any provision of these Terms is invalid, void or unenforceable, the remainder of these Terms shall remain in full force and effect.
  </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<Footer />



</body>
   
   
        </>
 
    
    )
}


export default Terms;