import React, { useEffect, useState } from "react";
import { useAuth } from "../services/auth/useAuth.ts";

const Footer = () => {
  const { isLoggedIn } = useAuth();
  const [loggedIn, setLoggedIn] = useState(true);
  useEffect(() => {
    const checkLogin = async () => {
      const check = await isLoggedIn();
      setLoggedIn(check);
    };
    checkLogin();

  }, []);
  return (
    <>
      <footer class="footer mt-auto">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="footer-content">
                  <h4>Company</h4>
                  <ul class="footer-link-list">
                    <li>
                      <a href="/about" class="footer-link">
                        About Us
                      </a>
                    </li>
                    {/* <li>
                      <a href="help_center.html" class="footer-link">
                        Help Center
                      </a>
                    </li> */}
                    <li>
                      <a href="/faq" class="footer-link">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a href="/contact" class="footer-link">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="footer-content">
                  <h4>Useful Links</h4>
                  <ul class="footer-link-list">
                    <li>
                      <a  href={loggedIn ? "/create-event" : "/login"} class="footer-link">
                        Create Event
                      </a>
                    </li>
                    {/* <li>
                      <a href="/creat" class="footer-link">
                        Sell Tickets Online
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="#" class="footer-link">
                        Privacy Policy
                      </a>
                    </li> */}
                    <li>
                      <a href="/terms-and-conditions" class="footer-link">
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="footer-content">
                  <h4>Resources</h4>
                  <ul class="footer-link-list">
                    {/* <li>
                      <a href="pricing.html" class="footer-link">
                        Pricing
                      </a>
                    </li> */}
                    <li>
                      <a href="/blog" class="footer-link">
                        Blog
                      </a>
                    </li>
                    {/* <li>
                      <a href="refer_a_friend.html" class="footer-link">
                        Refer a Friend
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="footer-content">
                  <h4>Follow Us</h4>
                  <ul class="social-links">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61554966682337&mibextid=ZbWKwL" class="social-link">
                        <i class="fab fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/ticketloope?igsh=YzAwZjE1ZTI0Zg==" class="social-link">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/ticketloope?t=M9skdtPbokSgpbD6nJG9ww&s=09" class="social-link">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/ticketloope/" class="social-link">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
         
                  </ul>
                </div>
                <div class="footer-content">
                  {/* <h4>Download Mobile App</h4>
                  <div class="download-app-link">
                    <a href="#" class="download-btn">
                      <img src="images/app-store.png" alt="" />
                    </a>
                    <a href="#" class="download-btn">
                      <img src="images/google-play.png" alt="" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="footer-copyright-text">
                  <p class="mb-0">
                    © 2024, <strong>Ticketloope</strong>. All rights reserved.
                    Powered by parkwell
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
