import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Dashboard2 from "./pages/Dashboard2";
import About from "./pages/About";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import BlogDet from "./pages/BlogDet";
import Checkout from "./pages/Checkout";
import Confirmed from "./pages/Confirmed";
import Contact from "./pages/Contact";
import Create from "./pages/Create";
import CreateEvent from "./pages/CreateEvent";
import CreateTick from "./pages/CreateTick";
import CreateVenue from "./pages/CreateVenue";
import DashboardEvent from "./pages/DashboardEvent";
import DashboardProfile from "./pages/DashboardProfile";
import EventSet from "./pages/EventSet";
import Eventbydate from "./pages/Eventbydate";
import ExploreEvent from "./pages/ExploreEvent";
import Faq from "./pages/Faq";
import Forgot from "./pages/Forgot";
import Home from "./pages/Home";
import Invoice from "./pages/Invoice";
import OnlineEventDet from "./pages/OnlineEventDet";
import OrganizerProfile from "./pages/OrganizerProfile";
import OurBlog from "./pages/OurBlog";
import Payout from "./pages/Payout";
import Report from "./pages/Report";
import Team from "./pages/Team";
import VenueEventDet from "./pages/VenueEventDet";
import { useEffect, useState } from "react";
import { useAuth } from "./services/auth/useAuth.ts";
import {AxiosContext} from "./services/axios/"
import axios from "axios";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { environment } from "./services/environment.jsx";
import DashboardTickets from "./pages/Tickets.js";
import Terms from "./pages/Terms.js";

function App() {

  const { isLoggedIn } = useAuth();

  const [currentUser, setCurrentUser] = useState("");
  const [loggedIn, setLoggedIn] = useState(true);
  const { getUserDetails } = useAuth();

  const { getAuthToken } = useAuth();
  const [authToken, setAuthToken] = useState();
  useEffect(() => {
    if (authToken == null || authToken === "undefined") {
      getAuthToken().then((token) => {
        if (token != null) {
          setAuthToken(token);
        }
      });
    }
  });
  axios.defaults.headers.common = { Authorization: `bearer ${authToken}` };

  return (
    <AxiosContext.Provider value={axios}>
        <GoogleOAuthProvider clientId={environment.googleClientId}>
        <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/create-online-event" element={<CreateEvent />} />
        <Route path="/create-event" element={<Create />} />
        <Route path="/create-event-venue" element={<CreateVenue />} />
        <Route path="/event-details" element={<OnlineEventDet />} />
        <Route path="/event-details/:id" element={<OnlineEventDet />} />
        <Route path="/venue-event" element={<VenueEventDet />} />
        <Route path="/venue-event/:id" element={<VenueEventDet />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/booking-confirmed" element={<Confirmed />} />
        <Route path="/organizer-profile" element={<OrganizerProfile />} />
        <Route path="/explore-events" element={<ExploreEvent />} />
        <Route path="/blog" element={<OurBlog />} />
        <Route path="/blog/:id" element={<BlogDet />} />
        <Route path="/blog-detail" element={<BlogDet />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard-two" element={<Dashboard2 />} />
        <Route path="/dashboard-profile" element={<DashboardProfile />} />
        <Route path="/dashboard-payout" element={<Payout />} />
        <Route path="/dashboard-event" element={<DashboardEvent />} />
        <Route path="/dashboard-event/:id" element={<DashboardEvent />} />
        <Route path="/event-by-date" element={<Eventbydate />} />
        <Route path="/dashboard-report" element={<Report />} />
        <Route path="/dashboard-team" element={<Team />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/create-ticket" element={<CreateTick />} />
        <Route path="/create-ticket/:id" element={<CreateTick />} />
        <Route path="/event-settings" element={<EventSet />} />
        <Route path="/dashboard-tickets" element={<DashboardTickets />} />
      </Routes>
    </BrowserRouter>

        </GoogleOAuthProvider>

    </AxiosContext.Provider>
  );
}

export default App;
