import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/loops2.png";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation, useNavigate } from "react-router";
import { environment } from "../services/environment";
import { AxiosContext } from "../services/axios";
import QRCode from "react-qr-code";
const Invoice = () => {
  const today = new Date();

  const axios = useContext(AxiosContext);
  const [allEvents, setAllEvents] = useState("");
  // Format the date as a string (if needed)
  const formattedDate = today.toISOString().split('T')[0];
const location = useLocation();
const {state} = location;
const { name,address,amount,currency,email,phone,ref,gateway } = state?.bookingDetails.data || {};
 const { images } = state?.events || {};
const navigate = useNavigate();
  console.log('state coonfirmed', name);
console.log("state", state);
  const generatePDF = () => {
    const invoiceElement = document.querySelector('.invoice');
  
    // Use html2canvas to convert the HTML content to an image
    html2canvas(invoiceElement, {
      scale: 1.5,
    }).then((canvas) => {
      // Convert the canvas to a data URL
  var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      alert(imgHeight)
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  
      // Save the PDF as a blob
      const blob = pdf.output('blob');
  
      // Create a download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'invoice.pdf';
  
      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();
  
      // Remove the link from the document
      document.body.removeChild(link);

      // history.
    });
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (state?.ticketse !== null ){
      try {
        const response = await axios.get(
          `${environment.backendURL}/event/${state.ticketse[0]?.eventId}`
        );
        const eventData = response.data;
        setAllEvents(eventData);
        // Set loading status to false when data is fetched successfully
        console.log("all events", eventData);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching events:", error);
        // Set loading status to false on error
      }
    }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      <div  class="invoice clearfix">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-lg-8 col-md-10">
              <div class="invoice-header justify-content-between">
                <div class="invoice-header-logo">
                  <img src={parkwell} alt="invoice-logo" />
                </div>
                <div class="invoice-header-text">
                  <a href="#" class="download-link" onClick={generatePDF}>
                    Download
                  </a>
                </div>
              </div>
              <div class="invoice-body">
                <div class="invoice_dts">
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="invoice_title">Invoice</h2>
                    </div>
                    <div class="col-md-6">
                      <div class="vhls140">
                        <ul>
                          <li>
                            <div class="vdt-list">Invoice to {name}</div>
                          </li>
                          <li>
                            <div class="vdt-list">{address}</div>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="vhls140">
                        <ul>
                          <li>
                            <div class="vdt-list">
                              Invoice ID : {ref}
                            </div>
                          </li>
                          <li>
                            <div class="vdt-list">Order Date : {formattedDate}</div>
                          </li>
                          {/* <li>
                            <div class="vdt-list">Near MBD Mall,</div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="main-table bt_40">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Ticket Details</th>
                          <th scope="col">Type</th>
                          <th scope="col">Qty</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      {
                        state?.ticketse.map((ticket,index) => {
                          return (
                            <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                <a href="#" target="_blank">
                               {  ticket.name}
                                </a>
                              </td>
                              <td>{ticket.type}</td>
                              <td>1</td>
                              <td>{ticket.price}</td>
                              <td>{ticket.price}</td>
                            </tr>
                            <tr>
                              <td colspan="1"></td>
                              <td colspan="5">
                                <div class="user_dt_trans text-end pe-xl-4">
                                  <div class="totalinv2">
                                    {
                                      amount === 0 ? (
                                        <>
                                             Invoice Total : Free
                                        </>
                                   
                                      ):(
                                        <>
                                                 Invoice Total :{currency + " " + amount}
                                        </>
                               
                                      )
                                    }
                                    
                                  </div>
                                  <p>Paid via {gateway}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          )
                        })
                      }
                     
                    </table>
                  </div>
                </div>
                <div class="invoice_footer">
                  <div class="cut-line">
                    <i class="fa-solid fa-scissors"></i>
                  </div>
                  <div class="main-card">
                    <div class="row g-0">
                      <div class="col-lg-7">
                        <div class="event-order-dt p-4">
                          <div class="event-thumbnail-img">
                            <img src={images[0]} alt="" />
                          </div>
                          <div class="event-order-dt-content">
                            <h5>{allEvents?.name}</h5>
                            <span>{allEvents?.date}</span>
                            <div class="buyer-name">{name}</div>
                            <div class="booking-total-tickets">
                              <i class="fa-solid fa-ticket rotate-icon"></i>&nbsp;
                              {/* //Todo: increment the number of tickets */}
                              {/* <span class="booking-count-tickets mx-2">1</span>x */}
                              Ticket
                            </div>
                            <div class="booking-total-grand">
                      
                              {
                                      amount === 0 ? (
                                        <>
                                         Total : Free
                                        </>
                                   
                                      ):(
                                        <>
                                                     Total : <span>{currency + " " + amount}</span>
                                        </>
                               
                                      )
                                    }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <div class="QR-dt p-4">
                          {/* <ul class="QR-counter-type">
                            <li>Online</li>
                            <li>Counter</li>
                            <li>0000000001</li>
                          </ul> */}
                          <div class="QR-scanner">
                          <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
    <QRCode
    size={256}
    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
    value="https://ticketloope.com"
    viewBox={`0 0 256 256`}
    />
</div>
                          </div>
                          <p>Powered by Ticketloope</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cut-line">
                    <i class="fa-solid fa-scissors"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
