import { environment } from "../environment";

export const uploadFileToCloudinary = (
  file: string,
  cloudinaryFolder: string,
  imageName: string,
  cb: Function
) => {
  var url = `https://api.cloudinary.com/v1_1/${environment.cloud_name}/upload`;
  var xhr = new XMLHttpRequest();
  var fd = new FormData();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      cb(JSON.parse(xhr.responseText));
    }
  };
  fd.append("upload_preset", `${environment.cloud_preset}`);
  fd.append("public_id", `${imageName}`);
  fd.append("folder", cloudinaryFolder);
  fd.append("file", file);
  xhr.send(fd);
};

export const getRandomString = (length: number) => {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};
