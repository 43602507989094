import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { environment } from "../services/environment";
import { ToastContainer, toast } from "react-toastify";
import { AxiosContext } from "../services/axios";

const EditEventModal = ({ show, onHide, eventData, onSubmit }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const axios = useContext(AxiosContext);
const [eventID, setEventID] = useState("");
  // Update state when eventData changes
  useEffect(() => {
    if (eventData) {
      setName(eventData.name || "");
      setDescription(eventData.description || "");
      setEventID(eventData._id);
    }
  }, [eventData]);

  const handleSubmit = async () => {
    // Call the onSubmit function with updated data
    onSubmit({ name, description });
    try {
      const response =   await axios.post(
           `${environment.backendURL}/event/edit/${eventID}`,
           {
            
           name: name,
           description: description,
         
      
             },
           
         );
         // setShowed(false);
     console.log("response", response)
     if (response.status === 201) {
     toast("Event Edited",{
       position: "top-right",
       autoClose: 5000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
       theme: "light",});
    
     onHide();
     window.location.reload();
     }
       
         
       
       } catch (err) {
        onHide();
         console.log(err);
         toast(err,{
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",})
       } 
    // Close the modal
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form style={{padding:"14px"}} onSubmit={handleSubmit}>
          <div class="form-group pb_30"> 
            <label class="form-label fs-16">Name</label>
            <input
              className="form-control h_40"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label class="form-label fs-16">Description</label>
            <textarea
             className="form-control h_40"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
      <ToastContainer/>
    </Modal>
  );
};

export default EditEventModal;
