import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import parkwell from "../assets/images/lops.png";
import { useAuth } from "../services/auth/useAuth.ts";
import { useNavigate } from "react-router";
const DashboardHeader = () => {

  const { isLoggedIn } = useAuth();
  const { getUserDetails } = useAuth();
  const [currentUser, setCurrentUser] = useState("");
  const { logOut } = useAuth();
  const [loggedIn, setLoggedIn] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const checkLogin = async () => {
      const check = await isLoggedIn();
      setLoggedIn(check);
    };
    checkLogin();

  }, []);

  useEffect(() => {
    const getUser = () => {
      getUserDetails().then((detail) => {
        if (detail) {
          const user = JSON.parse(detail);
          setCurrentUser(user);
        }
      });
    };

    getUser();

  }, []);
console.log("curr", currentUser);
  return (
    <>
      <header class="header">
        <div class="header-inner">
          <nav class="navbar navbar-expand-lg bg-barren barren-head navbar fixed-top justify-content-sm-start pt-0 pb-0 ps-lg-0 pe-2">
            <div class="container-fluid ps-0">
              {/* <button type="button" id="toggleMenu" class="toggle_menu">
                <i class="fa-solid fa-bars-staggered"></i>
              </button> */}
              {/* <button id="collapse_menu" class="collapse_menu me-4">
                <i class="fa-solid fa-bars collapse_menu--icon "></i>
                <span class="collapse_menu--label"></span>
              </button> */}
              <button
                class="navbar-toggler order-1 ms-2 pe-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span class="navbar-toggler-icon">
                  <i class="fa-solid fa-bars"></i>
                </span>
              </button>
              <a
                class="navbar-brand order-2 order-lg-0 ml-lg-0 ml-2 me-auto"
                href="/"
              >
                <div  class="res-main-logo">
                  
                  <img src={parkwell} alt="" />
                </div>
                <div class="main-logo" id="logo">
                  <img src={parkwell} alt="" />
                  <img class="logo-inverse" src={parkwell} alt="" />
                </div>
              </a>
              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div class="offcanvas-header">
                  <div class="offcanvas-logo" id="offcanvasNavbarLabel">
                    <img src={parkwell} alt="" />
                  </div>
                  <button
                    type="button"
                    class="close-btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div class="offcanvas-body">
                  <div class="offcanvas-top-area">
                    <div class="create-bg">
                      <a href="/create-event" class="offcanvas-create-btn">
                        <i class="fa-solid fa-calendar-days"></i>
                        <span>Create Event</span>
                      </a>
                    </div>
                  </div>
                  <ul class="navbar-nav justify-content-end flex-grow-1 pe_5">
                    <li class="nav-item">
                      <a class="nav-link" href="/">
                        <i class="fa-solid fa-right-left me-2"></i>My Home
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/explore-events">
                        <i class="fa-solid fa-compass me-2"></i>Explore Events
                      </a>
                    </li>
                    <li class="nav-item d-md-none">
    <a class="nav-link" href="/dashboard-tickets">
        <i class="fa-solid fa-ticket me-2"></i>Manage Tickets
    </a>
</li>
                    <li class="nav-item d-md-none">
    <a class="nav-link" href="/dashboard-event">
        <i class="fa-solid fa-calendar-days me-2"></i>Events
    </a>
</li>
                    <li class="nav-item d-md-none">
    <a class="nav-link" href="/dashboard-payout">
        <i class="fa-solid fa-credit-card  me-2"></i>Payouts
    </a>
</li>
                  </ul>
                </div>
                <div class="offcanvas-footer">
                  <div class="offcanvas-social">
                    <h5>Follow Us</h5>
                    <ul class="social-links">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=61554966682337&mibextid=ZbWKwL" class="social-link">
                          <i class="fab fa-facebook-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/ticketloope?igsh=YzAwZjE1ZTI0Zg==" class="social-link">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://x.com/ticketloope?t=M9skdtPbokSgpbD6nJG9ww&s=09" class="social-link">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/ticketloope/"  class="social-link">
                          <i class="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    
                    </ul>
                  </div>
                </div>
              </div>
              <div class="right-header order-2">
                <ul class="align-self-stretch">
                  <li>
                    <a href="/create-event" class="create-btn btn-hover">
                      <i class="fa-solid fa-calendar-days"></i>
                      <span>Create Event</span>
                    </a>
                  </li>
                  <li class="dropdown account-dropdown order-3">
                    <a
                      href="#"
                      class="account-link"
                      role="button"
                      id="accountClick"
                      data-bs-auto-close="outside"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="images/profile-imgs/avatarsvg.svg" alt="" />
                      <i class="fas fa-caret-down arrow-icon"></i>
                    </a>
                    <ul
                      class="dropdown-menu dropdown-menu-account dropdown-menu-end"
                      aria-labelledby="accountClick"
                    >
                      <li>
                        <div class="dropdown-account-header">
                          <div class="account-holder-avatar">
                            <img src="images/profile-imgs/avatarsvg.svg" alt="" />
                          </div>
                          {currentUser ? (
  <h5>
    {currentUser.firstName?.charAt(0).toUpperCase() +
      currentUser.firstName?.slice(1)}&nbsp;
    {currentUser.lastName?.charAt(0).toUpperCase() +
      currentUser.lastName?.slice(1)}
  </h5>
) : (
  <p>Loading...</p>
)}
                          <p>{currentUser?.email}</p>
                        </div>
                      </li>
                      <li class="profile-link">
                        {/* <a href="organiser_profile_view.html" class="link-item">
                          My Profile
                        </a> */}
                        <a  onClick={async () => {
              await logOut();
           navigate("/");
            }}  class="link-item">
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <div class="night_mode_switch__btn">
                      <div id="night-mode" class="fas fa-moon fa-sun"></div>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
          <div class="overlay"></div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
