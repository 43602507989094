import React, { useEffect, useState } from "react";
import Footer from "./Footer.js";

import parkwell from "../assets/images/lops.png";
import { useAuth } from "../services/auth/useAuth.ts";
import { Link, useNavigate } from "react-router-dom";
const Header = () => {
  const { isLoggedIn } = useAuth();
  const { getUserDetails } = useAuth();
  const [currentUser, setCurrentUser] = useState("");
  const { logOut } = useAuth();
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const checkLogin = async () => {
      const check = await isLoggedIn();
      setLoggedIn(check);
    };
    checkLogin();

  }, []);

  console.log("loggedin", loggedIn);

  useEffect(() => {
    const getUser = () => {
      getUserDetails().then((detail) => {
        if (detail) {
          const user = JSON.parse(detail);
          setCurrentUser(user);
        }
      });
    };

    getUser();

  }, []);
console.log("curr", currentUser);
  return (
    <>
      <header class="header">
        <div class="header-inner">
          <nav class="navbar navbar-expand-lg bg-barren barren-head navbar fixed-top justify-content-sm-start pt-0 pb-0">
            <div class="container">
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span class="navbar-toggler-icon">
                  <i class="fa-solid fa-bars"></i>
                </span>
              </button>
              <a
                class="navbar-brand order-1 order-lg-0 ml-lg-0 ml-2 me-auto"
                href="/"
              >
                <div class="res-main-logo">
                  <img
                    src={parkwell}
                    alt=""
                    style={{ width: "140px", height: "30px" }}
                  />
                </div>
                <div class="main-logo" id="logo">
                  <img
                    src={parkwell}
                    alt=""
                    style={{ width: "140px", height: "30px" }}
                  />
                  <img
                    class="logo-inverse"
                    src={parkwell}
                    alt=""
                    style={{ width: "140px", height: "30px" }}
                  />
                </div>
              </a>
              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div class="offcanvas-header">
                  <div class="offcanvas-logo" id="offcanvasNavbarLabel"></div>
                  <button
                    type="button"
                    class="close-btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div class="offcanvas-body">
                  <div class="offcanvas-top-area">
                    <div class="create-bg">
                      <a href={loggedIn === true ? "/create-event" : "/login"} class="offcanvas-create-btn">
                        <i class="fa-solid fa-calendar-days"></i>
                        <span>Create Event</span>
                      </a>
                    </div>
                  </div>
                  <ul class="navbar-nav justify-content-end flex-grow-1 pe_5">
                    <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="/">
                        Home
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link "
                        aria-current="page"
                        href="/explore-events"
                      >
                        Explore Events
                      </a>
                    </li>

                    {/*<li class="nav-item">
                      <a class="nav-link" href="pricing.html">
                        Pricing
                      </a>
  </li>*/}

                    <li class="nav-item">
                      <a class="nav-link " aria-current="page" href="/blog">
                        Blog
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link " aria-current="page" href="/faq">
                        Help
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link " aria-current="page" href="/contact">
                        Contact Us
                      </a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link " aria-current="page" href="/about">
                        About
                      </a>
                    </li>

                   
                  </ul>
                </div>
                <div class="offcanvas-footer">
                  <div class="offcanvas-social">
                    <h5>Follow Us</h5>
                    <ul class="social-links">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61554966682337&mibextid=ZbWKwL" class="social-link">
                        <i class="fab fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/ticketloope?igsh=YzAwZjE1ZTI0Zg==" class="social-link">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/ticketloope?t=M9skdtPbokSgpbD6nJG9ww&s=09" class="social-link">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/ticketloope/" class="social-link">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="right-header order-2">
                <ul class="align-self-stretch">
                  <li>
                    <a href={loggedIn === true ? "/create-event" : "/login"} class="create-btn btn-hover">
                      <i class="fa-solid fa-calendar-days"></i>
                      <span>Create Event</span>
                    </a>
                  </li>

                  {loggedIn ? (
                    // User is authenticated, show account dropdown
                    <li className="dropdown account-dropdown">
                      <a
                        href="#"
                        className="account-link"
                        role="button"
                        id="accountClick"
                        data-bs-auto-close="outside"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* Display user information or profile picture here */}
                        <img src="images/profile-imgs/avatarsvg.svg" alt="" />
                        <i className="fas fa-caret-down arrow-icon"></i>
                      </a>
                      <ul
                        className="dropdown-menu dropdown-menu-account dropdown-menu-end"
                        aria-labelledby="accountClick"
                      >
                        <li>
                          <div className="dropdown-account-header">
                            {/* Display user information here */}
                            <div className="account-holder-avatar">
                              <img
                                src="images/profile-imgs/avatarsvg.svg"
                                alt=""
                              />
                            </div>
                            {currentUser ? (
  <h5>
    {currentUser.firstName?.charAt(0).toUpperCase() +
      currentUser.firstName?.slice(1)}&nbsp;
    {currentUser.lastName?.charAt(0).toUpperCase() +
      currentUser.lastName?.slice(1)}
  </h5>
) : (
  <p>Loading...</p>
)}

                            <p>{currentUser?.email}</p>
                          </div>
                        </li>
                        <li className="profile-link">
                          <a href="/dashboard" className="link-item">
                            My Dashboard
                          </a>
                          {/* <Link to="/organizer-profile" className="link-item">
                            My Profile
                          </Link> */}
                          <button className="link-item"  onClick={async () => {
              await logOut().then(() =>    {
                navigate("/login");
              }  )
       
            }}>
                            Sign Out
                          </button>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <>
                  
                    <li className="nav-item">
                        <a  href="/login" class="create-btn btn-hover" aria-current="page">
                        <i class="fa fa-sign-in" aria-hidden="true"></i>
                       <span>Login</span>  
                        </a>
                      </li><li className="nav-item">
                          <a  href="/register" class="create-btn btn-hover" aria-current="page">
                          <i class="fa fa-user-plus" aria-hidden="true"></i>
                          <span>Register</span>  
                          </a>
                        </li></>
                  )}
            
                  {/*      <li>
                    <div class="night_mode_switch__btn">
                      <div id="night-mode" class="fas fa-moon fa-sun"></div>
                    </div>
</li>*/}
                </ul>
              </div>
            </div>
          </nav>
          <div class="overlay"></div>
        </div>
      </header>
    </>
  );
};

export default Header;
