import React, { useCallback, useContext, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
// import parkwell from "../assets/images/parklogo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { getRandomString, uploadFileToCloudinary } from '../services/cloudinary/index.ts';
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../services/environment.jsx";
import { AxiosContext } from "../services/axios/index.js";

const CreateEvent = () => {
  const axios = useContext(AxiosContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const handleModalOpen = useCallback((props) => {
    navigate(`/create-ticket/${props._id}`);
  }, [navigate]);
          const [eventName, setEventName] = useState("");
          const [eventDate, setEventDate] = useState("");
          const [eventVenue, setEventVenue] = useState("");
          const [eventEndDate, setEventEndDate] = useState("");
          const [virtualUrl, setVirtualUrl] = useState("");
          const [eventAddress, setEventAddress] = useState("");
          const [eventTime, setEventTime] = useState("");
          const [eventCategory, setEventCategory] = useState("");
          const [eventDescription, setEventDescription] = useState("");
          const [eventEndTime, setEventEndTime] = useState("");
          const [eventOrganizers, setEventOrganizers] = useState("");

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      // Cloudinary upload logic
      try {
        // setIsLoading(true);

        // Assuming you have the function uploadFileToCloudinary
        const cloudinaryResponse = await uploadFileToCloudinary(
          file,
          "drivers",
          getRandomString(20),
          (respondData) => {
            console.log("upload response",respondData);
            // setNewFeaturedImage(respondData.secure_url);
            setSelectedImage(respondData.secure_url);
            // setFeaturedImageUrl("");
            // setIsLoading(false);
         
          }
        );
        toast.success("Image Added Successfully");
        console.log("Cloudinary upload response", cloudinaryResponse);

        // Set the selected image to the Cloudinary URL
        
      } catch (error) {
        console.error("Error uploading to Cloudinary", error);
        toast.error(error);
      } finally {
        // setIsLoading(false);
      }
    }
  };


  const createNewEvent = async (e) => {
    e.preventDefault(); 
    try {
   const response =   await axios.post(
        `${environment.backendURL}/event`,
        {
          name: eventName,
          type: "VIRTUAL",
          category: eventCategory,
          date: eventDate,
          time: eventTime,
          virtualUrl: virtualUrl,
          venue: eventVenue,
          address: eventAddress,
          images: [selectedImage],
          description: eventDescription
          },
        
      );
      // setShowed(false);
console.log("response", response)
if (response.status === 201) {
  toast("Event Created",{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",});
// navigate('/create-ticket');
const modal = new bootstrap.Modal(document.getElementById('myModal'));
await modal.show();
}
      // window.location.reload().then(() => {
      //   setShowToast(true);
      // });
      
    
    } catch (err) {
      console.log(err);
      // toast(err.response.data.message[0],{
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",})
    } 
    // setShowToast(true);
  };



  return (
    <>
      <body class="d-flex flex-column h-100">
        <Header />

        <div class="wrapper">
          <div class="breadcrumb-block">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-10">
                  <div class="barren-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">
                          Create Online Event
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-dt-block p-80">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12">
                  <div class="main-title text-center">
                    <h3>Create Online Event</h3>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-9 col-md-12">
                  <div class="wizard-steps-block">
                    <div id="add-event-tab" class="step-app">
                      <div class="step-content">
                        <div
                          class="step-tab-panel step-tab-info active"
                          id="tab_step1"
                        >
                          <div class="tab-from-content">
                            <div class="main-card">
                              <div class="bp-title">
                                <h4>
                                  <i class="fa-solid fa-circle-info step_icon me-3"></i>
                                  Details
                                </h4>
                              </div>
                              <div class="p-4 bp-form main-form">
                                <div class="row">
                                  <div class="col-lg-12 col-md-12">
                                    <div class="form-group border_bottom pb_30">
                                      <label class="form-label fs-16">
                                        Give your event a name.*
                                      </label>
                                      <p class="mt-2 d-block fs-14 mb-3">
                                        See how your name appears on the event
                                        page and a list of all places where your
                                        event name will be used.{" "}
                                        <a href="#" class="a-link">
                                          Learn more
                                        </a>
                                      </p>
                                      <input
                                        class="form-control h_50"
                                        type="text"
                                        onChange={(e) => setEventName(e.target.value)}
                                        placeholder="Enter event name here"
                                        value={eventName}
                                      />
                                    </div>
                                    <div class="form-group border_bottom pb_30">
                                      <label class="form-label fs-16">
                                        Name of the event Organizers.*
                                      </label>
                                      <p class="mt-2 d-block fs-14 mb-3">
                                        This name appears on the event
                                        page as the organiser.{" "}
                                  
                                      </p>
                                      <input
                                        class="form-control h_50"
                                        type="text"

                                        onChange={(e) => setEventOrganizers(e.target.value)}
                                        placeholder="Enter event organiser here"
                                        value={eventOrganizers}
                                      />
                                    </div>
                                    <div class="form-group border_bottom pt_30 pb_30">
                                      <label class="form-label fs-16">
                                        Choose a category for your event.*
                                      </label>
                                      <p class="mt-2 d-block fs-14 mb-3">
                                        Choosing relevant categories helps to
                                        improve the discoverability of your
                                        event.{" "}
                                        <a href="#" class="a-link">
                                          Learn more
                                        </a>
                                      </p>
                                      <select
                                        class="selectpicker"
                                        style={{height:"54px", borderRadius:"12px", padding:"10px", fontSize:"20px", fontWeight:"500"}}
                                        onChange={(e) => setEventCategory(e.target.value)}
                                        multiple=""
                                        data-size="5"
                                        title="Select category"
                                        data-live-search="true"
                                      >
                                        <option value="ARTS">Arts</option>
                                        <option value="CONCERT">Concerts</option>
                                        <option value="SEMINAR">
                                          Seminar
                                        </option>
                                        <option value="SPORTS">
                                          Sports
                                        </option>
                                        <option value="GALA">
                                         Gala
                                        </option>
                                        <option value="FASHION">
                                          Fashion
                                        </option>
                                        <option value="FESTIVALS">
                                          Festivals
                                        </option>
                                        <option value="Fashion and Beauty">
                                          Fashion and Beauty
                                        </option>
                                        <option value="Film and Entertainment">
                                          Film and Entertainment
                                        </option>
                                        <option value="FOODANDDRINKS">
                                          Food and Drink
                                        </option>
                                        <option value="WEBINARS">
                                        Webinars
                                        </option>
                                        <option value="TRAINING_WORKSHOP">
                                         Training & Workshops
                                        </option>
                                        <option value="ONLINE_CLASS">
                                      Online Class
                                        </option>
                                        <option value="TALK_SHOW">
                                         Talk Show
                                        </option>
                                        <option value="Religion and Spirituality">
                                          Religion and Spirituality
                                        </option>
                                        <option value="TECH">
                                          Science and Technology
                                        </option>
                                        <option value="SPORTSANDFITNESS">
                                          Sports and Fitness
                                        </option>
                                        <option value="TRAVELANDOUTDOOR">
                                          Travel and Outdoor
                                        </option>
                                        <option value="VISUALARTS">Visual Arts</option>
                                        <option value="OTHERS">Others</option>
                                      </select>
                                    </div>
                                    <div class="form-group border_bottom pt_30 pb_30">
                                      <label class="form-label fs-16">
                                        When is your event?*
                                      </label>
                                      <p class="mt-2 fs-14 d-block mb-3">
                                        Tell your attendees when your event
                                        starts so they can get ready to attend.
                                      </p>
                                      <div class="row g-2">
                                        <div class="col-md-6">
                                          <label class="form-label mt-3 fs-6">
                                            Event Date.*
                                          </label>
                                          <div class="loc-group position-relative">
                                            <input
                                              class="form-control h_50 datepicker-here"
                                              data-language="en"
                                              onChange={(e) => setEventDate(e.target.value)}
                                              type="text"
                                              placeholder="DD/MM/YYYY"
                                              value={eventDate}
                                            />
                                            <span class="absolute-icon">
                                              <i class="fa-solid fa-calendar-days"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="row g-2">
                                            <div class="col-md-6">
                                              <div class="clock-icon">
                                                <label class="form-label mt-3 fs-6">
                                                  Time
                                                </label>
                                                <div className="time-picker">
        {/* <label>Time:</label> */}
        <input
          type="time"
          value={eventTime}
          onChange={(e) => setEventTime(e.target.value)}

          style={{
            maxWidth: '167.5px',
            height: '44px',
            padding: '10px 14px',
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            backgroundColor:"white",
            fontFamily:"Inter-Bold",
            color: "#666666",
          }}
        />
      </div>
                                              </div>
                                            </div>
                                            {/* <div class="col-md-6">
                                              <label class="form-label mt-3 fs-6">
                                                Duration
                                              </label>
                                              <select
                                                class="selectpicker"
                                                data-size="5"
                                                data-live-search="true"
                                              onChange={(e) => setDuratione(e.target.value)}

                                              >
                                                <option value="15">15m</option>
                                                <option value="30">30m</option>
                                                <option value="45">45m</option>
                                                <option
                                                  value="60"
                                                  selected="selected"
                                                >
                                                  1h
                                                </option>
                                                <option value="75">
                                                  1h 15m
                                                </option>
                                                <option value="90">
                                                  1h 30m
                                                </option>
                                                <option value="105">
                                                  1h 45m
                                                </option>
                                                <option value="120">2h</option>
                                                <option value="135">
                                                  2h 15m
                                                </option>
                                                <option value="150">
                                                  2h 30m
                                                </option>
                                                <option value="165">
                                                  2h 45m
                                                </option>
                                                <option value="180">3h</option>
                                                <option value="195">
                                                  3h 15m
                                                </option>
                                                <option value="210">
                                                  3h 30m
                                                </option>
                                                <option value="225">
                                                  3h 45m
                                                </option>
                                              </select>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row g-2">
                                        <div class="col-md-6">
                                          <label class="form-label mt-3 fs-6">
                                            Event End Date.*
                                          </label>
                                          <div class="loc-group position-relative">
                                            <input
                                              class="form-control h_50 datepicker-here"
                                              data-language="en"
                                              onChange={(e) => setEventEndDate(e.target.value)}
                                              type="text"
                                              placeholder="DD/MM/YYYY"
                                              value={eventEndDate}
                                            />
                                            <span class="absolute-icon">
                                              <i class="fa-solid fa-calendar-days"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="row g-2">
                                            <div class="col-md-6">
                                              <div class="clock-icon">
                                                <label class="form-label mt-3 fs-6">
                                                End Time
                                                </label>
                                                <div className="time-picker">
        {/* <label>Time:</label> */}
        <input
          type="time"
          value={eventEndTime}
          onChange={(e) => setEventEndTime(e.target.value)}

          style={{
            maxWidth: '167.5px',
            height: '50px',
            padding: '10px 14px',
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            backgroundColor:"white",
            fontWeight:"500",
            color: "black",
          }}
        />
      </div>
                                              </div>
                                            </div>
                                            {/* <div class="col-md-6">
                                              <label class="form-label mt-3 fs-6">
                                                Duration
                                              </label>
                                              <select
                                                class="selectpicker"
                                                data-size="5"
                                                data-live-search="true"
                                              onChange={(e) => setDuratione(e.target.value)}

                                              >
                                                <option value="15">15m</option>
                                                <option value="30">30m</option>
                                                <option value="45">45m</option>
                                                <option
                                                  value="60"
                                                  selected="selected"
                                                >
                                                  1h
                                                </option>
                                                <option value="75">
                                                  1h 15m
                                                </option>
                                                <option value="90">
                                                  1h 30m
                                                </option>
                                                <option value="105">
                                                  1h 45m
                                                </option>
                                                <option value="120">2h</option>
                                                <option value="135">
                                                  2h 15m
                                                </option>
                                                <option value="150">
                                                  2h 30m
                                                </option>
                                                <option value="165">
                                                  2h 45m
                                                </option>
                                                <option value="180">3h</option>
                                                <option value="195">
                                                  3h 15m
                                                </option>
                                                <option value="210">
                                                  3h 30m
                                                </option>
                                                <option value="225">
                                                  3h 45m
                                                </option>
                                              </select>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group pt_30 pb_30">
                                      <label class="form-label fs-16">
                                        Add an image to your event banner.
                                      </label>
                                      <p class="mt-2 fs-14 d-block mb-3 pe_right">
                                        Upload colorful and vibrant images as
                                        the banner for your event! See how
                                        beautiful images help your event details
                                        page.{" "}
                                        <a href="#" class="a-link">
                                          Learn more
                                        </a>
                                      </p>
                                   <div className="content-holder mt-4">
      <div className="default-event-thumb">
        <div className="default-event-thumb-btn">
          <div className="thumb-change-btn">
            <input
              type="file"
              id="thumb-img"
              onChange={handleImageChange}
            />
            <label htmlFor="thumb-img">Change Image</label>
          </div>
        </div>
        <img
          src={selectedImage || "images/banners/custom-img.jpg"}
          alt=""
        />
      </div>
    </div>
                                    </div>
                                    <div class="form-group border_bottom pb_30">
                                      <label class="form-label fs-16">
                                        Please describe your event.
                                      </label>
                                      <p class="mt-2 fs-14 d-block mb-3">
                                        Write a few words below to describe your
                                        event and provide any extra information
                                        such as schedules, itinerary or any
                                        special instructions required to attend
                                        your event.
                                      </p>
                                      <div class="text-editor mt-4">
                                      <input
                                        class="form-control h_50"
                                        type="text"
                                        onChange={(e) => setEventDescription(e.target.value)}
                                        placeholder="Enter Description Here"
                                        value={eventDescription}
                                      />
                                      </div>
                                    </div>
                                    <div class="form-group pt_30 pb-2">
                                      <label class="form-label fs-16">
                                        Virtual Link *
                                      </label>
                                      <p class="mt-2 fs-14 d-block mb-3">
                                        Add a Link to your event so your
                                        attendees can join the event.
                                      </p>
                                      <div class="stepper-data-set">
                                        <div class="content-holder template-selector">
                                          <div class="row g-4">
                                            {/* <div class="col-md-12">
                                              <div class="venue-event">
                                                <div class="map">
                                                  <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d27382.59422947023!2d75.84077125074462!3d30.919535510612153!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1534312417365"
                                                    style={{ border: "0" }}
                                                    allowfullscreen
                                                  ></iframe>
                                                </div>
                                              </div>
                                            </div> */}
                                            <div class="col-md-12">
                                              <div class="form-group mt-1">
                                                <label class="form-label fs-6">
                                                 Venue *
                                                </label>
                                                <input
                                                  class="form-control h_50"
                                                  type="text"
                                        onChange={(e) => setEventVenue(e.target.value)}

                                                  placeholder="e.g MEET, ZOOM..."
                                                  value={eventVenue}
                                                />
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group mt-1">
                                                <label class="form-label fs-6">
                                                Link Address *
                                                </label>
                                                <input
                                                  class="form-control h_50"
                                                  type="text"
                                        onChange={(e) => setVirtualUrl(e.target.value)}

                                                  placeholder=""
                                                  value={virtualUrl}
                                                />
                                              </div>
                                            </div>
                                  
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="step-footer step-tab-pager mt-4">
                        
                          <button
                                                    disabled={eventName.length < 1 || eventCategory.length < 1 || eventDate.length < 1 || eventVenue.length < 1 || virtualUrl.length < 1}
                            data-direction="next"
                            class="btn btn-default btn-hover steps_btn"
                            onClick={() => createNewEvent()}
                          >
                            Create Event
                          </button>
                          {/* <button
                          
                            class="btn btn-default btn-hover steps_btn"
                            onClick={() => clickBtn()}
                          >
                       open modal
                          </button> */}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Footer />
        <div id="myModal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    {/* <i class="material-icons">&#xE876;</i> */}
                    <i class="fa-solid fa-thumbs-up"></i>
                </div>
                <h4 class="modal-title w-100">Awesome!</h4>
            </div>
            <div class="modal-body">
                <p class="text-center">Your event has been created. Check your dashboard for details.</p>
            </div>
            <div class="modal-footer">
                <button data-bs-dismiss="modal" onClick={() => navigate('/dashboard')} class="btn btn-success btn-block" >OK</button>
            </div>
        </div>
    </div>
</div>
      </body>
    </>
  );
};

export default CreateEvent;
