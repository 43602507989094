import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/parklogo.png";
import { useParams } from "react-router";
import { AxiosContext } from "../services/axios";
import { environment } from "../services/environment";
import { useAuth } from "../services/auth/useAuth.ts";
import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from 'styled-components';
import { addDays, intervalToDuration, compareAsc, addSeconds,parseISO, differenceInMilliseconds, format, } from 'date-fns';
import Card from "../components/Card.js";

const CountdownComponent = ({ eventDateTime }) => {
  const calculateTimeLeft = () => {
    const eventDate = parseISO(eventDateTime);
    const currentDate = new Date();

    const difference = differenceInMilliseconds(eventDate, currentDate);

    if (difference > 0) {
      // Calculate duration including years if event date is in the future
      const duration = intervalToDuration({ start: currentDate, end: eventDate });

      // Add years to the duration
      const yearsDiff = eventDate.getFullYear() - currentDate.getFullYear();
      duration.years = yearsDiff;

      return duration;
    } else {
      // If the event date has already passed, return all zeros
      return { years: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [eventDateTime]);

  return (
    <div className="countdown">
      {timeLeft.years > 0 && (
        <div key={'year'} className="countdown-item">
          <span id="year">{timeLeft.years}</span> years
        </div>
      )}
      {timeLeft.days > 0 && (
        <div key={'day'} className="countdown-item">
          <span id="day">{timeLeft.days}</span> days
        </div>
      )}
      {timeLeft.hours > 0 && (
        <div key={'hour'} className="countdown-item">
          <span id="hour">{timeLeft.hours}</span> hours
        </div>
      )}
      {timeLeft.minutes > 0 && (
        <div key={'mins'} className="countdown-item">
          <span id="minute">{timeLeft.minutes}</span> minutes
        </div>
      )}
      <div key={'secs'} className="countdown-item">
        <span id="second">{timeLeft.seconds}</span> seconds
      </div>
    </div>
  );
};

const RootContainer = styled.div `
font-size: 28%;
font-family: 'Red Hat Text', sans-serif;
box-sizing: border-box;

@media screen and (max-width: 59.375em) {
  font-size: 20%;
}

@media screen and (max-width: 37.5em) {
  font-size: 25%;
}

`


const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 5rem;
`;

const Heading = styled.h1`
color: var(--white);
text-transform: uppercase;
letter-spacing: 5px;
font-size: 1.6rem;
text-align: center;
`;

const Main = styled.main`
display: flex;
justify-content: center;
gap: 2.5rem;
`;
const VenueEventDet = () => {
  const { getUserDetails } = useAuth();
  const [currentUser, setCurrentUser] = useState("");
  const [ticketQuantity, setTicketQuantity] = useState(0);
const [totalCost, setTotalCost] = useState(0);
// const [ticketQuantities, setTicketQuantities] = useState({});
  const [allEvents, setAllEvents] = useState(null);
  const [allTickets, setAllTickets] = useState([]);
  const [ticketQuantities, setTicketQuantities] = useState(Array(allTickets.length).fill(0));
  const axios = useContext(AxiosContext);
  const {id} = useParams();
 const [formattedDateTime, setFormattedDateTime] = useState(null);


const [targetTime] = useState(addDays(new Date(), 14));
const [currentTime, setCurrentTime] = useState(new Date());
useEffect(() => setCurrentTime(time => addSeconds(time, 1)), []);

useEffect(() => {
  const intvervalId = setInterval(() => {
    if (compareAsc(currentTime, targetTime) >= 0) return;

    setCurrentTime(addSeconds(currentTime, 1));
  }, 1000);
  return () => clearInterval(intvervalId);
}, [currentTime, targetTime]);

const { days, hours, minutes, seconds } = intervalToDuration({
  start: currentTime,
  end: targetTime,
});
const nextDays = days > 0 ? days - 1 : 0;
const nextHours = hours > 0 ? hours - 1 : days > 0 ? 23 : 0;
const nextMinutes = minutes > 0 ? minutes - 1 : days > 0 || hours > 0 ? 59 : 0;
const nextSeconds = seconds > 0 ? seconds - 1 : days > 0 || hours > 0 || minutes > 0 ? 59 : 0;

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${environment.backendURL}/event/slug/${id}`
          );
          const eventData = response.data;
            const formattedDate = eventData?.date ? format(new Date(eventData?.date), 'yyyy-MM-dd') : null;
        const formattedTime = eventData?.time ? format(new Date(`1970-01-01T${eventData?.time}`), 'HH:mm:ss') : null;
      
        setFormattedDateTime(formattedDate && formattedTime ? `${formattedDate}T${formattedTime}` : null);
      
          setAllEvents(eventData);
          // Set loading status to false when data is fetched successfully
          console.log("all events", eventData);
        } catch (error) {
          // Handle any errors here
          console.error("Error fetching events:", error);
          // Set loading status to false on error
        }
      };
  
      const timer = setTimeout(() => {
        fetchData();
      }, 200);
  
      return () => clearTimeout(timer);
    }, []);

    console.log("formatted", formattedDateTime);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${environment.backendURL}/ticket/event/${allEvents?._id}`
          );
          const eventData = response.data;
          setAllTickets(eventData);
          // Set loading status to false when data is fetched successfully
          console.log("all tickets", eventData);
        } catch (error) {
          // Handle any errors here
          console.error("Error fetching events:", error);
          // Set loading status to false on error
        }
      };
  
      const timer = setTimeout(() => {
        fetchData();
      }, 200);
  
      return () => clearTimeout(timer);
    }, [allEvents]);
    useEffect(() => {
      const getUser = () => {
        getUserDetails().then((detail) => {
          if (detail) {
            const user = JSON.parse(detail);
            setCurrentUser(user);
          }
        });
      };
  
      getUser();
  
    }, []);
  console.log("curr", currentUser);
    function getShortMonth(dateString) {
      // Create a Date object from the given date string
      const dateObject = new Date(dateString);
    
      // Define options for formatting the date
      const options = { month: 'short' };
    
      // Use toLocaleString to format the date and get the short form of the month
      const shortMonth = dateObject.toLocaleString('en-US', options);
    
      return shortMonth.toUpperCase(); // Optionally convert to uppercase
    }
    
    // Example usage:
    // const date = allEvents?.date;
    // const shortMonth = getShortMonth(date);
    // console.log(shortMonth);

    // function getDayFromDate(dateString) {
    //   // Split the date string into an array
    //   const dateArray = dateString.split('/');
    
    //   // Extract the day part (index 0 represents the day in "DD/MM/YYYY")
    //   const day = dateArray[0];
    
    //   return day;
    // }
    
    // // Example usage:
    // const dateday = allEvents?.date;
    // const day = getDayFromDate(dateday);
    // console.log(day); // Output: 12
    

    function formatDateString(inputDate) {
      // Create a Date object from the input date string
      const dateObject = new Date(inputDate);
    
      // Define options for formatting the date
      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      };
    
      // Use toLocaleDateString to format the date
      const formattedDate = dateObject.toLocaleDateString('en-US', options);
    
      return formattedDate;
    }
    
    // Example usage:
    const inputDate = allEvents?.date;
    const formattedDate = formatDateString(inputDate);
    console.log(formattedDate); 

    function formatTime(timeString) {
      // Create a Date object with a dummy date and the input time
      const dummyDate = new Date('2024-01-01 ' + timeString);
    
      // Define options for formatting the time
      const options = {
        
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
    
      // Use toLocaleTimeString to format the time
      const formattedTime = dummyDate.toLocaleTimeString('en-US', options);
    
      return formattedTime;
    }
    
    // Example usage:
    const inputTime = allEvents?.time;
    const formattedTime = formatTime(inputTime);
    console.log(formattedTime); // Output: 4:00 AM (or 4:00 PM depending on local settings)

    
    useEffect(() => {
      // Calculate total cost whenever ticket quantities change
      const newTotalCost = allTickets.reduce((acc, ticket, index) => {
        const quantity = ticketQuantities[index] || 0;
        return acc + quantity * ticket.price;
      }, 0);
  
      setTotalCost(newTotalCost.toFixed(2));
    }, [ticketQuantities, allTickets]);
  
    const updateCount = (index, increment) => {
      setTicketQuantities((prevQuantities) => {
        const currentQuantity = prevQuantities[index] || 0;
        const newQuantity = increment ? currentQuantity + 1 : Math.max(0, currentQuantity - 1);
  
        const newQuantities = [...prevQuantities];
        newQuantities[index] = newQuantity;
  
        return newQuantities;
      });
    };

    const totalTickets = ticketQuantities.reduce((acc, quantity) => acc + quantity, 0);
  return (
    <>
      <body class="d-flex flex-column h-100">
        <Header />

        <div class="wrapper">
          <div class="breadcrumb-block">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-10">
                  <div class="barren-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">
                 Event Detail View
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-dt-block p-80">
            <div class="container">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="event-top-dts">
                    {/* <div class="event-top-date">
                      <span class="event-month">{shortMonth ? shortMonth : "..."}</span>
                      <span class="event-date">{day ? day : "..."}</span>
                    </div> */}
                    <div class="event-top-dt">
                      <h3 class="event-main-title">
                      {allEvents?.name} {allEvents?.date} at {allEvents?.time}
                      </h3>
                      <div class="event-top-info-status">
                        <span class="event-type-name">
                          <i class="fa-solid fa-location-dot"></i>{allEvents?.type === "VENUE" ? "Physical" : "Online"}  Event
                        </span>
                        <span class="event-type-name details-hr">
                          Starts on{" "}
                          <span class="ev-event-date">
                          {allEvents?.date} at {allEvents?.time}
                          </span>
                        </span>
                        {/* <span class="event-type-name details-hr">2h</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-7 col-md-12">
                  <div class="main-event-dt">
                    <div class="event-img">
                      <img src={ allEvents !== null ? allEvents?.images[0] : ""} alt="" />
                    </div>
                    {/* <div class="share-save-btns dropdown">
                      <button class="sv-btn me-2">
                        <i class="fa-regular fa-bookmark me-2"></i>Save
                      </button>
                      <button
                        class="sv-btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa-solid fa-share-nodes me-2"></i>Share
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="fa-brands fa-facebook me-3"></i>Facebook
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="fa-brands fa-twitter me-3"></i>Twitter
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="fa-brands fa-linkedin-in me-3"></i>
                            LinkedIn
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="fa-regular fa-envelope me-3"></i>Email
                          </a>
                        </li>
                      </ul>
                    </div> */}
                    <div class="main-event-content">
                      <h4>About This Event</h4>
                 
                      <p>
                  {allEvents?.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-12">
                  <div class="main-card event-right-dt">
                    <div class="bp-title">
                      <h4>Event Details</h4>
                    </div>
                    <div class="time-left">
                      <div class="countdown">
                        {/* <RootContainer>
                        <Container>
                    
                      <Main>
        <Card currentNumber={days} nextNumber={nextDays} title="days" />
        <Card currentNumber={hours} nextNumber={nextHours} title="hours" />
        <Card currentNumber={minutes} nextNumber={nextMinutes} title="minutes" />
        <Card currentNumber={seconds} nextNumber={nextSeconds} title="seconds" />
      </Main>
      </Container>
                        </RootContainer> */}
                     
                        {/* <div class="countdown-item">
                          <span id="day"></span>days
                        </div>
                        <div class="countdown-item">
                          <span id="hour"></span>Hours
                        </div>
                        <div class="countdown-item">
                          <span id="minute"></span>Minutes
                        </div>
                        <div class="countdown-item">
                          <span id="second"></span>Seconds
                        </div> */}
                   {formattedDateTime && (
            <CountdownComponent eventDateTime={formattedDateTime} />
          )}
                      </div>
                    </div>
                    <div class="event-dt-right-group mt-5">
                      <div class="event-dt-right-icon">
                        <i class="fa-solid fa-circle-user"></i>
                      </div>
                      <div class="event-dt-right-content">
                        <h4>Organised by</h4>
                        {
                          allEvents?.organizers != null ? (
<h5>{  allEvents?.organizers}</h5>
                          ):(
                           " ----"
                          )

                        }
                        {/* <a href="attendee_profile_view.html">View Profile</a> */}
                      </div>
                    </div>
                    <div class="event-dt-right-group">
                      <div class="event-dt-right-icon">
                        <i class="fa-solid fa-calendar-day"></i>
                      </div>
                      <div class="event-dt-right-content">
                        <h4>Date and Time</h4>
                        <h5>   {allEvents?.date} {allEvents?.time}</h5>
                        {/* <div class="add-to-calendar">
                          <a
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-regular fa-calendar-days me-3"></i>Add
                            to Calendar
                          </a>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="fa-brands fa-windows me-3"></i>Outlook
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="fa-brands fa-apple me-3"></i>Apple
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="fa-brands fa-google me-3"></i>Google
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="fa-brands fa-yahoo me-3"></i>Yahoo
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                    <div class="event-dt-right-group">
                      <div class="event-dt-right-icon">
                        <i class="fa-solid fa-location-dot"></i>
                      </div>
                      <div class="event-dt-right-content">
                        <h4>Location</h4>
                        <h5 class="mb-0">
 {allEvents?.address}
                        </h5>
                        <a href="#">
                          <i class="fa-solid fa-location-dot me-2"></i>View Map
                        </a>
                      </div>
                    </div>
                    <div class="select-tickets-block">
                      <h6>Select Tickets</h6>
                      {
                          allTickets.length > 0 ? (
                            allTickets.map((tickets,index) => {
                              
                              return (
                           <>
                                    <div key={index} class="select-ticket-action">
                        <div class="ticket-price">{tickets?.currency + " " + tickets?.price}</div>
                        <div class="quantity">
                          <div class="counter">
                            <span
                              class="down"
                              onClick={() => updateCount(index, false)}
                            >
                              -
                            </span>
                            <input type="text" value={ticketQuantities[index] || 0} readOnly />
                            <span
                              class="up"
                              onClick={() => updateCount(index, true)}
                            >
                              +
                            </span>
                          </div>
                        </div>
                      </div>
                      <p key={index}>
                  {tickets?.name}
                      </p>
                           </>
                              )
                            })
                          ):(
                            <div class="main-card mt-4">
                            <div class="d-flex align-items-center justify-content-center flex-column min-height-430">
                              <div class="event-list-icon">
                                <img src="images/calendar.png" alt="" />
                              </div>
                              <p class="font-16 mt-4 text-light3">
                                No Ticket found
                              </p>
                            </div>
                          </div>
                          )
                        }
             
                      <div class="xtotel-tickets-count">
                        <div class="x-title">Ticket(s)</div>
                        <h4>
                      {currentUser?.accountCurrency} <span>{totalCost}</span>
                        </h4>
                      </div>
                    </div>
             {
             allTickets.length > 0 && (
              <Link
              to= "/checkout"
              state={{
                events: allEvents,
                tickets: allTickets,
                ticketQuantities: ticketQuantities,
                totalTickets: totalTickets,
                totalCost: totalCost,
              }}
            >
              <button className="main-btn btn-hover w-100">Proceed to Checkout</button>
            </Link>
             ) 
             }

                  </div>
                </div>
   
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </>
  );
};

export default VenueEventDet;
