import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/parklogo.png";
import { Link } from "react-router-dom";
import { getFeaturedPosts, getPosts } from "../services/Contentful/getEntries";
// import {client} from "../services/auth/useAuth.ts";
const OurBlog = () => {
  const [posts, setPosts] = useState([]);
  const [featuredposts, setFeaturedPosts] = useState([]);

  useEffect(() => {
    getPosts().then((res) => {
      setPosts(res);
      console.log("res", res);
    });
  }, []);
  // useEffect(() => {
  //   getFeaturedPosts().then((res) => {
  //     setFeaturedPosts(res[0]?.featuredBlogPost);
  //     console.log("featuredres", res);
  //   });
  // }, []);

  return (
    <>
      <body class="d-flex flex-column h-100">
        <Header />

        <div class="wrapper">
          <div class="breadcrumb-block">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-10">
                  <div class="barren-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">
                          Our Blog
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="event-dt-block p-80">
            <div class="most-popular-posts">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="slide-posts">
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <div class="">
                            <div class="owl-carousel most-posts-slider owl-theme">
                              <div class="item">
                                <div class="main-card">
                                  <div class="blog-block flex-slide">
                                    <div class="blog-img-card left">
                                      <a href="/blog-detail" class="bbig-img">
                                        <img
                                          src="images/blog-imgs/img-1.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div class="blog-content right">
                                      <a
                                        href="/blog-detail"
                                        class="blog--title"
                                      >
                                        Organising a Successful Christmas Party
                                        with Ticketloope
                                      </a>
                                      <p class="mb-4">
                                        Phasellus venenatis posuere nibh, sit
                                        amet blandit lorem pharetra ac.
                                        Phasellus feugiat laoreet laoreet.
                                      </p>
                                      <div class="post-meta">
                                        <span class="post-date me-4">
                                          <i class="fa-regular fa-calendar-days me-2"></i>
                                          5 May, 2024
                                        </span>
                                        <span class="post-read-time">
                                          <i class="fa-regular fa-clock me-2"></i>
                                          10 mins read{" "}
                                        </span>
                                      </div>
                                      <a
                                        href="/blog-detail"
                                        class="main-btn h_50 btn-hover bt_40"
                                      >
                                        View Post
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="item">
                                <div class="main-card">
                                  <div class="blog-block flex-slide">
                                    <div class="blog-img-card left">
                                      <a href="/blog-detail" class="bbig-img">
                                        <img
                                          src="images/blog-imgs/img-12.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div class="blog-content right">
                                      <a
                                        href="/blog-detail"
                                        class="blog--title"
                                      >
                                        Step-by-Step Guide to Promote Event on
                                        Social Media
                                      </a>
                                      <p class="mb-4">
                                        Phasellus venenatis posuere nibh, sit
                                        amet blandit lorem pharetra ac.
                                        Phasellus feugiat laoreet laoreet.
                                      </p>
                                      <div class="post-meta">
                                        <span class="post-date me-4">
                                          <i class="fa-regular fa-calendar-days me-2"></i>
                                          5 May, 2024
                                        </span>
                                        <span class="post-read-time">
                                          <i class="fa-regular fa-clock me-2"></i>
                                          10 mins read{" "}
                                        </span>
                                      </div>
                                      <a
                                        href="/blog-detail"
                                        class="main-btn h_50 btn-hover bt_40"
                                      >
                                        View Post
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="item">
                                <div class="main-card">
                                  <div class="blog-block flex-slide">
                                    <div class="blog-img-card left">
                                      <a href="/blog-detail" class="bbig-img">
                                        <img
                                          src="images/blog-imgs/img-3.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div class="blog-content right">
                                      <a
                                        href="/blog-detail"
                                        class="blog--title"
                                      >
                                        How to Live Stream Successfully:
                                        Complete Guide for Event Hosts
                                      </a>
                                      <p class="mb-4">
                                        Phasellus venenatis posuere nibh, sit
                                        amet blandit lorem pharetra ac.
                                        Phasellus feugiat laoreet laoreet.
                                      </p>
                                      <div class="post-meta">
                                        <span class="post-date me-4">
                                          <i class="fa-regular fa-calendar-days me-2"></i>
                                          5 May, 2024
                                        </span>
                                        <span class="post-read-time">
                                          <i class="fa-regular fa-clock me-2"></i>
                                          10 mins read{" "}
                                        </span>
                                      </div>
                                      <a
                                        href="/blog-detail"
                                        class="main-btn h_50 btn-hover bt_40"
                                      >
                                        View Post
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="item">
                                <div class="main-card">
                                  <div class="blog-block flex-slide">
                                    <div class="blog-img-card left">
                                      <a href="/blog-detail" class="bbig-img">
                                        <img
                                          src="images/blog-imgs/img-4.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div class="blog-content right">
                                      <a
                                        href="/blog-detail"
                                        class="blog--title"
                                      >
                                        Virtual Event Sponsorship Ideas for Your
                                        Next Event
                                      </a>
                                      <p class="mb-4">
                                        Phasellus venenatis posuere nibh, sit
                                        amet blandit lorem pharetra ac.
                                        Phasellus feugiat laoreet laoreet.
                                      </p>
                                      <div class="post-meta">
                                        <span class="post-date me-4">
                                          <i class="fa-regular fa-calendar-days me-2"></i>
                                          5 May, 2024
                                        </span>
                                        <span class="post-read-time">
                                          <i class="fa-regular fa-clock me-2"></i>
                                          10 mins read{" "}
                                        </span>
                                      </div>
                                      <a
                                        href="/blog-detail"
                                        class="main-btn h_50 btn-hover bt_40"
                                      >
                                        View Post
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="mx-auto max-w-8xl px-4">
              <a href="/how-ar-will-transform-our-lives-in-2050">
                <div class="flex flex-col overflow-hidden rounded-2xl border border-gray300 shadow-lg lg:flex-row">
                  <div
                    class="flex-1 basis-1/2"

                  >
                    <img
                      alt="Augment Reality (AR)"
                      fetchpriority="high"
                      width="721"
                      height="590"
                      decoding="async"
                      data-nimg="1"
                      class="w-full transition-all"
                      style={{color: "transparent"}}
                      src={featuredposts.fields?.featuredImage.fields?.file.url}
                    />
                  </div>
                  <div class="relative flex flex-1 basis-1/2 flex-col justify-center py-6 px-4 lg:px-16 lg:py-12 xl:px-24">
                    <div class="mb-2 flex flex-wrap items-center">
                      <div class="flex items-center">
                        <div
                          class="mr-2 overflow-hidden rounded-full border border-blue500"
                       
                        >
                          <img
                            alt="Livia Dokidis"
                            loading="lazy"
                            width="28"
                            height="28"
                            decoding="async"
                            data-nimg="1"
                            class="transition-all"
                            style={{color:"transparent"}}
                            srcset="/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fnvxmqufxogry%2F4HvHLkOxKlWEteCoTsIact%2Fda1e81a7ee7dcadb1154b936a512e3ed%2Flivia.png&amp;w=32&amp;q=75 1x, /_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fnvxmqufxogry%2F4HvHLkOxKlWEteCoTsIact%2Fda1e81a7ee7dcadb1154b936a512e3ed%2Flivia.png&amp;w=64&amp;q=75 2x"
                            src="/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fnvxmqufxogry%2F4HvHLkOxKlWEteCoTsIact%2Fda1e81a7ee7dcadb1154b936a512e3ed%2Flivia.png&amp;w=64&amp;q=75"
                          />
                        </div>
                        <span
                          class="text-xs leading-none text-gray600"
                          data-contentful-field-id="name"
                          data-contentful-entry-id="5pJhJPUcTzpAL9ZPeXL7hr"
                        >
                          Livia Dokidis
                        </span>
                      </div>
                      <div
                        class="ml-auto hidden pl-2 text-xs text-gray600"
                        data-contentful-field-id="publishedDate"
                        data-contentful-entry-id="5Ffob3XoJGrQuKE5uRUugR"
                      >
                        December 4, 2022
                      </div>
                    </div>
                    <h1
                      data-contentful-field-id="title"
                      data-contentful-entry-id="5Ffob3XoJGrQuKE5uRUugR"
                    >
                      How AR will transform our lives in 2050
                    </h1>
                    <p
                      class="mt-2"
                      data-contentful-field-id="shortDescription"
                      data-contentful-entry-id="5Ffob3XoJGrQuKE5uRUugR"
                    >
                      Augmented Reality set to revolutionize daily life in 2050
                    </p>
                    <div
                      class="mt-2 text-xs text-gray600"
                      data-contentful-field-id="publishedDate"
                      data-contentful-entry-id="5Ffob3XoJGrQuKE5uRUugR"
                    >
                      December 4, 2022
                    </div>
                  </div>
                </div>
              </a>
            </div> */}
            <div class="latest-posts Bp-top">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="main-title checkout-title">
                      <h3 style={{ color: "#fc440f" }}>Latest Posts</h3>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="bt_40">
                      <div class="row g-4">
                        {posts.map((post) => (
                          <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="main-card">
                              <div class="blog-thumbnail">
                                <a
                                  href={`/blog/${post.slug}`}
                                  class="thumbnail-img"
                                >
                                  <Link to={`/blog/${post.slug}`}>
                                    <img
                                      src={post.coverImage.fields.file.url}
                                      alt=""
                                    />
                                  </Link>
                                </a>
                              </div>
                              <div class="blog-content">
                                <a href="/blog-detail" class="blog-title fs-16">
                                  {post.title}
                                </a>
                                <div class="post-meta bt_33">
                                  <span class="post-date fs-14">
                                    <i class="fa-regular fa-calendar-days me-2"></i>
                                    {post.publishedDate}
                                  </span>
                                  <span class="post-read-time fs-14">
                                    <i class="fa-regular fa-clock me-2"></i>10
                                    mins read{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
       
        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </>
  );
};

export default OurBlog;
