//  export const environment = {
//  production: false,
//   graphURI: "https://backend-parkwell-graph-dev.herokuapp.com/v1/graphql",
//   graphWSURI: "wss://backend-parkwell-graph-dev.herokuapp.com/v1/graphql",
//   backendURL: "https://parkwell-web-service-dev.herokuapp.com",
//   googleApiKey: "AIzaSyClI93us6CcSRQj2xf5bRl5ommZETwe0v8",
//   cloud_name: "parkwell",
//   cloud_preset: "unsigned_parks_default",
// };



 export const environment = {
   production: true,
    graphURI: "https://parkwell-new-hasura.herokuapp.com/v1/graphql",
  graphWSURI: "wss://parkwell-new-hasura.herokuapp.com/v1/graphql",
  parkwellURL: "https://parkwell-web-service.herokuapp.com",
  HasuraSecret: "9A1X2doJanowY1o5281Uy5MURpTJDFqU1Uq0aoEwUXI29Alwbrue1P70yHatHDJH",
  googleClientId: "959566598433-8502bcsjbtjqkvt2oo64r4v2jn6027nt.apps.googleusercontent.com",
   backendURL: "https://ticket-loope-b9d1b4f469ab.herokuapp.com",
   googleApiKey: "AIzaSyClI93us6CcSRQj2xf5bRl5ommZETwe0v8",
   cloud_name: "parkwell",
   cloud_preset: "unsigned_parks_default",
 };
