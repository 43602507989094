import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import parkwell from "../assets/images/parklogo.png";
import { useNavigate, useParams } from "react-router";
import { getSinglePost } from "../services/Contentful/getEntries";
import "../styles/css/style.css";
import "../styles/css/responsive.css";
import "../styles/css/analytics.css";
import "../styles/css/jquery-steps.css";
import "../styles/css/datepicker.min.css";
import "../styles/css/vertical-responsive-menu.min.css";


const BlogDet = () => {
const [post, setPost] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const {id} = useParams();
const navigate = useNavigate();

useEffect(() => {
  getSinglePost(id).then((res) => {
    setPost(res.items[0].fields);
    console.log("res", res);
    setIsLoading(true);
  })
}, []);


  return (

    <>
        
      <body class="d-flex flex-column h-100">
      <Header />

        <div class="wrapper">
          <div class="breadcrumb-block">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-10">
                  <div class="barren-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">
                          Blog Detail View
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-dt-block p-80">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                {
                      isLoading && (
                        <div class="blog-view">
                        <div class="blog-img-card p-0">
                          <img src={post.coverImage.fields.file.url} alt="" />
                        </div>
    
                        <div class="blog-content blog-content-view p-0">
                          <h3>
                         {post.title}
                          </h3>
                          <div class="post-meta border_bottom pb-4">
                            <span class="post-date me-4 fs-14">
                              <i class="fa-regular fa-calendar-days me-2"></i>{post.publishedDate}
                            </span>
                            <span class="post-read-time float-none fs-14">
                              <i class="fa-regular fa-clock me-2"></i>10 mins read{" "}
                            </span>
                          </div>
                          <div>
                          {post && post?.body?.content.map((contentItem, index) => {
        switch (contentItem.nodeType) {
          case 'paragraph':
            return <p key={index}>{contentItem.content[0]?.value}</p>;
          case 'embedded-entry-block':
            // Handle embedded entries as needed
            return <div key={index}></div>;
          // Add more cases as needed based on your content types
          default:
            return null;
        }
      })}
                          </div>
                          {/* <div class="blog-content-meta">
                            <p>
                              Phasellus venenatis posuere nibh, sit amet blandit
                              lorem pharetra ac. Phasellus feugiat laoreet laoreet.
                              Praesent eget ex condimentum, malesuada lectus vitae,
                              porta nunc. Vestibulum nec venenatis magna, quis
                              hendrerit justo. Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit. Fusce fringilla, ex sit
                              amet sagittis blandit, justo mi faucibus elit, eget
                              egestas est velit vel libero.
                            </p>
                            <div class="blog-description-article">
                              <h5>
                                How exactly do we organize a virtual Christmas
                                party?
                              </h5>
                              <p>
                                Vestibulum non commodo quam. Morbi dignissim erat
                                nisl, at euismod felis blandit a. Aenean nec
                                sollicitudin turpis, sit amet cursus orci. Orci
                                varius natoque penatibus et magnis dis parturient
                                montes, nascetur ridiculus mus. Sed non mauris
                                nulla. Orci varius natoque penatibus et magnis dis
                                parturient montes, nascetur ridiculus mus. Vivamus
                                pretium, lacus quis bibendum accumsan, erat magna
                                ullamcorper libero, quis venenatis turpis neque eget
                                magna.
                              </p>
                              <p>
                                Fusce non tortor eros. Quisque tristique tincidunt
                                ullamcorper. Mauris sed sem posuere, varius nibh in,
                                volutpat nisl. Integer ipsum quam, tristique sed
                                ligula vel, tincidunt aliquam dui. Mauris porta vel
                                odio eget aliquam. Suspendisse ultrices leo sit amet
                                erat facilisis porta.
                              </p>
                            </div>
                            <div class="blog-description-article">
                              <h5>
                                Steps of Creating a Successful Virtual Christmas
                                Party
                              </h5>
                              <p>
                                Integer blandit elit nibh, sit amet dignissim ligula
                                volutpat sed. Donec consequat luctus nibh a posuere.
                                Fusce vitae commodo neque. Vivamus tempor augue id
                                lorem bibendum finibus. Praesent mauris ante,
                                laoreet ut semper nec, laoreet ac augue. Suspendisse
                                venenatis nisi tellus, at mattis velit tincidunt
                                vitae.{" "}
                              </p>
                              <p>
                                Nunc viverra eleifend sapien ac blandit. Nam id elit
                                tempor, tempor ante at, euismod nisl. Nullam ante
                                lectus, luctus eget porttitor ut, dignissim non
                                urna. Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Ut turpis quam, porttitor vel
                                vestibulum ut, venenatis non eros. In hac habitasse
                                platea dictumst.
                              </p>
                            </div>
                            <div class="blog-description-article">
                              <h5>Step-1: Define Your Event Objectives</h5>
                              <p>
                                Phasellus posuere nisi quis neque lobortis, vitae
                                aliquet dolor gravida. Pellentesque scelerisque dui
                                felis. Fusce rutrum congue dignissim. Phasellus
                                commodo auctor efficitur. Pellentesque ut aliquam
                                quam. Ut mi odio, aliquet quis felis at, semper
                                rhoncus felis. Vestibulum sollicitudin ultrices
                                turpis quis volutpat. Vestibulum ante ipsum primis
                                in faucibus orci luctus et ultrices posuere cubilia
                                curae; Suspendisse mollis, quam non lobortis varius,
                                quam enim tempus enim, ut porta lorem lacus sit amet
                                nulla. Donec ut suscipit augue, id fringilla mauris.
                                Duis egestas augue sem, vitae ornare quam facilisis
                                quis. Orci varius natoque penatibus et magnis dis
                                parturient montes, nascetur ridiculus mus. Aenean
                                vel ex facilisis, interdum felis vitae, imperdiet
                                eros.
                              </p>
                            </div>
                            <div class="blog-description-article">
                              <h5>Step-2: Prepare a Potential Attendee List</h5>
                              <p>
                                Vestibulum non commodo quam. Morbi dignissim erat
                                nisl, at euismod felis blandit a. Aenean nec
                                sollicitudin turpis, sit amet cursus orci. Orci
                                varius natoque penatibus et magnis dis parturient
                                montes, nascetur ridiculus mus. Fusce non tortor
                                eros. Quisque tristique tincidunt ullamcorper.
                                Mauris sed sem posuere, varius nibh in, volutpat
                                nisl. Integer ipsum quam, tristique sed ligula vel,
                                tincidunt aliquam dui. Mauris porta vel odio eget
                                aliquam. Suspendisse ultrices leo sit amet erat
                                facilisis porta. Sed non mauris nulla. Orci varius
                                natoque penatibus et magnis dis parturient montes,
                                nascetur ridiculus mus. Vivamus pretium, lacus quis
                                bibendum accumsan, erat magna ullamcorper libero,
                                quis venenatis turpis neque eget magna.
                              </p>
                            </div>
                            <div class="blog-description-article">
                              <h5>Step-3: Set a Budget</h5>
                              <p>
                                Morbi lectus nunc, lacinia ut consequat a, semper
                                vel erat. Duis ut lacus nec dui sodales mattis.
                                Mauris tellus dolor, pulvinar sit amet pretium a,
                                malesuada sed tellus. Aliquam ultrices elit neque,
                                quis lacinia ex porttitor non. Donec ac iaculis
                                turpis. Nulla lacinia enim quis orci aliquam, non
                                cursus urna pellentesque. Class aptent taciti
                                sociosqu ad litora torquent per conubia nostra, per
                                inceptos himenaeos. Phasellus in mi a nisi auctor
                                interdum.
                              </p>
                            </div>
                            <div class="blog-description-article">
                              <h5>Step-4: Send Virtual Invitation Cards</h5>
                              <p>
                                Morbi lectus nunc, lacinia ut consequat a, semper
                                vel erat. Duis ut lacus nec dui sodales mattis.
                                Mauris tellus dolor, pulvinar sit amet pretium a,
                                malesuada sed tellus. Aliquam ultrices elit neque,
                                quis lacinia ex porttitor non. Donec ac iaculis
                                turpis. Nulla lacinia enim quis orci aliquam, non
                                cursus urna pellentesque. Class aptent taciti
                                sociosqu ad litora torquent per conubia nostra, per
                                inceptos himenaeos. Phasellus in mi a nisi auctor
                                interdum.
                              </p>
                            </div>
                            <div class="blog-description-article">
                              <h5>
                                Step-5: Plan Out a Number of Engaging Online
                                Activities
                              </h5>
                              <p>
                                Morbi lectus nunc, lacinia ut consequat a, semper
                                vel erat. Duis ut lacus nec dui sodales mattis.
                                Mauris tellus dolor, pulvinar sit amet pretium a,
                                malesuada sed tellus. Aliquam ultrices elit neque,
                                quis lacinia ex porttitor non. Donec ac iaculis
                                turpis. Nulla lacinia enim quis orci aliquam, non
                                cursus urna pellentesque. Class aptent taciti
                                sociosqu ad litora torquent per conubia nostra, per
                                inceptos himenaeos. Phasellus in mi a nisi auctor
                                interdum.
                              </p>
                              <ul class="blog-description-list">
                                <li>
                                  <p class="mb-0">Holiday Bingo</p>
                                </li>
                                <li>
                                  <p class="mb-0">Christmas Karaoke</p>
                                </li>
                                <li>
                                  <p class="mb-0">Virtual Christmas Trivia</p>
                                </li>
                                <li>
                                  <p class="mb-0">Photo-video Montages</p>
                                </li>
                                <li>
                                  <p class="mb-0">Virtual secret Santa exchange</p>
                                </li>
                                <li>
                                  <p class="mb-0">Gift Revealing session</p>
                                </li>
                                <li>
                                  <p class="mb-0">Christmas Scavenger Hunt</p>
                                </li>
                                <li>
                                  <p class="mb-0">Holiday Wine Tasting</p>
                                </li>
                              </ul>
                            </div>
                            <div class="blog-description-article">
                              <h5>Event analytics at the fingertips</h5>
                              <p>
                                Morbi lectus nunc, lacinia ut consequat a, semper
                                vel erat. Duis ut lacus nec dui sodales mattis.
                                Mauris tellus dolor, pulvinar sit amet pretium a,
                                malesuada sed tellus. Aliquam ultrices elit neque,
                                quis lacinia ex porttitor non. Donec ac iaculis
                                turpis. Nulla lacinia enim quis orci aliquam, non
                                cursus urna pellentesque. Class aptent taciti
                                sociosqu ad litora torquent per conubia nostra, per
                                inceptos himenaeos. Phasellus in mi a nisi auctor
                                interdum.
                              </p>
                            </div>
                            <div class="social-share">
                              <h4>Share This Article</h4>
                              <ul>
                                <li>
                                  <a href="#" class="share-link">
                                    <i class="fab fa-facebook-f"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" class="share-link">
                                    <i class="fab fa-twitter"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" class="share-link">
                                    <i class="fab fa-linkedin-in"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" class="share-link">
                                    <i class="fas fa-envelope"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      )
                    }
             
                </div>
                <div class="col-md-12">
                  <div class="related-posts">
                    {/* <div class="main-title checkout-title Bp-top">
                      <h3>Related Posts</h3>
                    </div> */}
                    <div class="owl-carousel related-posts-slider owl-theme bt_40">
                      {/* {
                        post && post.relatedBlogPosts.map((related) => {
                          return (
                            <div class="item">
                            <div class="main-card">
                              <div class="blog-thumbnail">
                                <a
                                  href="#"
                                  class="thumbnail-img"
                                >
                                  <img src={related.fields.featuredImage.fields?.file?.url} alt="" />
                                </a>
                              </div>
                              <div class="blog-content">
                                <a
                                  href="#"
                                  class="blog-title fs-16"
                                >
                                 {related.fields.title}
                                </a>
                                <div class="post-meta bt_33">
                                  <span class="post-date fs-14">
                                    <i class="fa-regular fa-calendar-days me-2"></i>
                                { related.fields.publishedDate}
                                  </span>
                                  <span class="post-read-time fs-14">
                                    <i class="fa-regular fa-clock me-2"></i>10 mins
                                    read{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          )
                        })
                      }
                   
                    */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </>
  );
};

export default BlogDet;
